import React from 'react';
import {
  Account,
  AfcSubmissionsItem,
  AfcSubmissionCandidate,
} from '@axiom/validation';

import { Layout } from '../../layout/Layout/Layout';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';

export type PdfWantToTakeActionTypes = {
  candidate: AfcSubmissionCandidate;
  accountId: Account['id'];
  submissionId: AfcSubmissionsItem['submissionId'];
  clientUrl: string;
};

export const PdfWantToTakeAction = ({
  candidate,
  accountId,
  submissionId,
  clientUrl,
}: PdfWantToTakeActionTypes) => {
  return (
    <Layout
      name="WANT_TO_MAKE_ACTION"
      direction="vertical"
      className="text-align-center"
      verticalGutter="8px"
    >
      <SmallHeader name="HEADER">
        Want to interview, hire, or pass on {candidate.calculatedFirstName}?
      </SmallHeader>
      <div>
        <a
          href={`${clientUrl}/account/${accountId}/submission/${submissionId}/matches/${candidate.id}`}
          data-test="VIEW_IN_ENGAGEMENTS"
        >
          View this engagement on Axiomlaw.com
        </a>{' '}
        to take action now.
      </div>
    </Layout>
  );
};
