import React from 'react';
import {
  Button,
  Card,
  CardSection,
  Carousel,
  Gutter,
  Layout,
  LayoutItem,
  LargeHeader,
  DarkTheme,
  useApi,
} from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';
import { AccountApi } from '../../api/account-api';

import { EngagementCarouselTileButton } from './EngagementCarouselTileButton';
import { EngagementCarouselEndCard } from './EngagementCarouselEndCard';
import { useEngagementCarouselSubmissionData } from './useEngagementCarouselSubmissionsData';

type EngagementCarouselType = {
  maxDisplayCount?: number;
};

export const EngagementCarousel = ({
  maxDisplayCount = 8,
}: EngagementCarouselType) => {
  const { account, user } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({
    account,
  });
  // will fix in APCORE-2573
  const [{ data: accountNewEngagementAssignment } = { data: null }] = useApi(
    account.accountType === AccountConst.Types.Direct
      ? undefined
      : AccountApi.getAccountNewEngagementAssignment(account.id)
  );

  const submissions =
    allSubmissions.length > maxDisplayCount
      ? allSubmissions.slice(0, maxDisplayCount)
      : allSubmissions;
  const { allSubmissionsData, allInstantMatchCandidates } =
    useEngagementCarouselSubmissionData({
      accountId: account.id,
      submissions,
    });

  const buildChildren = () => {
    const kids = allSubmissionsData.map((submissionData, index) => {
      const { submission, submissionDetails } = submissionData;
      return (
        <EngagementCarouselTileButton
          instantMatchCandidates={allInstantMatchCandidates[index]}
          account={account}
          submission={submission}
          submissionDetails={submissionDetails}
          key={`${submission.id}-${submission.submissionId}`}
        />
      );
    });
    kids.push(
      <EngagementCarouselEndCard
        user={user}
        account={account}
        key="endCard"
        accountNewEngagementAssignment={accountNewEngagementAssignment}
      />
    );

    return kids;
  };

  return (
    <Card name="ENGAGEMENTS_CAROUSEL_CONTAINER">
      <CardSection background="blue">
        <Layout>
          <LayoutItem fluid>
            <LargeHeader name="HEADER">
              Recent engagements ({submissions.length})
            </LargeHeader>
          </LayoutItem>
          <LayoutItem>
            <Button to="./engagements" name="VIEW_ENGAGEMENTS">
              View all engagements
            </Button>
          </LayoutItem>
        </Layout>
        <Gutter bottom="24px" />
        <DarkTheme backgroundName="none">
          {submissions.length > 0 ? (
            <Carousel name="ENGAGEMENTS_CAROUSEL">{buildChildren()}</Carousel>
          ) : (
            <EngagementCarouselEndCard
              accountNewEngagementAssignment={accountNewEngagementAssignment}
              account={account}
              user={user}
            />
          )}
        </DarkTheme>
      </CardSection>
    </Card>
  );
};
