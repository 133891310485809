import { useApi } from '@axiom/ui';
import { AfcSubmissionsItem, Account, AfcSubmission } from '@axiom/validation';

import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { InstantMatchesApi } from '../../api/instant-matches-api';

export const useEngagementCarouselSubmissionData = ({
  accountId,
  submissions,
}: {
  accountId: Account['id'];
  submissions: AfcSubmissionsItem[];
}) => {
  const allSubmissionDetails = useApi(
    ...submissions.map(({ submissionId }) =>
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId
      )
    )
  ).map((response: { data: AfcSubmission }) => response.data);

  const allSubmissionsData = submissions.reduce(
    (crnt, submission, index) => {
      crnt.push({
        submission,
        submissionDetails: allSubmissionDetails[index],
      });

      return crnt;
    },
    [] as {
      submission: AfcSubmissionsItem;
      submissionDetails: AfcSubmission;
    }[]
  );

  const allInstantMatchCandidates = useApi(
    ...allSubmissionDetails.map(submission => {
      return InstantMatchesApi.readInstantMatches(submission.submissionId);
    })
  ).reduce((acc, response) => {
    const data = response?.data ?? [];
    const validCandidates = data
      .filter(match => !match.passed)
      .map(cOpp => cOpp.candidate);
    acc.push(validCandidates);
    return acc;
  }, []);

  return {
    allSubmissionsData,
    allInstantMatchCandidates,
  };
};
