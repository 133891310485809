import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TalentBios, useApi } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateLegalTechSkillsApi } from '../../api/candidate-legal-tech-skills-api';
import { EnvUtil } from '../../utils/env-util';
import { PublicRefIdSlugUtil } from '../../utils/public-ref-id-slug-util';
import { TaxonomyApi } from '../../api/taxonomy-api';

const noopFn = () => {};

export const CandidateBios = ({
  candidateId,
  initiateDownload = false,
  onSave = noopFn,
}: {
  candidateId?: Candidate['id'];
  initiateDownload?: boolean;
  onSave?: () => void;
}) => {
  const urlParams = useParams();
  const location = useLocation();

  const { candidateId: urlCandidateId } = urlParams;
  const isAnon = location.pathname.includes('/anonymous');
  const idOrPublicRefId = PublicRefIdSlugUtil.sanitizeId(urlCandidateId);
  // Will fix with APCORE-2573
  const shouldCallLimitedCandidates = candidateId ? undefined : true;
  const [limitedCandidate] = useApi(
    shouldCallLimitedCandidates &&
      CandidateLegacyApi.readLimitedCandidate(idOrPublicRefId)
  );
  const [{ data: candidate }, { data: practiceAreas }, { data: taxonomy }] =
    useApi(
      CandidateApi.readCandidate(candidateId ?? limitedCandidate?.data?.id, {
        isAnonymous: isAnon,
      }),
      PracticeAreaApi.readPracticeAreas(),
      TaxonomyApi.readTaxonomy()
    );
  const [
    { data: groupedExperiences } = { data: [] },
    { data: legalTechSkills } = { data: [] },
  ] = useApi(
    candidate.id
      ? CandidateApi.readCandidateGroupedExperiences(candidate.id)
      : undefined,
    candidate.id
      ? CandidateLegalTechSkillsApi.readLegalTechSkills(candidateId)
      : undefined
  );

  const baseUrl = `/pdf/${candidate.id}`;

  const handleOnNavigate = () => {
    const href = `${baseUrl}${isAnon ? '/anonymous' : ''}`;

    window.open(href, 'download_bio');
  };

  return (
    <TalentBios
      onSave={onSave}
      candidate={candidate}
      clientUrl={EnvUtil.clientEngagementsUrl}
      groupedExperiences={groupedExperiences}
      legalTechSkills={legalTechSkills}
      practiceAreas={practiceAreas}
      taxonomy={taxonomy}
      onNavigate={handleOnNavigate}
      download={!!initiateDownload}
      isAnonymous={!!isAnon}
    />
  );
};
