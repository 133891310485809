import { z } from 'zod';
import { CalendarEventSchema } from '@axiom/validation';

export const TalentInterviewScheduleSchema = z
  .object({
    CALENDAR_COMPONENT: CalendarEventSchema.array(),
    TIMEZONE_DROPDOWN: z.string().uuid().nullable(),
    NO_AVAILABILITY: z.boolean().default(false),
  })
  .superRefine((values, ctx) => {
    if (!values.NO_AVAILABILITY) {
      if (!values.TIMEZONE_DROPDOWN) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['TIMEZONE_DROPDOWN'],
          message: 'Required',
        });
      }

      if (values.CALENDAR_COMPONENT.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: 'array',
          inclusive: true,
          path: ['CALENDAR_COMPONENT'],
          message: 'Please make at least one edit to continue.',
        });
      }

      if (values.CALENDAR_COMPONENT.some(event => !!event.error)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['CALENDAR_COMPONENT'],
          message: 'Please select at least one available time to continue.',
        });
      }
    }
  });

export type TalentInterviewSchedule = z.infer<
  typeof TalentInterviewScheduleSchema
>;
