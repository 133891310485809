import React, { ReactElement, useState } from 'react';

import { Button } from '../../element/Button/Button';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Grid } from '../Grid/Grid';
import { GridColumn } from '../Grid/GridColumn';
import { Visible } from '../Visible/Visible';
import { Gutter } from '../Gutter/Gutter';
import { ResponsiveColumnOptions } from '../../../configs/responsive-config';

export type TilesProps = {
  children: React.ReactNode;
  className?: string;
  columnWidth?: 1 | 2 | 3;
  name?: string;
  size?: 'small' | 'large';
  stretched?: boolean;
};

export const Tiles = ({
  children,
  className,
  columnWidth = 3,
  name,
  size,
  stretched,
}: TilesProps) => {
  const { isMobile, isTablet } = useBreakpoint();
  const hasSelected = React.Children.toArray(children).some(
    // @ts-expect-error Needs new type
    (ch: React.ReactElement) => ch.props.selected
  );

  const [isOpen, setIsOpen] = useState(!isMobile);
  const childrenCount = React.Children.count(children);

  const isLastRow = (index: number, columns: number) => {
    const rowNumber = Math.floor(index / columns);
    const lastRow = Math.ceil(childrenCount / columns) - 1;

    return lastRow > rowNumber;
  };

  let leastRowColumns = Math.min(3, columnWidth);
  if (isTablet) {
    leastRowColumns = Math.min(2, columnWidth);
  } else if (isMobile) {
    leastRowColumns = 1;
  }

  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'tiles',
        size && `size-${size}`,
        isOpen ? 'tiles-open' : 'tiles-closed',
        stretched && 'stretch',
        className
      )}
    >
      <Grid>
        {React.Children.map(
          children as ReactElement,
          (ch: React.ReactElement, index) => {
            const rowPadding =
              (!isMobile || isOpen) && isLastRow(index, leastRowColumns)
                ? '16px'
                : null;
            return ch.props.selected ||
              !isMobile ||
              isOpen ||
              (!hasSelected && index === 0) ? (
              <GridColumn
                name="tile-column"
                className={AttrsHelper.formatClassname(
                  className,
                  'stretchable'
                )}
                key={`${ch.props.name}-${ch.props.option}`}
                largeScreenWidth={(12 / columnWidth) as ResponsiveColumnOptions}
                tabletWidth={
                  (12 / Math.min(2, columnWidth)) as ResponsiveColumnOptions
                }
                mobileWidth={12}
              >
                <Gutter
                  className="tile-spacing stretchable"
                  gutterType="padding"
                  bottom={rowPadding}
                >
                  {React.cloneElement(
                    ch,
                    {
                      ...ch.props,
                      onClick: ch.props.onClick
                        ? () => {
                            setIsOpen(false);
                            ch.props.onClick();
                          }
                        : null,
                    },
                    ch.props.children
                  )}
                </Gutter>
              </GridColumn>
            ) : null;
          }
        )}
      </Grid>
      {childrenCount > 1 && (
        <Visible only="mobile">
          <Gutter top="16px" />
          <Button
            fluid
            icon={isOpen ? 'arrow-up' : 'arrow-down'}
            pattern="secondary"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'Show Less' : 'Show More'}
          </Button>
        </Visible>
      )}
    </div>
  );
};
