import React from 'react';
import {
  CurrencyCodeKeyTypes,
  CandidateImageConst,
  PositionsConst,
} from '@axiom/const';
import {
  CandidateRateUtil,
  CondensedHeader,
  CondensedLabel,
  CondensedMedium,
  Gutter,
  ImageCircle,
  Layout,
  LayoutItem,
  Paragraph,
  Visible,
} from '@axiom/ui';
import {
  AfcSubmissionCandidateOpportunity,
  AfcSubmission,
  AfcSubmissionPosition,
} from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { CandidateUtil } from '../../utils/candidate-util';

const { PossibleImageSizes } = CandidateImageConst;
const { ReservedType } = PositionsConst;

export const EngagementTalentTile: React.FC<{
  candidateOpp: AfcSubmissionCandidateOpportunity;
  submission: AfcSubmission;
}> = ({ candidateOpp, submission }) => {
  const position: AfcSubmissionPosition = submission.positions.find(pos =>
    pos.candidateOpportunities.find(co => co.id === candidateOpp.id)
  );
  const isInstantMatch = position
    ? position.candidateOpportunities?.find(co => co.id === candidateOpp.id)
        .isInstantMatch
    : undefined;

  const getReservedHoursCopy = () => {
    if (position.reservedType !== ReservedType.Reserved) {
      return 'Pay as you go';
    }
    return `${position?.billingHoursPerWeek ?? 0} ${position?.billingHoursPerWeek === 1 ? 'hour' : 'hours'} reserved`;
  };

  const getRateCopy = () => {
    return `${CandidateRateUtil.formatCandidateRate(
      candidateOpp.candidate.id,
      CurrencyUtil.convertToSymbol(submission.currency as CurrencyCodeKeyTypes),
      submission
    )}${isInstantMatch ? ` (${getReservedHoursCopy()})` : ''}`;
  };

  return (
    <Layout horizontalGutter="8px" name="talent-tile">
      <LayoutItem fluid>
        <Layout position="middle" horizontalGutter="8px" wrap>
          <Visible only="largeScreen mobile">
            <LayoutItem>
              <ImageCircle
                size="small"
                imageName={candidateOpp.candidate.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidateOpp.candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </LayoutItem>
          </Visible>
          <LayoutItem fluid hug>
            <CondensedHeader name="talent-name" maxLines={1}>
              {candidateOpp.candidate.calculatedDisplayName}
            </CondensedHeader>

            <Gutter bottom="8px" />

            <Layout position="middle">
              <div className="max-lines-1">
                <CondensedLabel>Experience</CondensedLabel>
                <Gutter right="8px" />
                <CondensedMedium name="years-of-experience">
                  {candidateOpp.candidate?.yearsOfExperience
                    ? `${new CandidateYearsOfExperienceModel(
                        candidateOpp.candidate.yearsOfExperience
                      ).getYearsOfExperienceRange()} years`
                    : '--'}
                </CondensedMedium>
              </div>
            </Layout>

            {CandidateRateUtil.shouldDisplayRate(candidateOpp) && (
              <Layout>
                <Paragraph maxLines={2}>
                  <CondensedLabel>Rate</CondensedLabel>
                  <Gutter right="8px" />
                  <CondensedMedium as="span" name="rate">
                    {getRateCopy()}
                  </CondensedMedium>
                </Paragraph>
              </Layout>
            )}
          </LayoutItem>
        </Layout>
      </LayoutItem>
    </Layout>
  );
};
