import React from 'react';
import { Badge } from '@axiom/ui';
import {
  AfcSubmission,
  AfcSubmissionsItem,
  AfcSubmissionCandidateOpportunity,
  InstantMatch,
} from '@axiom/validation';
import { CandidateOpportunitiesConst, OpportunitiesConst } from '@axiom/const';

import { DateUtil } from '../../utils/date-util';

interface Props {
  submission: AfcSubmissionsItem;
  submissionDetails: AfcSubmission;
  instantMatchCandidates: InstantMatch['candidate'][];
}

export const EngagementCarouselTileBadge = ({
  submission,
  submissionDetails,
  instantMatchCandidates,
}: Props) => {
  const isProjectSubmitted =
    submission.projectStateDetail.state ===
    OpportunitiesConst.ProjectStates.Submitted;

  const candidateCount =
    submissionDetails.positions.reduce((count, position) => {
      count += position.candidateOpportunities.length;
      return count;
    }, 0) + instantMatchCandidates.length;

  const hasTalentWithStatus = (
    status: AfcSubmissionCandidateOpportunity['candidateStatus']
  ) => {
    return submissionDetails.positions?.some(position =>
      position.candidateOpportunities.some(
        cOpp => cOpp.candidateStatus === status
      )
    );
  };
  const daysRemainingOnEngagement = DateUtil.getDuration(
    DateUtil.formatAsDate(new Date()),
    submission.projectStateDetail.endDate
  );
  const rollingOffSoonThreshold = 30;

  return (
    <>
      {!isProjectSubmitted && (
        <>
          <Badge name="ACTIVE_ENGAGEMENT_BADGE" background="light orange">
            Active
          </Badge>
          {daysRemainingOnEngagement <= rollingOffSoonThreshold && (
            <Badge name="ENGAGEMENT_ROLL_OFF_BADGE" background="gray">
              Ending Soon
            </Badge>
          )}
        </>
      )}
      {isProjectSubmitted &&
        (hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Selected
        ) ||
          hasTalentWithStatus(
            CandidateOpportunitiesConst.CandidateStatuses.Engaged
          )) && (
          <Badge name="UPCOMING_ENGAGEMENT_BADGE" background="green">
            Starting Soon
          </Badge>
        )}
      {isProjectSubmitted && candidateCount === 0 && (
        <Badge name="NEW_ENGAGEMENT_BADGE" background="orange">
          New
        </Badge>
      )}
      {isProjectSubmitted &&
        candidateCount > 0 &&
        !hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Interviewing
        ) &&
        !hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Engaged
        ) &&
        !hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Selected
        ) && (
          <Badge name="NEW_MATCHES_ENGAGEMENT_BADGE" background="orange">
            New Matches
          </Badge>
        )}
      {isProjectSubmitted &&
        hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Interviewing
        ) &&
        !hasTalentWithStatus(
          CandidateOpportunitiesConst.CandidateStatuses.Selected
        ) && (
          <Badge name="INTERVIEWING_ENGAGEMENT_BADGE" background="green">
            Interviewing
          </Badge>
        )}
    </>
  );
};
