import React from 'react';
import { bool, func, string } from 'prop-types';
import { Trans } from '@lingui/macro';
import {
  Button,
  FlexiCardGridBody,
  Gutter,
  Label,
  Divider,
  Text,
  useApi,
} from '@axiom/ui';

import { CandidateAccountShape } from '../../models/candidate';
import { FormDigitalBenchRequestRateModalStore } from '../../stores/form-digital-bench-request-rate-modal-store';
import { FormDigitalBenchRequestUpdateModalStore } from '../../stores/form-digital-bench-request-update-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { TalentUtil } from '../../utils/talent-util';
import { WindowUtil } from '../../utils/window-util';
import { getDisplayableIndustries } from '../../utils/industries-util';
import { TaxonomyApi } from '../../api/taxonomy-api';

import { MyBenchAvailability } from './MyBenchAvailability';
import { MyBenchExpectedChange } from './MyBenchExpectedChange';
import { BENCH_CANDIDATES_FORM_TYPES } from './my-bench-const';
import {
  BCAvailabilityContainer,
  BCAvailabilityLeft,
  BCAvailabilityRight,
} from './MyBenchStyles';

export const MyBenchCardShown = ({
  accountId,
  candidate,
  getPracticeAreaText,
  isEngaged,
  isEnvoyUser,
  user,
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const displayableIndustries = getDisplayableIndustries(candidate.industries);

  return (
    <>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_AVAILABILITY_ROW">
          <BCAvailabilityContainer>
            <BCAvailabilityLeft>
              <Text>
                <MyBenchAvailability candidate={candidate} />
              </Text>
              <Text size="small" subtle className="font-style-italic">
                <MyBenchExpectedChange candidate={candidate} />
              </Text>
            </BCAvailabilityLeft>
            <BCAvailabilityRight>
              {isEngaged ? (
                <Button
                  disabled={isEnvoyUser}
                  name="UPDATE_ENGAGEMENT_MODAL_BUTTON"
                  onClick={() =>
                    FormDigitalBenchRequestUpdateModalStore.load(
                      candidate,
                      user
                    )
                  }
                >
                  Manage Engagement
                </Button>
              ) : (
                <Button
                  disabled={isEnvoyUser}
                  name="REQUEST_RATE_MODAL_BUTTON"
                  onClick={() => {
                    FormDigitalBenchRequestRateModalStore.load(candidate, user);
                    WindowUtil.setUrlQuery(
                      {
                        account: accountId,
                        talent: candidate.id,
                        modal: BENCH_CANDIDATES_FORM_TYPES.requestRate,
                      },
                      true
                    );
                  }}
                >
                  Re-engage Talent
                </Button>
              )}
            </BCAvailabilityRight>
          </BCAvailabilityContainer>
        </div>
      </FlexiCardGridBody>
      <FlexiCardGridBody divided>
        <Gutter bottom="20px">
          <div data-test="CANDIDATE_CARD_CANDIDATE_NOTES_ROW">
            <Text name="CANDIDATE_NOTES">
              {candidate.calculatedNotes || (
                <span className="font-style-italic">
                  <Trans>
                    Profile summary not available. To see their full bio click
                    View Profile below.
                  </Trans>
                </span>
              )}
            </Text>
          </div>
        </Gutter>
      </FlexiCardGridBody>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_INDUSTRIES_ROW">
          <Label className="text-transform-uppercase">
            <Trans>Industries</Trans>
          </Label>
          <Text name="INDUSTRIES">
            {Array.isArray(displayableIndustries) &&
            displayableIndustries.length > 0 ? (
              <Divider>
                {displayableIndustries.map(i => (
                  <span data-test="INDUSTRY" key={i.industryValue}>
                    {taxonomy.industries[i.industryValue].displayValue}
                  </span>
                ))}
              </Divider>
            ) : (
              <Trans>Not Available</Trans>
            )}
          </Text>
        </div>
      </FlexiCardGridBody>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_PRACTICE_AREA_ROW">
          <Label className="text-transform-uppercase">
            <Trans>Practice Area</Trans>
          </Label>
          <Text name="PRACTICE_AREA">
            {getPracticeAreaText(candidate.practiceArea)}
          </Text>
        </div>
      </FlexiCardGridBody>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_YEARS_OF_EXPERIENCE_ROW">
          <Label className="text-transform-uppercase">
            <Trans>Years of Experience</Trans>
          </Label>
          <Text name="YEARS_OF_EXPERIENCE">
            {typeof candidate.yearsOfExperience === 'number' ? (
              <Trans>
                {TalentUtil.formatYearsOfExperience(
                  candidate.yearsOfExperience
                )}{' '}
                years
              </Trans>
            ) : (
              <Trans>Not Available</Trans>
            )}
          </Text>
        </div>
      </FlexiCardGridBody>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_LAW_SCHOOL_ROW">
          <Label className="text-transform-uppercase">
            <Trans>Law Schools</Trans>
          </Label>
          {candidate.lawDegreeSchools?.length ? (
            <div>
              <Divider>
                {candidate.lawDegreeSchools.map(({ institution }) => (
                  <Text name="LAW_SCHOOL" as="span" key={institution}>
                    {institution}
                  </Text>
                ))}
              </Divider>
            </div>
          ) : (
            <Text name="LAW_SCHOOL">
              <Trans>Not Available</Trans>
            </Text>
          )}
        </div>
      </FlexiCardGridBody>
      <FlexiCardGridBody>
        <div data-test="CANDIDATE_CARD_LOCATION_ROW">
          <Label className="text-transform-uppercase">
            <Trans>Location</Trans>
          </Label>
          <Text name="LOCATION">
            {[candidate.addressState, candidate.addressCountry]
              .filter(Boolean)
              .join(', ') || <Trans>Not Available</Trans>}
          </Text>
        </div>
      </FlexiCardGridBody>
    </>
  );
};

MyBenchCardShown.defaultProps = {
  isEngaged: false,
  isEnvoyUser: false,
};

MyBenchCardShown.propTypes = {
  accountId: string.isRequired,
  candidate: CandidateAccountShape.isRequired,
  getPracticeAreaText: func.isRequired,
  isEngaged: bool,
  isEnvoyUser: bool,
  user: PreloadedUserStore.getDataShape().isRequired,
};
