import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AccountNewEngagementAssignment,
  Account,
  User,
} from '@axiom/validation';
import { AccountConst } from '@axiom/const';

import { Button, ButtonProps } from '../../element/Button/Button';
import { ServiceChoiceSuccessModal } from '../ServiceChoiceSuccessModal/ServiceChoiceSuccessModal';

import { Loader } from '@axiom/ui';

export interface MqlData {
  fields: { name: string; value: string }[];
}

export interface StartEngagementButtonProps {
  name: string;
  account: Account | null;
  user: User | null;
  accountNewEngagementAssignment: AccountNewEngagementAssignment | null;
  children: React.ReactNode;
  pattern: ButtonProps['pattern'];
  variation: ButtonProps['variation'];
  onSelfServiceIneligibleClick: (data: MqlData) => Promise<void>;
  loginUrl?: string;
  onLoggedOutSubmissionClick?: () => void;
}

export const StartEngagementButton = ({
  name,
  account,
  accountNewEngagementAssignment,
  pattern,
  variation,
  children,
  user,
  onSelfServiceIneligibleClick,
  onLoggedOutSubmissionClick,
}: StartEngagementButtonProps) => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hubspotApiInitiated, setHubspotApiInitiated] = useState(false);
  return (
    <>
      {hubspotApiInitiated && (
        <Loader expand="fullscreen" name="hubspot-loader" />
      )}
      <Button
        name={name}
        onClick={async () => {
          try {
            if (!user) {
              onLoggedOutSubmissionClick();
            } else if (account?.accountType === AccountConst.Types.Direct) {
              navigate(`/my-axiom/account/${account?.id}/intake`);
            } else if (accountNewEngagementAssignment?.canSelectSelfService) {
              navigate(`/my-axiom/account/${account?.id}/service-choice`);
            } else if (
              account?.accountType !== AccountConst.Types.Direct &&
              accountNewEngagementAssignment?.canSelectSelfService === false
            ) {
              setHubspotApiInitiated(true);
              const data = {
                fields: [
                  {
                    name: 'email',
                    value: user?.email,
                  },
                  {
                    name: 'salesforce_account_owner',
                    value: accountNewEngagementAssignment?.assignTo,
                  },
                  {
                    name: 'leadsource',
                    value: 'AFC - Self-Service',
                  },
                ],
              };
              await onSelfServiceIneligibleClick(data);
              setHubspotApiInitiated(false);
              setShowSuccessModal(true);
            }
          } catch {
            navigate('/400');
          }
        }}
        variation={variation}
        pattern={pattern}
      >
        {children}
      </Button>
      {showSuccessModal && (
        <ServiceChoiceSuccessModal
          accountId={account.id}
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </>
  );
};
