import React from 'react';
import { CondensedLarge, Icon } from '@axiom/ui';
import { CandidateIndustry, Taxonomy } from '@axiom/validation';

export const DiscoverTalentCarouselIndustry = ({
  industry,
  industries,
}: {
  industry: CandidateIndustry;
  industries: Taxonomy['industries'];
}) => {
  if (!industry?.industryValue) return null;

  const industryYOE = Math.round(+industry.yearsOfExperience);
  const industryName = industries[industry.industryValue].displayValue;

  return (
    <>
      <Icon name="checkmark" />
      <CondensedLarge name="DISCOVER_INDUSTRY_EXPERIENCE">
        {industryYOE === 0
          ? `Less than 1 Year in ${industryName}`
          : `${industryYOE} Year${
              industryYOE > 1 ? 's' : ''
            } in ${industryName}`}
      </CondensedLarge>
    </>
  );
};
