import React, {
  forwardRef,
  LegacyRef,
  MutableRefObject,
  useEffect,
} from 'react';
import { PDFExport as KendoPDFExport } from '@progress/kendo-react-pdf';

export type PDFExportRef = KendoPDFExport & {
  save: () => void;
};

interface Props {
  children: JSX.Element | JSX.Element[];
  fileName: string;
  pageTemplate?: (props: {
    pageNum: number;
    totalPages: number;
  }) => JSX.Element;
  onSave?: () => void;
  scale: number;
  margin: KendoPDFExport['props']['margin'];
}

const PDFComponent = (
  {
    children,
    fileName,
    onSave,
    scale = 0.8,
    margin = '0.3in',
    pageTemplate,
  }: Props,
  ref: MutableRefObject<PDFExportRef>
) => {
  useEffect(() => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('download') === 'true') {
      url.searchParams.delete('download');
      window.history.replaceState(null, '', url.href);
      if (ref?.current) ref?.current?.save(onSave);
    }
  }, [ref]);

  return (
    // @ts-expect-error The type is wrong for this; it allows children.
    <KendoPDFExport
      pageTemplate={pageTemplate}
      fileName={fileName}
      forcePageBreak=".pdf-page-break"
      keepTogether=".pdf-no-break"
      margin={margin}
      paperSize="A4"
      ref={ref as LegacyRef<KendoPDFExport>}
      scale={scale}
      proxyURL="/downloading"
    >
      {children}
    </KendoPDFExport>
  );
};

// @ts-expect-error forwardRef needs to be changed to current usage
export const PDFExport = forwardRef<PDFExportRef, Props>(PDFComponent);
