import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Layout } from '../Layout/Layout';
import { LayoutItem } from '../Layout/LayoutItem';

export type EmptyLayoutProps = {
  children: React.ReactNode;
  className?: string;
  graphic: React.ReactNode;
  graphicPosition?: 'left' | 'right';
  name?: string;
};

export const EmptyLayout = ({
  children,
  className,
  graphic,
  graphicPosition = 'left',
  name,
}: EmptyLayoutProps) => {
  return (
    <Layout
      reversibleOn={graphicPosition === 'right' ? 'mobile' : undefined}
      stackableOn="mobile"
      horizontalGutter="24px"
      verticalGutter="16px"
      name={name}
      className={AttrsHelper.formatClassname('empty-layout', className)}
    >
      {!!graphic && graphicPosition === 'left' && graphic}
      <LayoutItem fluid name="layout-contents">
        {children}
      </LayoutItem>
      {graphicPosition === 'right' && !!graphic && graphic}
    </Layout>
  );
};
