import React, { useState } from 'react';
import {
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Visible,
  useApiWithErrors,
  ApiError,
  GoneError,
  LayoutItem,
  Layout,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { PublicEngagementToLoginModal } from '../PublicEngagementToLoginModal/PublicEngagementToLoginModal';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';
import { EngagementsShareContextMenu } from '../EngagementsShareContextMenu/EngagementsShareContextMenu';

export const PublicEngagementHeader = () => {
  const [displayLoginModal, setDisplayLoginModal] = useState(false);
  const { accountId, submissionId } = useParams();
  let invalidSubmissionRequest = false;

  const [response] = useApiWithErrors(
    AccountSubmissionsApi.readAccountSubmissionDetails(accountId, submissionId)
  );

  if (response instanceof ApiError) {
    if (response instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw response;
    }
  }
  const { data: submission = {} } = response;

  return invalidSubmissionRequest ? null : (
    <>
      <Grid stackable name="CLIENT_ACTIONS_BANNER">
        <GridRow>
          <GridColumn largeScreenWidth={10} tabletWidth={10} mobileWidth={12}>
            <LargeHeader name="CLIENT_NAME">{submission.jobName}</LargeHeader>
            <Visible only="mobile">
              <Gutter bottom="16px" />
            </Visible>
          </GridColumn>
          <GridColumn
            largeScreenWidth={2}
            tabletWidth={2}
            mobileWidth={12}
            verticalAlign="bottom"
            textAlign="right"
          >
            <Layout
              direction="horizontal"
              position="right"
              stackableOn="mobile"
            >
              <LayoutItem>
                <EngagementsShareContextMenu
                  onClickInviteToAccount={() => setDisplayLoginModal(true)}
                />
                <Gutter bottom="8px" only="mobile" />
              </LayoutItem>
            </Layout>
          </GridColumn>
        </GridRow>
      </Grid>
      {!!displayLoginModal && (
        <PublicEngagementToLoginModal
          onClose={() => setDisplayLoginModal(false)}
        />
      )}
    </>
  );
};
