import React from 'react';
import { GroupedExperiences, PracticeArea } from '@axiom/validation';

import { DateUtil } from '../../../utils/date-util';
import { PracticeAreaUtil } from '../../../utils/practice-area-util';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { Gutter } from '../../layout/Gutter/Gutter';
import { CardSection } from '../../layout/Card/CardSection';
import { Layout } from '../../layout/Layout/Layout';

import { TalentExperiencesItem } from './TalentExperiencesItem';

interface TalentExperiencesGroupProps {
  experienceGroup?: GroupedExperiences;
  practiceAreas: PracticeArea[];
  expandDescription?: boolean;
}

export const TalentExperiencesGroup = ({
  experienceGroup,
  practiceAreas,
}: TalentExperiencesGroupProps) => {
  if (!experienceGroup) {
    return null;
  }
  const { experiences, isAxiom, startDate, endDate } = experienceGroup;

  return (
    <CardSection name="experience_group" divider>
      {!isAxiom && experiences.length > 1 && (
        <Gutter bottom="4px">
          <Layout position="middle" name="EXPERIENCE_GROUP_HEADER">
            <div>
              <ParagraphHeader name="EXPERIENCE_CLIENT_NAME">
                {experiences[0]?.calculatedDisplayName}
              </ParagraphHeader>
              <Gutter top="4px">
                <Paragraph name="EXPERIENCE_GROUP_DATE_RANGE">
                  {DateUtil.displayDateRange(startDate, endDate)}
                </Paragraph>
              </Gutter>
            </div>
          </Layout>
        </Gutter>
      )}

      {experiences.map((experience, index) => (
        <TalentExperiencesItem
          key={experience.id}
          experience={experience}
          groupSize={experiences.length}
          lastOfGroup={index === experiences.length - 1}
          practiceAreaName={PracticeAreaUtil.formatPracticeAreaSpecialty(
            practiceAreas,
            experience?.opportunity?.practiceAreaId ||
              experience?.practiceAreaId
          )}
        />
      ))}
    </CardSection>
  );
};
