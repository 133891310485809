import React, { useEffect, useRef } from 'react';
import {
  Candidate,
  CandidateLegalTechSkill,
  GroupedExperiences,
  PracticeArea,
  Taxonomy,
} from '@axiom/validation';

import { StringUtil } from '../../../utils/string-util';
import { PDFExport, PDFExportRef } from '../../layout/PDFExport/PDFExport';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { PdfPageTemplateFooter } from '../PdfPageTemplateFooter/PdfPageTemplateFooter';

import { BioPdfFooter } from './BioPdfFooter';
import { BioPdfCandidate } from './BioPdfCandidate';
import { PDFWrapper } from './BioPdfStyles';

export type BiosTypes = {
  candidate: Candidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  isAnonymous?: boolean;
  clientUrl: string;
  hideAllHighlights?: boolean;
  hideHighlightsDetails?: boolean;
  hideLocation?: boolean;
  onSave?: () => void;
  taxonomy: Taxonomy;
  legalTechSkills: CandidateLegalTechSkill[];
};

export const BioPdf = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  isAnonymous = false,
  clientUrl,
  hideAllHighlights,
  hideHighlightsDetails = false,
  hideLocation,
  onSave,
  taxonomy,
  legalTechSkills,
}: BiosTypes) => {
  const pdfRoot = useRef<PDFExportRef>(null);

  const renderPageTemplate = (props: {
    pageNum: number;
    totalPages: number;
  }) => {
    return (
      <PdfPageTemplateFooter
        pdfProps={props}
        candidate={candidate}
        isAnonymous={isAnonymous}
      />
    );
  };

  useEffect(() => {
    const listenerFunction = window.close;
    if (pdfRoot?.current?.save) {
      if (onSave instanceof Function) {
        setTimeout(() => pdfRoot?.current?.save(onSave), 500);
      } else {
        window.addEventListener('focus', listenerFunction);

        setTimeout(() => pdfRoot?.current?.save(), 500);
      }
    }

    return () => {
      window.removeEventListener('focus', listenerFunction);
    };
  }, [pdfRoot]);

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        pageTemplate={props => renderPageTemplate(props)}
        fileName={`${StringUtil.cleanStringForFileName(
          candidate.calculatedDisplayName
        )} - Axiom`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
      >
        <Layout name="BIO_PDF" background="panelLight">
          <LayoutItem fluid>
            <Gutter horizontal="40px" vertical="24px">
              <BioPdfCandidate
                hideAllHighlights={hideAllHighlights}
                hideHighlightsDetails={hideHighlightsDetails}
                hideLocation={hideLocation}
                candidate={candidate}
                groupedExperiences={groupedExperiences}
                practiceAreas={practiceAreas}
                isAnonymous={isAnonymous}
                removeTalentImage
                taxonomy={taxonomy}
                legalTechSkills={legalTechSkills}
              />
              <BioPdfFooter clientUrl={clientUrl} />
            </Gutter>
          </LayoutItem>
        </Layout>
      </PDFExport>
    </PDFWrapper>
  );
};
