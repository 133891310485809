import React, { useState } from 'react';
import moment from 'moment';
import {
  CalendarComponentEvent,
  TimeZoneIana,
  CalendarPermissions,
} from '@axiom/validation';

import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { DateUtil } from '../../../utils/date-util';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { CalendarTimeZoneUtil } from '../../../utils/calendar-time-zone-util';
import { Modal } from '../../element/Modal/Modal';
import { ModalSection } from '../../element/Modal/ModalSection';
import { Icon } from '../../element/Icon/Icon';
import { ModalFooter } from '../../element/Modal/ModalFooter';
import { Button } from '../../element/Button/Button';
import { ModalHeader } from '../../element/Modal/ModalHeader';
import { Banner } from '../../element/Banner/Banner';

import { EventStates } from './calendar-events-const';

export interface InterviewDeleteModalProps {
  permissions: CalendarPermissions;
  onClose: () => void;
  deleteEvent: CalendarComponentEvent;
  onDelete: () => void;
}

export const CalendarDeleteEventModal = ({
  permissions,
  onClose,
  deleteEvent,
  onDelete,
}: InterviewDeleteModalProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { isMobile } = useBreakpoint();

  const timezone = CalendarTimeZoneUtil.getZoneFromMomentZone(
    moment.tz(new Date(), moment(deleteEvent.start).tz() as TimeZoneIana)
  );
  return confirmOpen ? (
    <Modal name="DELETE_CONFIRMATION_MODAL">
      <ModalHeader onClose={() => onClose()} name="DELETE_CONFIRMATION_HEADER">
        Delete Interview
      </ModalHeader>
      <ModalSection>
        <Banner type="error" impact="high">
          Deleted interviews can only be rescheduled manually and talent will
          remain in interview status.
        </Banner>
        <Gutter vertical="8px" />
        <Paragraph>
          The event below will be deleted and all attendees will receive an
          interview cancellation notice.
        </Paragraph>
        <Gutter vertical="8px" />
        {!!deleteEvent.accountName && (
          <>
            <ParagraphHeader name="DELETE_CONFIRM_MODAL_ACCOUNT_NAME">
              {deleteEvent.accountName}
            </ParagraphHeader>
            <Gutter bottom="16px" />
          </>
        )}
        {(deleteEvent.attendees?.length ?? 0) > 0 && (
          <>
            <Layout position="top" horizontalGutter="8px">
              <LayoutItem>
                <Icon name="avatar" />
              </LayoutItem>
              <LayoutItem>
                {deleteEvent.attendees?.map((attendee, index) => (
                  <Paragraph
                    name="ATTENDEE"
                    key={`${attendee.email}_${index + 1}`}
                  >
                    {`${attendee.name} (${attendee.email})`}
                  </Paragraph>
                ))}
              </LayoutItem>
            </Layout>
            <Gutter vertical="8px" />
          </>
        )}
        <Layout position="middle" horizontalGutter="8px">
          <Icon name="calendar" />
          <Paragraph name="EVENT_DATE">{`${DateUtil.displayLongDay(
            deleteEvent.start
          )}, ${DateUtil.displayLongMonthDayYear(
            deleteEvent.start
          )}`}</Paragraph>
        </Layout>
        <Gutter vertical="8px" />
        <Layout position="middle" horizontalGutter="8px">
          <Icon name="clock" />
          <Paragraph name="EVENT_TIME">{`${DateUtil.displayFullTimeRange(
            deleteEvent.start,
            deleteEvent.end
          )} ${DateUtil.displayTimezoneAbbreviation(
            deleteEvent.start
          )}`}</Paragraph>
        </Layout>
      </ModalSection>
      <ModalFooter>
        <Button
          name="CANCEL_BUTTON"
          variation="outline"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button name="DELETE_BUTTON_CONFIRMATION" onClick={() => onDelete()}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  ) : (
    <Modal name="CALENDAR_DELETE_MODAL">
      <ModalHeader onClose={() => onClose()} name="DELETE_MODAL_HEADER">
        {deleteEvent.busy ? 'Interview' : 'Availability'} Details
      </ModalHeader>
      <ModalSection>
        {!!deleteEvent.accountName && (
          <>
            <ParagraphHeader name="DELETE_MODAL_ACCOUNT_NAME">
              {deleteEvent.accountName}
            </ParagraphHeader>
            <Gutter bottom="16px" />
          </>
        )}
        {(deleteEvent.attendees?.length ?? 0) > 0 && (
          <>
            <Layout position="top" horizontalGutter="8px">
              <LayoutItem topGutter="4px">
                <Icon name="avatar" />
              </LayoutItem>
              <LayoutItem>
                {deleteEvent.attendees?.map((attendee, index) => (
                  <Paragraph
                    name="ATTENDEE"
                    key={`${attendee.email}_${index + 1}`}
                  >
                    {`${attendee.name} (${attendee.email})`}
                  </Paragraph>
                ))}
              </LayoutItem>
            </Layout>
            <Gutter vertical="8px" />
          </>
        )}
        <Layout position="middle" horizontalGutter="8px">
          <Icon name="calendar" />
          <Paragraph name="EVENT_DATE">{`${DateUtil.displayLongDay(
            deleteEvent.start
          )}, ${DateUtil.displayLongMonthDayYear(
            deleteEvent.start
          )}`}</Paragraph>
        </Layout>
        <Gutter vertical="8px" />
        <Layout position="middle" horizontalGutter="8px">
          <Icon name="clock" />
          <Paragraph name="EVENT_TIME">{`${DateUtil.displayFullTimeRange(
            deleteEvent.start,
            deleteEvent.end
          )} ${timezone?.abbr}`}</Paragraph>
        </Layout>
      </ModalSection>
      <ModalFooter>
        {!isMobile &&
          !!(
            (!deleteEvent.busy && permissions.eventBoundary.canDelete) ||
            (deleteEvent.busy &&
              deleteEvent.state !== EventStates.SAVED &&
              permissions.staticEvent.canDelete) ||
            (deleteEvent.busy &&
              deleteEvent.state === EventStates.SAVED &&
              permissions.staticEvent.canDeleteSaved)
          ) && (
            <Button
              name="DELETE_BUTTON"
              variation="outline"
              onClick={() => {
                if (
                  deleteEvent.busy &&
                  (deleteEvent.attendees?.length ?? 0) > 0
                ) {
                  setConfirmOpen(true);
                } else {
                  onDelete();
                }
              }}
            >
              Delete
            </Button>
          )}
        <Button name="CLOSE_BUTTON" onClick={() => onClose()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
