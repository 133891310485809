export const ProfessionConst = {
  lawyer: {
    name: 'Lawyer',
    occupationTypes: ['Lawyer', 'Lawyer-Flex', 'Lawyer-Client Referred'],
  },
  other: {
    name: 'Paralegal or other legal professional',
    occupationTypes: ['Legal Support', 'Legal Support-Flex'],
  },
} as const;
