import React from 'react';
import {
  Button,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalSection,
  Dropdown,
  Form,
  ToastUtil,
  CondensedHeader,
  CondensedMedium,
  FluidButtonLayout,
} from '@axiom/ui';
import {
  SelfServiceHelpRequired,
  selfServiceHelpRequiredValues,
} from '@axiom/const';
import {
  AfcSubmissionsItem,
  SelfServiceHelpRequest,
  SelfServiceHelpRequestSchema,
} from '@axiom/validation';

import { SelfServiceApi } from '../../api/self-service-api';

export const EngagementHelpModal = ({
  currentSubmission,
  onClose,
}: {
  currentSubmission: AfcSubmissionsItem;
  onClose: () => void;
}) => {
  const handleSubmit = async (data: SelfServiceHelpRequest) => {
    const body = {
      submissionId: currentSubmission.submissionId,
      helpRequired: data.helpRequired as SelfServiceHelpRequired,
    };

    await SelfServiceApi.createHelpRequest(body);
    ToastUtil.add({
      dismissible: 5,
      name: 'help-request-toast',
      type: 'info',
      children: (
        <>
          <CondensedHeader>Help is on the way!</CondensedHeader>
          <CondensedMedium>
            We'll reach out within 1 business day or sooner.
          </CondensedMedium>
        </>
      ),
    });
    onClose();
  };

  return (
    <Form
      name="engagement-help-form"
      schema={SelfServiceHelpRequestSchema}
      onSubmit={data => handleSubmit(data)}
    >
      {({ fireSubmit }) => {
        return (
          <Modal size="confirm" name="engagement_help_modal">
            <ModalHeader onClose={onClose} name="engagement_help_modal">
              Send a request to get help fast
            </ModalHeader>
            <ModalSection disableScrolling>
              <Dropdown
                name="helpRequired"
                description="Choose the area that best describes what you need assistance with and we'll reach out within 1 business day - often sooner!"
                label="How can we help"
                options={Object.keys(selfServiceHelpRequiredValues).map(
                  key => ({
                    label:
                      selfServiceHelpRequiredValues[
                        key as keyof typeof selfServiceHelpRequiredValues
                      ],
                    value: key,
                  })
                )}
                displayKey="label"
                valueKey="value"
              />
            </ModalSection>
            <ModalFooter>
              <FluidButtonLayout stackableOn="mobile">
                <Button
                  name="help-modal-cancel-button"
                  pattern="primary"
                  variation="outline"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button name="help-modal-submit-button" onClick={fireSubmit}>
                  Send Request
                </Button>
              </FluidButtonLayout>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
