import { Api } from '@axiom/ui';
import { AfcSubmissionsItem } from '@axiom/validation';
import { SelfServiceHelpRequired } from '@axiom/const';

import { EnvUtil } from '../utils/env-util';

class SelfServiceApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  createHelpRequest(body: {
    submissionId: AfcSubmissionsItem['id'];
    helpRequired: SelfServiceHelpRequired;
  }) {
    return super.write<{ data: null }>({
      endpoint: `/self-service/help`,
      method: 'POST',
      body,
    });
  }
}

export const SelfServiceApi = new SelfServiceApiClass();
