import { createContext } from 'react';

export type AccordionContextType = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
};

export const AccordionContext = createContext<AccordionContextType>({
  isExpanded: false,
  setIsExpanded: () => {},
});
