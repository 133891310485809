import React from 'react';
import {
  Card,
  CardFooter,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SmallHeader,
  StartEngagementButton,
} from '@axiom/ui';
import {
  Account,
  AccountNewEngagementAssignment,
  User,
} from '@axiom/validation';

import { postCreateMqlPassing } from '../../api/hubspot-api';
import RocketShip from '../../public/svg/rocket-ship.svg';

export const EngagementCarouselEndCard = ({
  account,
  accountNewEngagementAssignment,
  user,
}: {
  account: Account;
  accountNewEngagementAssignment?: AccountNewEngagementAssignment;
  user: User;
}) => {
  return (
    <Card name="ENGAGEMENT_CAROUSEL_END_CARD" stretched>
      <CardSection>
        <Layout>
          <LayoutItem rightGutter="24px">
            <RocketShip width="117px" height="117px" />
          </LayoutItem>
          <LayoutItem>
            <SmallHeader>Start a new engagement</SmallHeader>
            <Gutter bottom="16px" />
            <Paragraph>
              We will walk you through finding talent that matches your legal
              needs.
            </Paragraph>
          </LayoutItem>
        </Layout>
      </CardSection>
      <CardFooter>
        <StartEngagementButton
          user={user}
          name="END_CARD_START_ENGAGEMENT"
          account={account}
          accountNewEngagementAssignment={accountNewEngagementAssignment}
          pattern="secondary"
          variation="filled"
          onSelfServiceIneligibleClick={postCreateMqlPassing}
        >
          Start an Engagement
        </StartEngagementButton>
      </CardFooter>
    </Card>
  );
};
