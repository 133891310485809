import { useParams } from 'react-router-dom';
import {
  ApiError,
  CookieUtil,
  GoneError,
  useApi,
  useApiWithErrors,
} from '@axiom/ui';
import { RolesType } from '@axiom/const';
import { AfcSubmission } from '@axiom/validation';

import { AccountSubmissionsApi } from '../api/account-submissions-api';
import { UserApi } from '../api/user-api';
import { CandidateApi } from '../api/candidate-api';
import { SubmissionsUtil } from '../utils/submissions-util';
import { EngagementTalentUtil } from '../components/EngagementTalent/engagement-talent-util';
import { InstantMatchesApi } from '../api/instant-matches-api';
import { CandidateLegalTechSkillsApi } from '../api/candidate-legal-tech-skills-api';

const { roleDisplayOrder, sortOrderObject } = EngagementTalentUtil;

export type CandidateOpp =
  AfcSubmission['positions'][number]['candidateOpportunities'][number];

export const useEngagementTalentData = () => {
  const { accountId, submissionId, talentSlug } = useParams();
  const isUserLoggedIn = !!CookieUtil.get('user') || null;
  const [response, { data: user } = { data: null }] = useApiWithErrors(
    AccountSubmissionsApi.readAccountSubmissionDetails(accountId, submissionId),
    isUserLoggedIn ? UserApi.readUser() : undefined
  );

  let invalidSubmissionRequest = false;
  if (response instanceof ApiError) {
    if (response instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw response;
    }
  }

  const submission: AfcSubmission | null =
    SubmissionsUtil.getNonInterviewingCandidates(response.data);

  const sortedPositions = submission?.positions?.sort(
    (a, b) => sortOrderObject[a.role] - sortOrderObject[b.role]
  );

  // instantMatches ================================ START
  const [{ data: instantMatches } = { data: [] }] = useApi(
    InstantMatchesApi.readInstantMatches(submissionId)
  );

  const availableInstantMatches = instantMatches.filter(match => !match.passed);

  if (availableInstantMatches.length > 0 && sortedPositions.length === 1) {
    availableInstantMatches.forEach(match => {
      if (
        !sortedPositions[0].candidateOpportunities?.some(
          candidateOpportunity =>
            candidateOpportunity.candidate.id === match.candidate.id
        )
      ) {
        sortedPositions[0].candidateOpportunities?.push({
          candidate: match.candidate,
          badge: match.orderNumber < 4 ? 'Top Match' : null,
          displayBillingRate: 'hourly',
          proposedHourlyRate: match.rate,
          isInstantMatch: true,
        });
      }
    });
  }
  // instantMatches ================================ END

  // groupedCandidateOpps ================================ START
  const filterPositionByRole = (role: RolesType) =>
    sortedPositions
      ?.filter(position => position.role === role)
      ?.flatMap(position => position.candidateOpportunities);
  const groupedCandidateOpps = roleDisplayOrder
    .map(order => ({
      title: order,
      data: filterPositionByRole(order),
    }))
    .filter(item => item.data?.length > 0);
  // groupedCandidateOpps ================================ END

  // allCandidate ================================ START
  const allCandidateOpps = sortedPositions?.flatMap(
    p => p.candidateOpportunities
  );
  // allCandidate ================================ END

  // candidate ================================ START
  const candidateOppFromSlug =
    talentSlug &&
    allCandidateOpps?.find(
      candidateOpp => candidateOpp.candidate.id === talentSlug
    );

  const candidateOpp = candidateOppFromSlug ?? allCandidateOpps?.[0];
  // candidate ================================ END

  // groupedExperiences & legalTechSkills for a candidate ================================ START
  const [
    { data: groupedExperiences } = { data: [] },
    { data: legalTechSkills } = { data: [] },
  ] = useApi(
    candidateOpp?.candidate?.id &&
      CandidateApi.readCandidateGroupedExperiences(candidateOpp.candidate.id, {
        submissionId,
      }),
    isUserLoggedIn &&
      candidateOpp?.candidate?.id &&
      CandidateLegalTechSkillsApi.readLegalTechSkills(candidateOpp.candidate.id)
  );
  // groupedExperiences & legalTechSkills for a candidate ================================ END

  return {
    user,
    accountId,
    submission,
    candidateOpp,
    candidateOppFromSlug,
    groupedCandidateOpps,
    invalidSubmissionRequest,
    talentSlug,
    groupedExperiences,
    allCandidateOpps,
    legalTechSkills,
  };
};
