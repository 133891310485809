import { Api } from '@axiom/ui';
import { Account, AfcSubmission, AfcSubmissionsItem } from '@axiom/validation';

import ApiHelper from '../lib/api-helper';
import { EnvUtil } from '../utils/env-util';

const api = new ApiHelper('ACCOUNTS_SUBMISSIONS');
export const getAccountSubmissionsForStore = async (accountId: string) => {
  const { data } = await api.GET(
    `${EnvUtil.apiAltUrl}/accounts/${accountId}/submissions`
  );
  return data;
};

class AccountSubmissionsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAccountSubmissions(accountId: Account['id']) {
    return super.read<{ data: AfcSubmissionsItem[] }>({
      endpoint: `/accounts/${accountId}/submissions`,
      method: 'GET',
    });
  }

  readAccountSubmissionDetails(
    accountId: Account['id'],
    submissionId: AfcSubmission['submissionId']
  ) {
    return super.read<{ data: AfcSubmission }>({
      endpoint: `/accounts/${accountId}/submissions/${submissionId}`,
      method: 'GET',
    });
  }

  refreshAccountSubmissionDetails(
    accountId: Account['id'],
    submissionId: AfcSubmission['submissionId']
  ) {
    return super.invalidate(
      `/accounts/${accountId}/submissions/${submissionId}`
    );
  }
}

export const AccountSubmissionsApi = new AccountSubmissionsApiClass();
