import React from 'react';
import { AfcSubmission } from '@axiom/validation';

import bannerBackgroundImg from '../../../assets/graphics/textured_dark_background.webp';
import { DarkTheme } from '../../theme/DarkTheme/DarkTheme';
import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { AxiomLogo } from '../../element/AxiomLogo/AxiomLogo';
import { LargeHeader } from '../../content/LargeHeader/LargeHeader';
import { Paragraph } from '../../content/Paragraph/Paragraph';

export const SubmissionPdfHeader = ({
  submission,
  clientUrl,
}: {
  submission: AfcSubmission;
  clientUrl: string;
}) => {
  const displaySalesLeadContact = () => {
    if (submission?.salesLead?.firstName) {
      if (submission.salesLead.lastName) {
        return `${submission.salesLead.firstName} ${submission.salesLead.lastName}`;
      }
      return submission.salesLead.firstName;
    }
    return 'your Axiom rep';
  };

  return (
    <DarkTheme>
      <Layout
        name="SUBMISSION_HEADER"
        background={{
          color: 'panelDark',
          image: bannerBackgroundImg,
          repeat: 'no-repeat',
          position: 'center top',
        }}
      >
        <Gutter top="40px" bottom="24px" horizontal="24px">
          <Grid>
            <GridRow>
              <GridColumn>
                <Gutter bottom="16px">
                  <AxiomLogo name="LOGO" height="47px" />
                </Gutter>
              </GridColumn>
            </GridRow>
            <GridRow gutterBottom="16px">
              <GridColumn>
                <LargeHeader name="COMPANY_NAME">
                  {submission.jobName}
                </LargeHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <Paragraph>
                  This information may no longer be accurate. To view the most
                  up-to-date matches and engagement information, visit{' '}
                  <a href={clientUrl} data-test="LINK">
                    Axiomlaw.com
                  </a>{' '}
                  and sign in as a Client, or reach out to{' '}
                  <Paragraph name="HEADER_DESCRIPTION_CONTACT" as="span">
                    {displaySalesLeadContact()}
                  </Paragraph>{' '}
                  to request access.
                </Paragraph>
              </GridColumn>
            </GridRow>
          </Grid>
        </Gutter>
      </Layout>
    </DarkTheme>
  );
};
