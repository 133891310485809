import React, { useState } from 'react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Layout,
  LayoutItem,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { AfcSubmissionsItem } from '@axiom/validation';
import { AccountConst } from '@axiom/const';

import { generateAHQOpportunityURL } from '../../utils/link-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';
import { EngagementsShareModal } from '../EngagementsShareModal/EngagementsShareModal';
import { EngagementsShareContextMenu } from '../EngagementsShareContextMenu/EngagementsShareContextMenu';
import { useCurrentSubmissionData } from '../../hooks/useCurrentSubmissionData';
import { EngagementHelpModal } from '../EngagementHelpModal/EngagementHelpModal';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';

export const EngagementHeader = () => {
  const params = useParams();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const { account, user } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({ account });
  const submission = params.submissionId
    ? allSubmissions.find(
        (sub: AfcSubmissionsItem) => sub.submissionId === params.submissionId
      )
    : allSubmissions?.[0];
  const { accountId, currentSubmission, invalidSubmissionRequest } =
    useCurrentSubmissionData({
      account,
      submissionId: submission?.submissionId,
    });
  return invalidSubmissionRequest ? null : (
    <>
      <Grid stackable name="CLIENT_ACTIONS_BANNER">
        <GridRow gutterBottom="16px">
          <GridColumn width={12}>
            <Button
              to="../../engagements"
              name="BACK_TO_ENGAGEMENTS"
              pattern="primary"
              variation="minimal"
              icon="arrow-left"
              iconPosition="left"
              onClick={() => {
                AccountSubmissionsApi.refreshAccountSubmissionDetails(
                  account.id,
                  submission.submissionId
                );
              }}
            >
              All Engagements
            </Button>
            <Gutter bottom="8px" only="mobile" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={8} tabletWidth={6} mobileWidth={12}>
            <LargeHeader>{currentSubmission.jobName}</LargeHeader>
            <Gutter bottom="16px" only="mobile" />
          </GridColumn>
          <GridColumn
            largeScreenWidth={4}
            tabletWidth={6}
            mobileWidth={12}
            verticalAlign="bottom"
            textAlign="right"
          >
            <Layout
              direction="horizontal"
              position="right"
              stackableOn="mobile"
            >
              <LayoutItem>
                <EngagementsShareContextMenu
                  user={user}
                  onClickInviteToAccount={() =>
                    setShowShareModal(!showShareModal)
                  }
                  onClickOpenInAhq={() => {
                    window.open(generateAHQOpportunityURL(submission.id));
                  }}
                />
                <Gutter bottom="8px" only="mobile" />
              </LayoutItem>
              {account.accountType === AccountConst.Types.Direct && (
                <>
                  <Gutter right="24px" only="tablet largeScreen" />
                  <LayoutItem>
                    <Button
                      fluid
                      name="need-help-button"
                      pattern="primary"
                      variation="outline"
                      onClick={() => setShowHelpModal(true)}
                    >
                      Need Help?
                    </Button>
                  </LayoutItem>
                </>
              )}
            </Layout>
          </GridColumn>
        </GridRow>
      </Grid>
      {!!showShareModal && (
        <EngagementsShareModal
          accountName={account.calculatedName}
          accountId={accountId}
          currentSubmission={currentSubmission}
          onClose={() => setShowShareModal(!showShareModal)}
        />
      )}
      {showHelpModal && (
        <EngagementHelpModal
          onClose={() => setShowHelpModal(false)}
          currentSubmission={currentSubmission}
        />
      )}
    </>
  );
};
