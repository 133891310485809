import React, { isValidElement } from 'react';
import moment from 'moment';

import { CondensedSmall } from '../../content/CondensedSmall/CondensedSmall';
import { Gutter } from '../../layout/Gutter/Gutter';

export const CalendarTimeSlot = ({
  value,
  children,
}: {
  value: Date;
  children: JSX.Element;
}) => {
  return React.cloneElement(
    children,
    {
      'data-test': 'TIME_SLOT',
    },
    isValidElement(children?.props?.children) ? (
      <Gutter horizontal="6px">
        <CondensedSmall name="TIME">
          {moment(value).format('ha')}
        </CondensedSmall>
      </Gutter>
    ) : null
  );
};
