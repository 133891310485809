import { AfcSubmissionsItem } from '@axiom/validation';

import { StringUtil } from './string-util';

export const SubmissionUtil = {
  getSubmissionFileName(submission: AfcSubmissionsItem) {
    const { jobName, submissionLastUpdatedAt } = submission;
    const date = new Date(submissionLastUpdatedAt || 0)
      .toISOString()
      .slice(0, 10);
    return `Axiom Submission_${StringUtil.cleanStringForFileName(
      jobName
    )}_${date}.pdf`;
  },
};
