import React, { useMemo } from 'react';
import { LanguageConst } from '@axiom/const';
import { AfcSubmissionCandidate, CandidateLanguage } from '@axiom/validation';

import { Gutter } from '../../layout/Gutter/Gutter';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

import { TalentLanguagesItem } from './TalentLanguagesItem';

export type ProficiencyValues =
  (typeof LanguageConst.LANGUAGE_PROFICIENCIES)[keyof typeof LanguageConst.LANGUAGE_PROFICIENCIES];
type SkillsValues =
  (typeof LanguageConst.LANGUAGE_SKILLS)[keyof typeof LanguageConst.LANGUAGE_SKILLS];

interface SkillsProficiency {
  basic?: SkillsValues[];
  fluent?: SkillsValues[];
  proficient?: SkillsValues[];
}

interface GroupLanguages {
  name?: string;
  skillsByProficiency?: SkillsProficiency;
}

const groupLanguages = (languages: CandidateLanguage[]): GroupLanguages[] => {
  if ((languages || []).length === 0) return [];

  return Object.entries(
    languages.reduce(
      (acc, curr) => {
        const { name, languageProficiency, languageSkill } = curr;
        if (name) {
          acc[name] = acc[name] || {
            basic: [],
            proficient: [],
            fluent: [],
          };
          if (
            LanguageConst.LANGUAGE_SKILLS[
              languageSkill as keyof typeof LanguageConst.LANGUAGE_SKILLS
            ]
          ) {
            acc[name][languageProficiency as keyof SkillsProficiency]?.push(
              LanguageConst.LANGUAGE_SKILLS[
                languageSkill as keyof typeof LanguageConst.LANGUAGE_SKILLS
              ]
            );
          }
        }
        return acc;
      },
      {} as { [key: string]: SkillsProficiency }
    )
  )
    .sort(([nameA, proficienciesA], [nameB, proficienciesB]) => {
      if (proficienciesA.fluent && proficienciesB.fluent) {
        // if both language groups have at least one fluent skill
        if (
          proficienciesA.fluent.length > 0 &&
          proficienciesB.fluent.length > 0
        ) {
          // sort by name
          return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
        }
        // otherwise, prefer the language group with at least one fluent skill
        if (proficienciesA.fluent.length > 0) {
          return -1;
        }
        if (proficienciesB.fluent.length > 0) {
          return 1;
        }
      }
      if (proficienciesA.proficient && proficienciesB.proficient) {
        // do the same for proficient because neither group has a fluent skill
        if (
          proficienciesA.proficient.length > 0 &&
          proficienciesB.proficient.length > 0
        ) {
          return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
        }
        if (proficienciesA.proficient.length > 0) {
          return -1;
        }
        if (proficienciesB.proficient.length > 0) {
          return 1;
        }
      }
      // both groups have only basic skills, so sort by name
      return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
    })
    .map(([name, skillsByProficiency]) => ({
      name,
      skillsByProficiency,
    }));
};

export const TalentLanguages = ({
  candidate,
}: {
  candidate: AfcSubmissionCandidate;
}) => {
  const groupedLanguages = useMemo(
    () => groupLanguages(candidate.languages || []),
    [candidate.languages]
  );

  return groupedLanguages.length === 0 ? (
    <Paragraph name="empty-language-placeholder">--</Paragraph>
  ) : (
    groupedLanguages.map(({ name, skillsByProficiency }, index) => (
      <div key={name} data-test="LANGUAGE_GROUP">
        <ParagraphHeader name="NAME">{name}</ParagraphHeader>
        {!!skillsByProficiency?.fluent?.length &&
          skillsByProficiency?.fluent?.length > 0 && (
            <TalentLanguagesItem
              proficiency={
                LanguageConst.LANGUAGE_PROFICIENCIES.fluent as ProficiencyValues
              }
              skills={skillsByProficiency.fluent}
            />
          )}
        {!!skillsByProficiency?.proficient?.length &&
          skillsByProficiency.proficient.length > 0 && (
            <TalentLanguagesItem
              proficiency={
                LanguageConst.LANGUAGE_PROFICIENCIES
                  .proficient as ProficiencyValues
              }
              skills={skillsByProficiency.proficient}
            />
          )}
        {!!skillsByProficiency?.basic?.length &&
          skillsByProficiency.basic.length > 0 && (
            <TalentLanguagesItem
              proficiency={
                LanguageConst.LANGUAGE_PROFICIENCIES.basic as ProficiencyValues
              }
              skills={skillsByProficiency.basic}
            />
          )}
        {index < groupedLanguages.length - 1 && <Gutter bottom="12px" />}
      </div>
    ))
  );
};
