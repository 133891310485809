import { AhqSubmissionCandidateExperience } from '@axiom/validation';

export class SubmissionExperienceModel {
  props: Partial<AhqSubmissionCandidateExperience> = {};

  constructor(props: Partial<AhqSubmissionCandidateExperience> = {}) {
    this.props = props;
  }

  calculatedDescription() {
    return (
      this.props.submissionDescription ||
      this.props.description ||
      ''
    ).trim();
  }
}
