import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../Button/Button';
import { Layout } from '../../layout/Layout/Layout';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow, GridRowProps } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';

export type CarouselType = {
  name?: string;
  children: React.ReactNode | [React.ReactNode];
};

export const Carousel = ({ name, children }: CarouselType) => {
  const childElements = Array.isArray(children) ? children : [children];
  const childCount = childElements.length;
  const { isMobile, isTablet } = useBreakpoint();
  const [pageNumber, setPageNumber] = useState(1);
  const [elmsToDisplay, setElmsToDisplay] = useState<React.ReactNode[]>([]);
  const displayCount: GridRowProps['columns'] = (() => {
    if (isMobile) {
      return 1;
    } else if (isTablet) {
      return 2;
    }

    return 3;
  })();

  const paginationCount = Math.ceil(childCount / displayCount);

  useEffect(() => {
    const spacesToFill =
      (paginationCount * displayCount - childCount) % displayCount;
    const fillers =
      spacesToFill >= 1
        ? (Array.from({ length: spacesToFill }).fill(
            <div data-test="FILLER_DOM" />
          ) as React.ReactNode[])
        : [];

    setPageNumber(1);
    setElmsToDisplay([...childElements, ...fillers]);
  }, [childCount, displayCount]);

  const getDisplaySet = useCallback(() => {
    const startIndex = (pageNumber - 1) * displayCount;
    const endIndex = startIndex + displayCount;

    return elmsToDisplay.length > displayCount
      ? elmsToDisplay.slice(startIndex, endIndex)
      : elmsToDisplay;
  }, [pageNumber, elmsToDisplay]);

  const goToPage = (n: number) => {
    setPageNumber(n);
  };

  const buildPagination = useCallback(() => {
    const data = Array.from({ length: paginationCount })
      .fill(null)
      .map((_, idx) => {
        const n = idx + 1;
        return (
          <Button
            pattern="primary"
            variation="minimal"
            className={`pagination-item dot ${pageNumber === n ? 'active' : ''}`}
            name="PAGINATION_DOT"
            key={`${idx.toString()}pagination-item`}
            onClick={() => pageNumber !== n && goToPage(n)}
          >
            <span />
          </Button>
        );
      });

    const previousChevron = (
      <Button
        pattern="secondary"
        variation="minimal"
        className="pagination-item chevron"
        icon="arrow-left"
        disabled={pageNumber - 1 <= 0}
        onClick={() => goToPage(pageNumber - 1)}
        key="previousChevron"
        name="PREVIOUS_CHEVRON"
      />
    );

    const nextChevron = (
      <Button
        pattern="secondary"
        variation="minimal"
        className="pagination-item chevron"
        icon="arrow-right"
        disabled={pageNumber + 1 > paginationCount}
        onClick={() => goToPage(pageNumber + 1)}
        key="nextChevron"
        name="NEXT_CHEVRON"
      />
    );

    return [previousChevron, ...data, nextChevron];
  }, [pageNumber, paginationCount]);

  return (
    <Grid name={name || 'CAROUSEL'} className="carouselContainer">
      <GridRow columns={displayCount}>
        {getDisplaySet().map((child, idx) => {
          let id: string | null = null;
          if ((child as React.ReactElement)?.props) {
            const reactChild = child as React.ReactElement;
            id = reactChild.props.id || reactChild.props.name;
          }

          if (!id) {
            id = idx.toString();
          }
          return (
            <GridColumn stretched key={id}>
              {child}
            </GridColumn>
          );
        })}
      </GridRow>
      {paginationCount > 1 && (
        <GridRow gutterTop="24px">
          <GridColumn>
            <Layout position="center middle">{buildPagination()}</Layout>
          </GridColumn>
        </GridRow>
      )}
    </Grid>
  );
};
