import memoize from 'lodash/memoize';
import {
  isClientUser,
  isEnvoyAdmin,
  isEnvoyUser,
  isLpUser,
  isSalesUser,
} from '@axiom/utils';
import {
  PermissionImpersonationRoles,
  PermissionImpersonationRolesValuesType,
} from '@axiom/const';
import { User, CalendarPermissions } from '@axiom/validation';

const getBasePermissions = (): CalendarPermissions => ({
  staticEvent: {
    canCreate: false,
    canDragToCreate: false,
    canCreateMultiple: false,
    canCreateFreely: false,
    canMove: false,
    canResize: false,
    canDelete: false,
    canDeleteSaved: false,
  },
  eventBoundary: {
    canCreate: false,
    canDragToCreate: false,
    canMove: false,
    canResize: false,
    canDelete: false,
  },
  allEvents: {
    canSameDay: false,
  },
});

export const CalendarPermissionsUtil = (
  user?: User,
  appType?: PermissionImpersonationRolesValuesType
) => {
  const isImpersonatingClientUser = () => {
    const isImpersonationAllowed =
      isEnvoyUser(user) || isEnvoyAdmin(user) || isSalesUser(user);
    return (
      appType === PermissionImpersonationRoles.client && isImpersonationAllowed
    );
  };

  const isImpersonatingTSUser = () => {
    return appType === PermissionImpersonationRoles.envoy && isEnvoyAdmin(user);
  };

  const isImpersonatingLPUser = () => {
    const isTsOrAdmin = isEnvoyUser(user) || isEnvoyAdmin(user);
    return appType === PermissionImpersonationRoles.talent && isTsOrAdmin;
  };

  const userPermissions = memoize((): CalendarPermissions => {
    // SalesUser has the least permissions.
    const data = getBasePermissions();
    const impersonatedClient = isImpersonatingClientUser();
    const impersonatedTS = isImpersonatingTSUser();
    const impersonatedTalent = isImpersonatingLPUser();

    if (isClientUser(user) || impersonatedClient) {
      data.staticEvent.canCreate = true;
      data.staticEvent.canMove = true;
      data.staticEvent.canDelete = true;
      data.staticEvent.canDeleteSaved = false;
    } else if (isLpUser(user) || impersonatedTalent) {
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
    } else if (isEnvoyUser(user) || impersonatedTS) {
      data.staticEvent.canDelete = true;
      data.staticEvent.canDeleteSaved = true;
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
    } else if (isEnvoyAdmin(user)) {
      data.staticEvent.canCreate = true;
      data.staticEvent.canDragToCreate = true;
      data.staticEvent.canCreateMultiple = true;
      data.staticEvent.canCreateFreely = true;
      data.staticEvent.canMove = true;
      data.staticEvent.canResize = true;
      data.staticEvent.canDelete = true;
      data.staticEvent.canDeleteSaved = true;
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
      data.allEvents.canSameDay = true;
    }

    return data;
  });

  const changePermissionsToEditStaticEvent = (): CalendarPermissions => {
    if (isEnvoyUser(user) || isEnvoyAdmin(user)) {
      return {
        eventBoundary: getBasePermissions().eventBoundary,
        staticEvent: {
          canCreate: true,
          canDragToCreate: true,
          canCreateMultiple: true,
          canCreateFreely: true,
          canMove: true,
          canResize: true,
          canDelete: true,
          canDeleteSaved: true,
        },
        allEvents: {
          canSameDay: true,
        },
      };
    }

    return getBasePermissions();
  };

  const changePermissionsToEditEventBoundary = (): CalendarPermissions => {
    if (isEnvoyUser(user) || isEnvoyAdmin(user)) {
      return {
        staticEvent: getBasePermissions().staticEvent,
        eventBoundary: {
          canCreate: true,
          canDragToCreate: true,
          canMove: true,
          canResize: true,
          canDelete: true,
        },
        allEvents: {
          canSameDay: true,
        },
      };
    }

    return getBasePermissions();
  };

  return {
    userPermissions,
    getBasePermissions,
    changePermissionsToEditStaticEvent,
    changePermissionsToEditEventBoundary,
  };
};
