import React, { useRef, useState, useEffect } from 'react';
import { string } from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CandidateOpportunitiesConst, CandidateImageConst } from '@axiom/const';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  ContextMenu,
  ContextMenuItem,
  FlexiCardGrid,
  FlexiCardGridBody,
  FlexiCardGridHeader,
  Gutter,
  Text,
  Header3,
  Flashy,
  useApi,
} from '@axiom/ui';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import InfoPopover from '../InfoPopover/InfoPopover';
import { EnvUtil } from '../../utils/env-util';
import { ProfileImage } from '../ProfileImage/ProfileImage';
import { AccountCandidatesStore } from '../../stores/account-candidates-store';
import { FormDigitalBenchRequestRateModalStore } from '../../stores/form-digital-bench-request-rate-modal-store';
import { FormDigitalBenchRequestUpdateModalStore } from '../../stores/form-digital-bench-request-update-modal-store';
import { FormDigitalBenchEditHighlightsModalStore } from '../../stores/form-digital-bench-edit-highlights-modal-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { ShareLinkModalStore } from '../../stores/share-link-modal-store';
import { COLORS } from '../../themes/constants';
import { DateUtil } from '../../utils/date-util';
import { isEnvoyUser as getIsEnvoyUser } from '../../utils/roles-util';
import { WindowUtil } from '../../utils/window-util';
import { getShareUrl } from '../../utils/link-util';
import { ShareLinkModal } from '../ShareLinkModal/ShareLinkModal';
import { CandidateUtil } from '../../utils/candidate-util';
import { TaxonomyApi } from '../../api/taxonomy-api';

import { MyBenchCardHidden } from './MyBenchCardHidden';
import { MyBenchCardShown } from './MyBenchCardShown';
import { MyBenchEngagedFor } from './MyBenchEngagedFor';
import { MyBenchRollingOff } from './MyBenchRollingOff';
import { BENCH_CANDIDATES_FORM_TYPES as formTypes } from './my-bench-const';
import {
  BCCardButtons,
  BCCardHeader,
  BCItemGridWrapper,
} from './MyBenchStyles';
import { MyBenchConfirmSubmitModal } from './MyBenchConfirmSubmitModal';
import { MyBenchDigitalModal } from './MyBenchDigitalModal';

const cardId = candidateId => `talent-${candidateId}`;
const { PossibleImageSizes } = CandidateImageConst;

export const MyBenchItemGrid = ({
  accountCandidates,
  accountId,
  user,
  practiceAreas,
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const { i18n } = useLingui();

  const isEnvoyUser = getIsEnvoyUser(user);
  const selectedTalentRef = useRef(null);
  const [toggleVisibleCandidate, setToggleVisibleCandidate] = useState(null);
  const [confirmModalType, setConfirmModalType] = useState();
  const [confirmModalHeader, setConfirmModalHeader] = useState();
  const [cancelled, setCancelled] = useState(false);
  const clearConfirmModal = () => {
    setConfirmModalType(null);
    setConfirmModalHeader(null);
  };

  const selectedTalentId = WindowUtil.getUrlQuery().talent;
  const displayCandidates = accountCandidates.data.filter(
    candidate => isEnvoyUser || !candidate.isHiddenExternal
  );

  useEffect(() => {
    const { modal, talent } = WindowUtil.getUrlQuery();
    const card = selectedTalentRef.current;
    if (!card) return;

    card.scrollIntoView();

    // now account for fixed header
    const scrolledY = window.scrollY;

    const desktopHeaderAndPaddingHeight = 130;
    if (scrolledY) {
      window.scroll(0, scrolledY - desktopHeaderAndPaddingHeight);
    }
    // All currently handled modals are unavailable to Envoy users.
    if (!modal || isEnvoyUser) return;

    const candidate = displayCandidates.find(c => c.id === talent);

    switch (modal) {
      case formTypes.requestRate: {
        if (
          candidate.accountCandidateStatus !==
          CandidateOpportunitiesConst.CandidateStatuses.Engaged
        ) {
          FormDigitalBenchRequestRateModalStore.load(candidate, user);
        }
        break;
      }
      default:
      // no action
    }
  }, []);

  return (
    <BCItemGridWrapper>
      <ShareLinkModal />
      <FormStateTreatment
        isModalState
        name="DIGITAL_BENCH_REQUEST_UPDATE_TREATMENT"
        formStore={FormDigitalBenchRequestUpdateModalStore}
        onSubmit={result => {
          // testing submissions can contain an invalid email address
          // this allows the form to silently fail
          if (result?.applicationError) {
            setCancelled(true);
            FormDigitalBenchRequestUpdateModalStore.clearState(null);
          } else {
            setCancelled(false);
            FormDigitalBenchRequestUpdateModalStore.clearState(result);
          }
        }}
        renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
          <MyBenchDigitalModal
            name="DIGITAL_BENCH_UPDATE_ENGAGEMENT"
            header={i18n._(
              t`Confirm your request for ${formData.candidateName}`
            )}
            placeholder={i18n._(
              t`Tell us more about what engagement details you’d like to update for this talent.`
            )}
            inputLabel={i18n._(t`Add a message:`)}
            fieldName="description"
            onClose={() => {
              fireCancel();
              setCancelled(true);
              WindowUtil.setUrlQuery({ account: accountId }, true);
            }}
            onSubmit={() => {
              fireSubmit();
              setConfirmModalHeader(
                i18n._(t`Confirm your request for ${formData.candidateName}`)
              );
              setConfirmModalType(formTypes.requestUpdate);
            }}
          />
        )}
        renderNonLoadedView={() =>
          confirmModalType === formTypes.requestUpdate && !cancelled ? (
            <MyBenchConfirmSubmitModal
              header={confirmModalHeader}
              onConfirm={clearConfirmModal}
            />
          ) : null
        }
      />
      <FormStateTreatment
        isModalState
        name="DIGITAL_BENCH_REQUEST_RATE_TREATMENT"
        formStore={FormDigitalBenchRequestRateModalStore}
        onSubmit={result => {
          if (result?.applicationError) {
            // testing submissions can contain an invalid email address
            // this allows the form to silently fail
            setCancelled(true);
            FormDigitalBenchRequestRateModalStore.clearState(null);
          } else {
            FormDigitalBenchRequestRateModalStore.clearState(result);
            setCancelled(false);
          }
        }}
        renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
          <MyBenchDigitalModal
            name="DIGITAL_BENCH_REQUEST_RATE"
            header={i18n._(
              t`Confirm your request for ${formData.candidateName}`
            )}
            inputLabel={i18n._(t`Add a message:`)}
            fieldName="description"
            placeholder={i18n._(
              t`Anything else we should know about the type of legal work you’re considering this talent for?`
            )}
            onClose={() => {
              fireCancel();
              setCancelled(true);
              WindowUtil.setUrlQuery({ account: accountId }, true);
            }}
            onSubmit={() => {
              fireSubmit();
              setConfirmModalHeader(
                i18n._(t`Request ${formData.candidateName}'s Rate`)
              );
              setConfirmModalType(formTypes.requestRate);
            }}
          />
        )}
        renderNonLoadedView={() =>
          confirmModalType === formTypes.requestRate && !cancelled ? (
            <MyBenchConfirmSubmitModal
              header={confirmModalHeader}
              onConfirm={clearConfirmModal}
            />
          ) : null
        }
      />
      <FormStateTreatment
        isModalState
        name="DIGITAL_BENCH_EDIT_HIGHLIGHTS_TREATMENT"
        formStore={FormDigitalBenchEditHighlightsModalStore}
        renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
          <MyBenchDigitalModal
            name="DIGITAL_BENCH_EDIT_HIGHLIGHTS"
            fieldName="notes"
            header={i18n._(
              t`Edit ${formData.calculatedDisplayName}'s Bench Highlights`
            )}
            inputLabel={i18n._(t`Bench Highlights:`)}
            maxLength={500}
            onClose={fireCancel}
            onSubmit={fireSubmit}
            submitText={i18n._(t`Save`)}
            extraSubText={
              formData.notesUpdatedByUserFullName ? (
                <Trans>
                  Last edited by{' '}
                  <span data-test="NOTES_UPDATED_BY_USER_FULL_NAME">
                    {formData.notesUpdatedByUserFullName}
                  </span>{' '}
                  on{' '}
                  <span data-test="NOTES_UPDATED_AT">
                    {DateUtil.displayShortDate(formData.notesUpdatedAt)}
                  </span>
                </Trans>
              ) : (
                <>
                  <span data-test="NOTES_UPDATED_BY_USER_FULL_NAME" />
                  <span data-test="NOTES_UPDATED_AT" />
                  <Trans>Profile Default</Trans>
                </>
              )
            }
          />
        )}
      />
      {!!toggleVisibleCandidate && (
        <Modal name="SHOW_OR_HIDE_TALENT_DIALOG" size="confirm">
          <ModalHeader onClose={() => setToggleVisibleCandidate(null)}>
            {i18n._(
              toggleVisibleCandidate.isHiddenExternal
                ? t`Are you sure you want to show this talent on the client bench?`
                : t`Are you sure you want to hide this talent from the client bench?`
            )}
          </ModalHeader>
          <ModalSection>
            {i18n._(
              toggleVisibleCandidate.isHiddenExternal
                ? t`The client will be able to see ${toggleVisibleCandidate.calculatedDisplayName} on their bench.`
                : t`The client will no longer be able to see ${toggleVisibleCandidate.calculatedDisplayName} on their bench.`
            )}
          </ModalSection>
          <ModalFooter>
            <Button onClick={() => setToggleVisibleCandidate(null)}>
              {i18n._(t`Cancel`)}
            </Button>
            <Button
              onClick={() => {
                setToggleVisibleCandidate(null);
                AccountCandidatesStore.update(
                  accountId,
                  toggleVisibleCandidate.id,
                  practiceAreas,
                  taxonomy,
                  { isHiddenExternal: !toggleVisibleCandidate.isHiddenExternal }
                );
                setToggleVisibleCandidate(null);
              }}
            >
              {i18n._(
                toggleVisibleCandidate.isHiddenExternal
                  ? t`Show on Bench`
                  : t`Hide from Bench`
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <FlexiCardGrid
        data={displayCandidates}
        renderCard={({ dataItem: candidate }) => {
          const candidateBioUrl = getShareUrl(candidate, practiceAreas);
          const isEngaged =
            candidate.accountCandidateStatus ===
            CandidateOpportunitiesConst.CandidateStatuses.Engaged;
          const billingHoursPerWeekCalculation =
            CandidateUtil.calculateBillingHoursPerWeek(candidate);

          return (
            <>
              <FlexiCardGridHeader
                className={
                  candidate.isHiddenExternal ? 'hidden-external-header' : null
                }
              >
                <div data-test="CANDIDATE_CARD">
                  <BCCardHeader
                    id={cardId(candidate.id)}
                    ref={
                      candidate.id === selectedTalentId
                        ? selectedTalentRef
                        : null
                    }
                  >
                    <div className="profile-circle">
                      <ProfileImage
                        size="75px"
                        color={COLORS.denim}
                        fullName={candidate.calculatedDisplayName}
                        src={CandidateUtil.getProfileImageUri(
                          candidate,
                          PossibleImageSizes.W_100
                        )}
                      />
                    </div>
                    <Gutter left="10px" className="full-width">
                      <Header3 accent name="CANDIDATE_NAME">
                        {candidate.calculatedDisplayName}
                      </Header3>
                      <Text>
                        <div data-test="CANDIDATE_STATUS">
                          {isEngaged ? (
                            <Trans>
                              Currently engaged
                              {billingHoursPerWeekCalculation >= 40 ? (
                                <Flashy bold> full-time</Flashy>
                              ) : (
                                ''
                              )}
                            </Trans>
                          ) : (
                            <Trans>Previously engaged</Trans>
                          )}
                        </div>
                        {!!isEngaged && (
                          <div className="roll-off-row">
                            <MyBenchRollingOff candidate={candidate} />
                            {!!candidate.hasOtherEngagements && (
                              <div className="info-icon">
                                <InfoPopover
                                  name="hasOtherEngagementsTooltip"
                                  position="right"
                                >
                                  <Trans>
                                    These details are for the talent's nearest
                                    roll-off date. For their other engagements,
                                    please contact your Axiom team.
                                  </Trans>
                                </InfoPopover>
                              </div>
                            )}
                          </div>
                        )}
                      </Text>
                      {billingHoursPerWeekCalculation < 40 && !!isEngaged && (
                        <Text className="float-left">
                          <MyBenchEngagedFor candidate={candidate} />
                        </Text>
                      )}
                      {!!isEnvoyUser && (
                        <ContextMenu className="float-right" direction="left">
                          <ContextMenuItem
                            name="SHOW_OR_HIDE_TALENT"
                            onClick={() => setToggleVisibleCandidate(candidate)}
                          >
                            {candidate.isHiddenExternal ? (
                              <Trans>Show on Bench</Trans>
                            ) : (
                              <Trans>Hide From Bench</Trans>
                            )}
                          </ContextMenuItem>
                          <ContextMenuItem
                            name="EDIT_HIGHLIGHTS"
                            onClick={() =>
                              FormDigitalBenchEditHighlightsModalStore.load(
                                accountId,
                                candidate,
                                practiceAreas,
                                taxonomy
                              )
                            }
                          >
                            <Trans>Edit Bench Highlights</Trans>
                          </ContextMenuItem>
                          <ContextMenuItem
                            name="VIEW_TALENT_PROFILE"
                            onClick={() => {
                              window.open(
                                `${EnvUtil.envoyUrl}/talent-detail/${candidate.id}`,
                                '_blank'
                              );
                            }}
                          >
                            <Trans>View Talent Profile</Trans>
                          </ContextMenuItem>
                        </ContextMenu>
                      )}
                    </Gutter>
                  </BCCardHeader>
                </div>
              </FlexiCardGridHeader>
              {candidate.isHiddenExternal ? (
                <MyBenchCardHidden candidate={candidate} />
              ) : (
                <MyBenchCardShown
                  accountId={accountId}
                  candidate={candidate}
                  isEngaged={isEngaged}
                  getPracticeAreaText={practiceArea =>
                    CandidateUtil.formatPracticeAreaSpecialty(
                      practiceAreas,
                      practiceArea?.id
                    ) ?? i18n._(t`Not Available`)
                  }
                  isEnvoyUser={isEnvoyUser}
                  user={user}
                />
              )}
              <FlexiCardGridBody
                className={
                  candidate.isHiddenExternal ? 'hidden-external-buttons' : null
                }
              >
                <div data-test="CANDIDATE_CARD_CTA_ROW">
                  <BCCardButtons>
                    <Button
                      pattern="secondary"
                      to={candidateBioUrl}
                      name="VIEW_MORE_BUTTON"
                    >
                      {i18n._(t`View Profile`)}
                    </Button>
                    {!!candidate.isSpotlightedLawyer && (
                      <Button
                        name="SHARE_TALENT_BUTTON"
                        pattern="secondary"
                        onClick={() => {
                          ShareLinkModalStore.load(
                            `${EnvUtil.clientUrl}${candidateBioUrl}`
                          );
                        }}
                      >
                        {i18n._(t`Share`)}
                      </Button>
                    )}
                  </BCCardButtons>
                </div>
              </FlexiCardGridBody>
            </>
          );
        }}
      />
    </BCItemGridWrapper>
  );
};

MyBenchItemGrid.propTypes = {
  accountCandidates: AccountCandidatesStore.getDataShape().isRequired,
  accountId: string.isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};
