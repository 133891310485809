import React, { useState } from 'react';
import {
  Button,
  Banner,
  Checkbox,
  Flashy,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  IconButton,
  ImageCircle,
  Input,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  CandidateProfileUtil,
  Icon,
  Dropdown,
  BadRequestError,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';
import {
  Candidate,
  User,
  CalendarEventType,
  Contact,
  AfcSubmission,
} from '@axiom/validation';

import { ConfidentialRateConfirmModal } from '../ConfidentialRateConfirmModal/ConfidentialRateConfirmModal';
import {
  TalentInterviewConfirmModalSchema,
  TalentInterviewConfirmModal as TalentInterviewConfirmModalType,
} from '../../schema/talent-interview-confirm-modal-schema';

type SetValueType = (
  values: React.SetStateAction<TalentInterviewConfirmModalType>,
  shouldValidate?: boolean
) => void;

export type InterviewConfirmModalType = {
  loggedInUser: User;
  submission: AfcSubmission;
  candidate: Candidate;
  onClose: (arg0: boolean) => void;
  onConfirm: (
    arg: TalentInterviewConfirmModalType,
    scheduledEvent?: CalendarEventType
  ) => Promise<unknown>;
  scheduledEvent?: CalendarEventType;
  interviewDate?: string;
  primaryContactId?: Contact['id'];
  onBackHandler?: () => void;
  availabilityNote?: string;
};

type ContactOptionType = { value: string; label: string };

export const TalentInterviewConfirmModal = ({
  loggedInUser,
  submission,
  candidate,
  onClose,
  onConfirm,
  scheduledEvent,
  interviewDate,
  primaryContactId,
  onBackHandler,
  availabilityNote,
}: InterviewConfirmModalType) => {
  const [adHocContactsCount, setAdHocContactsCount] = useState(0);
  const [isInterviewTimeUnavailable, setIsInterviewTimeUnavailable] =
    useState(false);
  const [
    isRateConfidentialConfirmModalOpen,
    setIsRateConfidentialConfirmModalOpen,
  ] = useState(true);
  const initValues: TalentInterviewConfirmModalType = {
    contacts: [],
    adHocContacts: [],
    isZoomNotPreferred: false,
  };

  let currentUserIsAContact = false;

  const contacts: ContactOptionType[] =
    submission?.contacts?.reduce(
      (crnt, data: AfcSubmission['contacts'][number]) => {
        const { fullName, email, id } = data;
        // Don't add the client user to the selection list if no linked user record exists
        if (!id) {
          return crnt;
        }

        let action: 'push' | 'unshift' = 'push';
        if (loggedInUser?.contactId === id) {
          action = 'unshift';
          currentUserIsAContact = true;
          initValues.contacts.push(id);
        } else if (
          primaryContactId &&
          id === primaryContactId &&
          !loggedInUser?.contactId
        ) {
          action = 'unshift';
          initValues.contacts.push(id);
        }

        crnt[action]({
          label: `${fullName} (${email})`,
          value: id,
        });

        return crnt;
      },
      [] as ContactOptionType[]
    ) || [];

  if (loggedInUser?.contactId && !currentUserIsAContact) {
    contacts.unshift({
      label: `${loggedInUser.fullName} (${loggedInUser.email})`,
      value: loggedInUser.contactId,
    });
    initValues.contacts.push(loggedInUser.contactId);
  }

  const handleOnSubmit = async (
    formData: TalentInterviewConfirmModalType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    try {
      const payload = { ...initValues, ...formData };
      if (availabilityNote) {
        Object.assign(payload, { availabilityNote });
      }
      await onConfirm(payload, scheduledEvent);
    } catch (error) {
      if (error instanceof BadRequestError) {
        const errorObj = error.getResponse();

        // @ts-expect-error Needs new type
        if (errorObj?.text?.includes('domain')) {
          setFieldError(
            `errorMissMatchEmailDomain`,
            'For enhanced security, all attendees must have matching email domains from the same organization.'
          );

          return;
        }
        setIsInterviewTimeUnavailable(true);
      } else {
        throw error;
      }
    }
  };

  const handleOnClickOneMore = (setValues: SetValueType) => {
    setValues(
      (prev: TalentInterviewConfirmModalType) => ({
        ...prev,
        adHocContacts: [
          ...prev.adHocContacts,
          {
            firstName: null,
            lastName: null,
            email: null,
          },
        ],
      }),
      false
    );

    setAdHocContactsCount(prev => prev + 1);
  };

  const handleOnClickTrash = (index: number, setValues: SetValueType) => {
    setValues((prev: TalentInterviewConfirmModalType) => {
      const { adHocContacts } = prev;
      adHocContacts.splice(index, 1);
      return { ...prev, adHocContacts };
    }, false);

    setAdHocContactsCount(prev => prev - 1);
  };
  return isRateConfidentialConfirmModalOpen ? (
    <ConfidentialRateConfirmModal
      candidate={candidate}
      onClose={onClose}
      onConfirm={() => setIsRateConfidentialConfirmModalOpen(false)}
    />
  ) : (
    <Form
      name="MODAL_FORM"
      initialValues={initValues}
      onSubmit={handleOnSubmit}
      schema={TalentInterviewConfirmModalSchema}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal name="INTERVIEW_ATTENDEES" size="large">
            <ModalHeader name="HEADER" onClose={() => onClose(false)}>
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader name="HEADER_TEXT">
                    {scheduledEvent ? '' : 'Request to'} Interview{' '}
                    {candidate.calculatedFirstName}
                  </SmallHeader>
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Grid>
                {!!scheduledEvent && (
                  <GridRow gutterBottom="16px">
                    <GridColumn>
                      {!isInterviewTimeUnavailable && (
                        <Banner name="INTERVIEW_BANNER">
                          <Flashy bold color="controlSecondary">
                            Interview Date{' '}
                          </Flashy>
                          {interviewDate}
                        </Banner>
                      )}
                      {isInterviewTimeUnavailable && (
                        <Banner
                          name="INTERVIEW_UNAVAILABLE_BANNER"
                          type="error"
                          impact="high"
                        >
                          <Layout position="middle" horizontalGutter="8px">
                            <Paragraph>
                              <Flashy color="textAlert">
                                <Icon name="alert-filled" />
                              </Flashy>
                            </Paragraph>
                            <Paragraph>
                              The selected interview time is no longer
                              available. Please try again.
                            </Paragraph>
                          </Layout>
                        </Banner>
                      )}
                    </GridColumn>
                  </GridRow>
                )}

                <GridRow gutterBottom="16px">
                  <GridColumn>
                    <FormGroup
                      label="Who else should we invite to the interview?"
                      description="All contacts you select or add will be invited to join the interview."
                    >
                      {contacts.map((contact: ContactOptionType) => (
                        <Gutter key={contact.value} bottom="8px">
                          <Checkbox
                            name="contacts"
                            displayValue={contact.label}
                            option={contact.value}
                          />
                        </Gutter>
                      ))}
                    </FormGroup>
                  </GridColumn>
                </GridRow>
                {adHocContactsCount > 0 && (
                  <GridRow gutterBottom="16px">
                    <GridColumn>
                      <Banner impact="high" name="ADHOC_DISCLAIMER_BANNER">
                        Axiom cannot send communications to new recipients
                        outside of the United States unless they opt in and
                        share their email with us. However, you are free to
                        forward the interview invitation to anyone you choose.
                      </Banner>
                    </GridColumn>
                  </GridRow>
                )}

                {Array.from({ length: adHocContactsCount })
                  .fill(null)
                  .map((_, i) => (
                    <React.Fragment key={`adHocContacts_${i.toString(10)}`}>
                      <GridRow
                        name="ADHOC_ROW"
                        stretched
                        columns={4}
                        gutterBottom="12px"
                      >
                        <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
                          <Input
                            name={`adHocContacts.${i}.firstName`}
                            label="First Name"
                            placeholder="Entered Text"
                          />
                        </GridColumn>
                        <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
                          <Input
                            name={`adHocContacts.${i}.lastName`}
                            label="Last Name"
                            placeholder="Entered Text"
                          />
                        </GridColumn>
                        <GridColumn largeScreenWidth={4} smallScreenWidth={10}>
                          <Input
                            name={`adHocContacts.${i}.email`}
                            label="Email"
                            placeholder="Entered Text"
                          />
                        </GridColumn>
                        <GridColumn largeScreenWidth={3} smallScreenWidth={10}>
                          <Dropdown
                            label="Located in the United States?"
                            options={[
                              { label: 'Yes', value: 'Yes' },
                              { label: 'No', value: 'No' },
                            ]}
                            displayKey="label"
                            valueKey="value"
                            name={`adHocContacts.${i}.isUnitedStates`}
                          />
                        </GridColumn>
                        <GridColumn largeScreenWidth={1} smallScreenWidth={2}>
                          <Gutter top="40px">
                            <IconButton
                              name="REMOVE_CONTACT"
                              icon="trash"
                              pattern="secondary"
                              variation="minimal"
                              onClick={() => handleOnClickTrash(i, setValues)}
                            />
                          </Gutter>
                        </GridColumn>
                      </GridRow>
                      <GridRow gutterBottom="0">
                        <GridColumn width={12}>
                          {/* for displaying custom error */}
                          <FormGroup name="errorMissMatchEmailDomain" />
                        </GridColumn>
                      </GridRow>
                    </React.Fragment>
                  ))}

                <GridRow gutterBottom="12px">
                  <GridColumn width={12}>
                    {/* for displaying custom error */}
                    <FormGroup name="errorSelectAtLeastOne" />
                  </GridColumn>
                </GridRow>

                <GridRow>
                  <GridColumn>
                    <Button
                      pattern="secondary"
                      variation="minimal"
                      icon="plus"
                      iconPosition="right"
                      name="ADD_NEW_BUTTON"
                      onClick={() => handleOnClickOneMore(setValues)}
                    >
                      Add Attendee
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalSection divider>
              <Checkbox
                option={values.isZoomNotPreferred}
                name="isZoomNotPreferred"
                displayValue="My company can't use Zoom. Let's discuss other options."
              />
            </ModalSection>
            <ModalFooter stackableOn="mobile">
              {isInterviewTimeUnavailable ? (
                <Button name="BACK" variation="outline" onClick={onBackHandler}>
                  Back
                </Button>
              ) : (
                <>
                  <Button
                    name="CANCEL"
                    variation="outline"
                    onClick={() => onClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button name="CONFIRM" onClick={fireSubmit}>
                    {scheduledEvent ? 'Schedule' : 'Request'} Interview
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
