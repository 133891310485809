import moment from 'moment';
import {
  CalendarEventType,
  CalendarComponentEvent,
  CalendarPermissions,
} from '@axiom/validation';

import { EventStates } from './calendar-events-const';

type CleanEventType = Omit<CalendarEventType, 'state'>;

export type CleanEvent = Omit<CalendarEventType, 'state'>;
type CalendarOnChangeType = {
  newEvents: CleanEvent[];
  modifiedEvents: CleanEvent[];
  deleteIds: string[];
  staticEvents: CleanEvent[];
};

export const backgroundNamePrefix = 'BACKGROUND_';

export const CalendarEventsUtil = {
  cleanForegroundEventsForState: (
    items: CalendarEventType[],
    permissions: CalendarPermissions
  ): CalendarComponentEvent[] => {
    const blockingGranularity =
      CalendarEventsUtil.dateCheckGranularity(permissions);

    const stateSet = new Set([EventStates.NEW, EventStates.MODIFIED]);
    return CalendarEventsUtil.translateFromStartTimeEndTimeProps(items)
      .filter(event => {
        return (
          stateSet.has(event.state) ||
          moment(event.end).isAfter(moment(), blockingGranularity)
        );
      })
      .map(e => {
        if (
          e.state === EventStates.SAVED &&
          moment(e.start).isBefore(moment(), blockingGranularity)
        ) {
          e.start = moment()
            .set({ minutes: 0, seconds: 0, milliseconds: 0 })
            .add(1, 'hour')
            .toDate();
          e.state = EventStates.MODIFIED;
        }

        e.name = CalendarEventsUtil.setForegroundName(e.name);

        return e;
      });
  },
  cleanBackgroundEventsForState: (
    items: CalendarEventType[],
    permissions: CalendarPermissions,
    bgEventIdsSet: Set<CalendarEventType['id']>
  ) => {
    bgEventIdsSet.clear();
    const blockingGranularity =
      CalendarEventsUtil.dateCheckGranularity(permissions);

    const stateSet = new Set([EventStates.NEW, EventStates.MODIFIED]);
    return CalendarEventsUtil.translateFromStartTimeEndTimeProps(
      items
        .filter(event => {
          return (
            stateSet.has(event.state) ||
            moment(event.endTime).isAfter(moment(), blockingGranularity)
          );
        })
        .map((bgE: CalendarEventType) => {
          bgEventIdsSet.add(bgE.id);

          return {
            ...bgE,
            name: CalendarEventsUtil.setBackgroundName(bgE.name),
          };
        })
    );
  },
  canAddEvent: (permissions: CalendarPermissions, startDate: string) => {
    return (
      (permissions.eventBoundary.canCreate ||
        permissions.eventBoundary.canDragToCreate ||
        permissions.staticEvent.canCreate ||
        permissions.staticEvent.canDragToCreate) &&
      moment(startDate).isAfter(
        moment(),
        CalendarEventsUtil.dateCheckGranularity(permissions)
      )
    );
  },
  dateCheckGranularity: (permissions: CalendarPermissions) => {
    return permissions.allEvents.canSameDay ? 'hour' : 'day';
  },
  isInThePast: (
    date: CalendarEventType['endTime'] | Date,
    permissions: CalendarPermissions
  ) => {
    return moment(date).isSameOrBefore(
      moment(),
      CalendarEventsUtil.dateCheckGranularity(permissions)
    );
  },
  translateFromStartTimeEndTimeProps: (
    events: CalendarEventType[]
  ): CalendarComponentEvent[] => {
    return events.reduce(
      (
        data: CalendarComponentEvent[],
        event: CalendarEventType
      ): CalendarComponentEvent[] => {
        const { endTime, startTime, ...rest } = event;
        const item: CalendarComponentEvent = {
          ...rest,
          end: moment(endTime).toDate(),
          start: moment(startTime).toDate(),
        };

        data.push(item);
        return data;
      },
      []
    );
  },
  translateToStartTimeEndTimeProps: (
    events: CalendarComponentEvent[]
  ): CalendarEventType[] => {
    return events.reduce(
      (
        data: CalendarEventType[],
        event: CalendarComponentEvent
      ): CalendarEventType[] => {
        const { end, start, ...rest } = event;
        const item: CalendarEventType = {
          ...rest,
          endTime: moment(end).toISOString(),
          startTime: moment(start).toISOString(),
        };

        data.push(item);
        return data;
      },
      []
    );
  },
  setForegroundName: (eventName?: string | null) => {
    if (!eventName) return `EVENT`;

    if (eventName.includes(backgroundNamePrefix)) {
      return eventName.slice(backgroundNamePrefix.length);
    }

    return eventName;
  },
  setBackgroundName: (eventName?: string | null) => {
    if (!eventName) return `${backgroundNamePrefix}EVENT`;

    if (eventName.includes(backgroundNamePrefix)) return eventName;

    return `${backgroundNamePrefix}${eventName}`;
  },
  sortEvents: (events: CalendarComponentEvent[]) => {
    if (events.length >= 2) {
      events.sort((a, b) => {
        const aStart = moment(a.start);
        const bStart = moment(b.start);
        const aEnd = moment(a.end);
        const bEnd = moment(b.end);

        if (aStart.isBefore(bStart) || aEnd.isBefore(bEnd)) return -1;

        return 1;
      });
    }
    return events;
  },
  getDeletedEventIds: (
    permissions: CalendarPermissions,
    events: CalendarEventType[],
    formEvents?: CalendarEventType[]
  ) => {
    if (!formEvents) {
      return [];
    }

    const formIds = new Set(formEvents?.map(e => e.id) || []);

    return (
      events
        ?.filter(event => {
          return !CalendarEventsUtil.isInThePast(event.endTime, permissions);
        })
        ?.map(e => e.id) || []
    ).reduce(
      (crnt, id) => {
        if (!formIds.has(id) && id) {
          crnt.push(id);
        }
        return crnt;
      },
      [] as CalendarOnChangeType['deleteIds']
    );
  },
  cleanAndOrganiseEvents: (
    permissions: CalendarPermissions,
    initData: CalendarEventType[],
    changedEvents?: CalendarEventType[]
  ): CalendarOnChangeType => {
    const baseDataset: CalendarOnChangeType = {
      newEvents: [],
      modifiedEvents: [],
      deleteIds: [],
      staticEvents: [],
    };

    if (!changedEvents) {
      return baseDataset;
    }

    const cleanEvents = changedEvents?.length
      ? changedEvents.reduce(
          (data, event) => {
            const tmpEvent = {
              ...event,
              id: event.state === EventStates.NEW ? null : event.id,
            };

            const cleanEvent = (toCleanEvent: CalendarEventType) => {
              return Object.entries({
                ...toCleanEvent,
                state: null,
              }).reduce((crnt, [key, value]) => {
                if (value !== null) Object.assign(crnt, { [key]: value });

                return crnt;
              }, {} as CleanEventType);
            };

            if (tmpEvent.state === EventStates.NEW) {
              data[tmpEvent.busy ? 'staticEvents' : 'newEvents'].push(
                cleanEvent(tmpEvent)
              );
            } else if (tmpEvent.state === EventStates.MODIFIED) {
              data.modifiedEvents.push(cleanEvent(tmpEvent));
            }

            return data;
          },
          { ...baseDataset } as CalendarOnChangeType
        )
      : baseDataset;

    cleanEvents.deleteIds = CalendarEventsUtil.getDeletedEventIds(
      permissions,
      initData,
      changedEvents
    );

    return cleanEvents;
  },
  setEventError: (
    {
      foreground,
      background,
    }: {
      foreground: CalendarComponentEvent[];
      background?: CalendarComponentEvent[];
    },
    permissions: CalendarPermissions
  ) => {
    const { fgFreetime, fgInterviews } = (foreground ?? []).reduce(
      (crnt, event) => {
        if (event.busy) {
          crnt.fgInterviews.push(event);
        } else {
          crnt.fgFreetime.push(event);
        }
        return crnt;
      },
      { fgFreetime: [], fgInterviews: [] } as {
        fgFreetime: Array<CalendarComponentEvent>;
        fgInterviews: Array<CalendarComponentEvent>;
      }
    );
    const { bgFreetime } = (background ?? []).reduce(
      (crnt, event) => {
        if (event.busy) {
          crnt.bgInterviews.push(event);
        } else {
          crnt.bgFreetime.push(event);
        }
        return crnt;
      },
      { bgFreetime: [], bgInterviews: [] } as {
        bgFreetime: Array<CalendarComponentEvent>;
        bgInterviews: Array<CalendarComponentEvent>;
      }
    );

    const stateSet = new Set([EventStates.NEW, EventStates.MODIFIED]);
    const checkStaticEvent = (event: CalendarComponentEvent) => {
      return (
        stateSet.has(event.state) &&
        ![...fgFreetime, ...bgFreetime].some(fte => {
          // is staticEvent in valid time slot
          return (
            moment(event.start).isBetween(
              fte.start,
              fte.end,
              undefined,
              '[]'
            ) &&
            moment(event.end).isBetween(fte.start, fte.end, undefined, '[]')
          );
        })
      );
    };

    fgInterviews.forEach((event: CalendarComponentEvent) => {
      if (
        (!permissions.staticEvent.canCreateFreely && checkStaticEvent(event)) ||
        CalendarEventsUtil.isInThePast(event.end, permissions)
      ) {
        event.error = 'Unavailable';
      }
    });

    fgFreetime.forEach((event: CalendarComponentEvent) => {
      if (
        moment(event.end).isSameOrBefore(
          moment(),
          CalendarEventsUtil.dateCheckGranularity(permissions)
        ) ||
        moment(event.start).isSameOrBefore(
          moment(),
          CalendarEventsUtil.dateCheckGranularity(permissions)
        )
      ) {
        event.error = 'Unavailable';
      }
    });

    return [...fgFreetime, ...fgInterviews];
  },
};
