const EnvVars = {
  ALT_API_URL: 'ALT_API_URL',
  API_URL: 'API_URL',
  AXIOM_SOLUTIONS_URL: 'AXIOM_SOLUTIONS_URL',
  CLEARBIT_AUTOCOMPLETE_API_URL: 'CLEARBIT_AUTOCOMPLETE_API_URL',
  CLEARBIT_LOGO_URL: 'CLEARBIT_LOGO_URL',
  CLIENT_BENCH_URL: 'CLIENT_BENCH_URL',
  CLIENT_URL: 'CLIENT_URL',
  COOKIE_DOMAIN: 'COOKIE_DOMAIN',
  ENABLE_CALENDAR_EVENTS_TODAY: 'ENABLE_CALENDAR_EVENTS_TODAY',
  ENABLE_LOGGING: 'ENABLE_LOGGING',
  END_OF_OPPORTUNITY_EMAIL: 'END_OF_OPPORTUNITY_EMAIL',
  ENVIRONMENT_NAME: 'ENVIRONMENT_NAME',
  ENVOY_URL: 'ENVOY_URL',
  GOOGLE_MAPS_API_URL: 'GOOGLE_MAPS_API_URL',
  HUBSPOT_ACCESS_OUR_NETWORK_INQUIRE_WITH_AXIOM:
    'HUBSPOT_ACCESS_OUR_NETWORK_INQUIRE_WITH_AXIOM',
  HUBSPOT_NEW_ENGAGEMENT_FORM: 'HUBSPOT_NEW_ENGAGEMENT_FORM',
  HUBSPOT_EXTERNAL_SEARCH_REACH_OUT: 'HUBSPOT_EXTERNAL_SEARCH_REACH_OUT',
  HUBSPOT_DIGITAL_BENCH_ASK_US_HOW: 'HUBSPOT_DIGITAL_BENCH_ASK_US_HOW',
  HUBSPOT_DIGITAL_BENCH_REQUEST_RATE: 'HUBSPOT_DIGITAL_BENCH_REQUEST_RATE',
  HUBSPOT_DIGITAL_BENCH_REQUEST_UPDATE: 'HUBSPOT_DIGITAL_BENCH_REQUEST_UPDATE',
  CLIENT_ENGAGEMENTS_URL: 'CLIENT_ENGAGEMENTS_URL',
  CLIENT_OUR_NETWORK_DETAILS_URL: 'CLIENT_OUR_NETWORK_DETAILS_URL',
  HUBSPOT_TALENT_FINDER: 'HUBSPOT_TALENT_FINDER',
  LOGGLY_CLIENT_ID: 'LOGGLY_CLIENT_ID',
  LOGIN_URL: 'LOGIN_URL',
  PENDO_APP_ID: 'PENDO_APP_ID',
  S3_PUBLIC_FILE_BUCKET: 'S3_PUBLIC_FILE_BUCKET',
  S3_PUBLIC_BASE_URL: 'S3_PUBLIC_BASE_URL',
  ENABLE_SUGGESTIONS: 'ENABLE_SUGGESTIONS',
};
// decode query string special characters because something (yaml parser?) encodes them
const decodeEnvUrl = url => {
  if (!url) return '';
  return url.replaceAll(/(&#x3D;)/g, '=').replaceAll(/(&amp;)/g, '&');
};
let envs = {};
const EnvUtil = {
  initialize(env) {
    envs = this.pluckAllowedFrom(env);
  },

  getAllowedFrontendProcessVars() {
    return Object.values(EnvVars);
  },

  pluckAllowedFrom(obj) {
    const allowedProps = {};
    this.getAllowedFrontendProcessVars().forEach(propName => {
      allowedProps[propName] = obj[propName] || null;
    });

    return allowedProps;
  },

  get cookieDomain() {
    return envs[EnvVars.COOKIE_DOMAIN];
  },

  get apiUrl() {
    return envs[EnvVars.API_URL];
  },

  get clientApiBase() {
    return envs[EnvVars.CLIENT_API_BASE] || '/api';
  },

  get loginUrl() {
    return envs[EnvVars.LOGIN_URL];
  },

  get pendoAppId() {
    return envs[EnvVars.PENDO_APP_ID];
  },

  get logglyClientId() {
    return envs[EnvVars.LOGGLY_CLIENT_ID];
  },

  get environmentName() {
    return envs[EnvVars.ENVIRONMENT_NAME];
  },

  get enableLogging() {
    return (
      envs[EnvVars.ENABLE_LOGGING] === true ||
      envs[EnvVars.ENABLE_LOGGING] === 'true'
    );
  },

  get publicS3Bucket() {
    return envs[EnvVars.S3_PUBLIC_FILE_BUCKET];
  },

  get publicBaseUrlS3() {
    return envs[EnvVars.S3_PUBLIC_BASE_URL];
  },

  get googleMapsApiUrl() {
    return decodeEnvUrl(envs[EnvVars.GOOGLE_MAPS_API_URL]);
  },

  get clearbitAutocompleteApiUrl() {
    return decodeEnvUrl(envs[EnvVars.CLEARBIT_AUTOCOMPLETE_API_URL]);
  },

  get clearbitLogoUrl() {
    return envs[EnvVars.CLEARBIT_LOGO_URL];
  },

  get apiAltUrl() {
    return envs[EnvVars.ALT_API_URL];
  },

  get clientOurNetworkDetailsUrl() {
    return envs[EnvVars.CLIENT_OUR_NETWORK_DETAILS_URL];
  },

  get clientBenchUrl() {
    return envs[EnvVars.CLIENT_BENCH_URL];
  },

  get clientEngagementsUrl() {
    return envs[EnvVars.CLIENT_ENGAGEMENTS_URL];
  },

  get envoyUrl() {
    return envs[EnvVars.ENVOY_URL];
  },

  get hubspotTalentFinder() {
    return envs[EnvVars.HUBSPOT_TALENT_FINDER];
  },

  get axiomSolutionsUrl() {
    return envs[EnvVars.AXIOM_SOLUTIONS_URL];
  },

  get clientUrl() {
    return envs[EnvVars.CLIENT_URL];
  },

  get hubspotAccessOurNetworkInquireWithAxiom() {
    return envs[EnvVars.HUBSPOT_ACCESS_OUR_NETWORK_INQUIRE_WITH_AXIOM];
  },

  get hubspotNewEngagementForm() {
    return envs[EnvVars.HUBSPOT_NEW_ENGAGEMENT_FORM];
  },

  get hubspotDigitalBenchRequestUpdate() {
    return envs[EnvVars.HUBSPOT_DIGITAL_BENCH_REQUEST_UPDATE];
  },

  get hubspotDigitalBenchRequestRate() {
    return envs[EnvVars.HUBSPOT_DIGITAL_BENCH_REQUEST_RATE];
  },

  get hubspotDigitalBenchAskUsHow() {
    return envs[EnvVars.HUBSPOT_DIGITAL_BENCH_ASK_US_HOW];
  },

  get hubspotExternalSearchReachOut() {
    return envs[EnvVars.HUBSPOT_EXTERNAL_SEARCH_REACH_OUT];
  },

  get enableSuggestions() {
    return (
      envs[EnvVars.ENABLE_SUGGESTIONS] === true ||
      envs[EnvVars.ENABLE_SUGGESTIONS] === 'true'
    );
  },

  get enableCalendarEventsToday() {
    return (
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === true ||
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === 'true'
    ).toString();
  },
};

try {
  EnvUtil.initialize(window?.ENV || {});
  // eslint-disable-next-line no-empty
} catch {}
// eslint-disable-next-line unicorn/prefer-module
module.exports = {
  EnvUtil,
};
