import { z } from 'zod';

export const TalentInterviewPromptModalSchema = z.object({
  TIMEZONE_DROPDOWN: z.string().uuid(),
  AVAILABILITY_NOTES: z.string().max(500),
});

export type TalentInterviewPromptModalType = z.infer<
  typeof TalentInterviewPromptModalSchema
>;
