import {
  AccordionsItem,
  AccordionsItemHeader,
  Accordions,
  AccordionsItemSection,
  Badge,
  Button,
  Card,
  CardHeader,
  CardSection,
  Checkbox,
  CondensedMedium,
  DateInput,
  Dropdown,
  Flashy,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Icon,
  Input,
  Layout,
  LayoutItem,
  Location,
  Paragraph,
  Radio,
  SmallHeader,
  TaxonomyUtil,
  useApi,
  Well,
  useBreakpoint,
  Textarea,
  LargeHeader,
  Stretched,
  DateUtil,
  ToastUtil,
  ParagraphHeader,
  CondensedHeader,
  FormSchemaUtil,
  FluidButtonLayout,
} from '@axiom/ui';
import { PositionsConst, StateCodesConst, states } from '@axiom/const';
import {
  levelOfExperienceValues,
  licensedLawyerValues,
  occupationTypeValues,
  positionReservedTypeValues,
  worksiteValues,
  billingUnitValues,
  selfServiceAdditionalDetailsForTalentMaxLength,
  ProjectNeedsSchema,
  ProjectNeedsWithLegalSkillsSchema,
  ProjectNeedsWithLegalSkills,
  ProjectNeedsDefaultRemoteLocation,
  AfcSubmission,
  Account,
} from '@axiom/validation';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { EngagementsUtil } from '@axiom/utils';
import { Location as GoogleLocation } from '@axiom/types/src/schema-types';
import { RAW_DATE_INPUT_DIRECTIONS } from '@axiom/ui/src/components/form/DateInput/RawDateInput';

import { InstantMatchesApi } from '../../api/instant-matches-api';
import { LanguagesApi } from '../../api/languages-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { OpportunityIntakeApi } from '../../api/opportunity-intake-api';
import { AccountIntakeApi } from '../../api/account-intake-api';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';

const sortDropDownOptions = (
  a: { label: string; value: string },
  b: { label: string; value: string }
) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

const getMatchesUrl = (
  accountId: Account['id'],
  submissionId: AfcSubmission['id']
) => {
  return `/my-axiom/account/${accountId}/submission/${submissionId}/matches`;
};

export const DirectEngagementsForm = () => {
  const { accountId } = useParams();
  let { submissionId } = useParams();
  const isEditForm = !!submissionId;

  const navigate = useNavigate();
  const [companyLocationInputValue, setCompanyLocationInputValue] =
    useState<GoogleLocation>();
  const [{ data: taxonomy }, { data: languages }, { data: intakeData }] =
    useApi(
      TaxonomyApi.readTaxonomy(),
      LanguagesApi.readLanguages(),
      AccountIntakeApi.getAccountIntake(
        accountId,
        submissionId ? { submissionId } : undefined
      )
    );

  const isOptionalDataPrefilled = !!(
    intakeData?.otherFocusAreas?.length ||
    intakeData?.legalTechSkills?.length ||
    intakeData?.generalSkills?.length ||
    intakeData?.languageId ||
    intakeData?.additionalDetailsForTalent
  );

  const { isMobile } = useBreakpoint();

  const {
    focusAreas,
    generalSkills,
    legalSkills,
    legalTechSkills,
    practiceAreas,
    skillGroups,
  } = taxonomy;
  const focusAreaOptions = TaxonomyUtil.getFocusAreaOptions(
    focusAreas,
    practiceAreas
  );
  const industryOptions = TaxonomyUtil.getFilteredIntakeFormIndustryOptions(
    taxonomy.industries
  );

  const [filteredSkillGroups, setFilteredSkillGroups] = useState(
    TaxonomyUtil.getFocusAreaSkillGroups(
      intakeData?.primaryFocusArea ?? null,
      skillGroups
    )
  );

  const [
    additionalDetailsForTalentUpdated,
    setAdditionalDetailsForTalentUpdated,
  ] = useState(false);

  const addPrefilledLegalSkills = () => {
    if (intakeData?.legalSkills) {
      return filteredSkillGroups.map(({ key }) => {
        const legalSkillOptions = TaxonomyUtil.getLegalSkillOptions(
          legalSkills,
          key
        );

        return legalSkillOptions
          .filter(({ value }) => intakeData.legalSkills.includes(value))
          .map(({ value }) => value);
      });
    }
    return [];
  };

  const initialValues: ProjectNeedsWithLegalSkills = useMemo(
    () =>
      intakeData
        ? {
            ...intakeData,
            companyLocation:
              intakeData.companyLocation ?? ProjectNeedsDefaultRemoteLocation,
            levelOfExperience: intakeData.levelOfExperience,
            licensedLawyerState: StateCodesConst.StateCodeMap.get(
              intakeData.licensedLawyerState
            ),
            legalSkillsAnswers: addPrefilledLegalSkills(),
          }
        : {},
    [intakeData]
  );

  // Remove the dynamic fields when resetting or submitting the form
  const removeDynamicFields = (data: object) => {
    return {
      ...Object.fromEntries(
        Object.entries({ ...data }).filter(([key]) =>
          Object.keys(ProjectNeedsSchema.strict().shape).includes(key)
        )
      ),
    };
  };

  const handleSubmit = async (formData: ProjectNeedsWithLegalSkills) => {
    let addressStreet: string;
    let addressCity;
    let addressState;
    let addressZip;

    if (
      formData.companyLocation.locationName ===
      ProjectNeedsDefaultRemoteLocation.locationName
    ) {
      formData.companyLocation = undefined;
    } else {
      addressStreet = '';
      formData.companyLocation?.locationAddressComponents.addresses.forEach(
        line => {
          if (line.types.includes('street_number')) {
            addressStreet += `${line.long_name} `;
          }
          if (line.types.includes('route')) {
            addressStreet += `${line.long_name}`;
          }
          if (line.types.includes('sublocality_level_1')) {
            addressCity = line.long_name;
          }
          if (line.types.includes('locality')) {
            addressCity = line.long_name;
          }
          if (line.types.includes('administrative_area_level_1')) {
            addressState = line.long_name;
          }
          if (line.types.includes('postal_code')) {
            addressZip = line.long_name;
          }
        }
      );
    }

    const selectedLegalSkills = formData.legalSkillsAnswers
      .flat()
      .filter(Boolean);
    const body = {
      ...removeDynamicFields(formData),
      accountId,
      addressCity,
      addressState,
      addressStreet,
      addressZip,
      legalSkills:
        selectedLegalSkills.length > 0 ? selectedLegalSkills : undefined,
    };

    if (isEditForm) {
      await OpportunityIntakeApi.updateOpportunityIntake({
        ...body,
        opportunityId: intakeData.opportunityId,
      });
    } else {
      const { data } = await OpportunityIntakeApi.createOpportunityIntake(body);
      submissionId = data.submissionId;
    }

    await Promise.all([
      AccountIntakeApi.refreshAccountIntake(accountId),
      InstantMatchesApi.createInstantMatches(submissionId),
    ]);
    await InstantMatchesApi.refreshInstantMatches(submissionId);
    await AccountSubmissionsApi.refreshAccountSubmissionDetails(
      accountId,
      submissionId
    );

    navigate(getMatchesUrl(accountId, submissionId));
  };

  return (
    <Grid centered>
      <GridColumn largeScreenWidth={8} tabletWidth={12}>
        {isEditForm && (
          <Gutter bottom="16px">
            <Button
              to={getMatchesUrl(accountId, submissionId)}
              name="BACK_TO_MATCHES"
              pattern="primary"
              variation="minimal"
              icon="arrow-left"
              iconPosition="left"
            >
              MATCHES
            </Button>
          </Gutter>
        )}
        <LargeHeader name="direct-engagements-header">
          {isEditForm
            ? 'Edit your legal needs to update matches'
            : 'Describe your legal needs to get instant matches.'}
        </LargeHeader>
        <Form
          name="direct-engagements-form"
          schema={ProjectNeedsWithLegalSkillsSchema}
          initialValues={initialValues}
          onSubmit={async formData => {
            await handleSubmit({ ...initialValues, ...formData });
          }}
        >
          {({ values, setValues, isValid, submitCount, fireSubmit }) => {
            const engagementLengthDays =
              EngagementsUtil.calculateEngagementLengthInDays(
                values.startDate,
                values.endDate
              );
            const weeks =
              EngagementsUtil.calculateEngagementWeeks(engagementLengthDays);
            return (
              <>
                <Gutter bottom="24px" />
                <Card>
                  <CardHeader>
                    <SmallHeader>Engagement details</SmallHeader>
                  </CardHeader>
                  <CardSection>
                    <Grid>
                      <GridRow>
                        <Well background="blue">
                          <Paragraph>
                            It's okay if you don't know every detail of your
                            engagement right now. Provide a best guess and we'll
                            finalize everything later.
                          </Paragraph>
                        </Well>
                      </GridRow>

                      <GridColumn
                        largeScreenWidth={3}
                        smallScreenWidth={12}
                        tabletWidth={3}
                      >
                        <DateInput
                          name="startDate"
                          label="Expected start date"
                          direction={RAW_DATE_INPUT_DIRECTIONS.DOWN}
                        />
                      </GridColumn>
                      <GridColumn
                        largeScreenWidth={3}
                        smallScreenWidth={12}
                        tabletWidth={3}
                      >
                        <Gutter top="24px" only="mobile" />
                        <DateInput
                          name="endDate"
                          label="Expected end date"
                          direction={RAW_DATE_INPUT_DIRECTIONS.DOWN}
                        />
                      </GridColumn>
                      <GridColumn
                        largeScreenWidth={4}
                        smallScreenWidth={12}
                        tabletWidth={5}
                      >
                        <LayoutItem fluid>
                          <Gutter top="24px" only="mobile" />
                          <Dropdown
                            name="billingUnitsPerWeek"
                            label="Expected hours per week (5 - 40)"
                            options={billingUnitValues.map(value => ({
                              label: value,
                              value,
                            }))}
                            displayKey="label"
                            valueKey="label"
                          />
                        </LayoutItem>
                      </GridColumn>

                      <GridColumn width={12}>
                        <Gutter top="16px" />
                        <Well>
                          <Paragraph name="engagment-length-paragraph">
                            Engagement length:{' '}
                            {values.startDate &&
                            values.endDate &&
                            DateUtil.isDateAfterDate(
                              values.endDate,
                              moment(values.startDate).toDate()
                            ) &&
                            values.billingUnitsPerWeek &&
                            values.billingUnitsPerWeek > 0 &&
                            engagementLengthDays >= 0 ? (
                              <Flashy bold>
                                {values.billingUnitsPerWeek} hour
                                {values.billingUnitsPerWeek > 1 && 's'} per week
                                for {weeks} week
                                {weeks > 1 && 's'}
                              </Flashy>
                            ) : (
                              <Flashy bold>
                                Enter dates and hours to see length
                              </Flashy>
                            )}
                          </Paragraph>
                        </Well>

                        {EngagementsUtil.showEngagementLengthWarning(
                          values.startDate,
                          values.endDate,
                          values.billingUnitsPerWeek,
                          weeks
                        ) && (
                          <>
                            <Gutter top="8px" />
                            <Layout
                              direction="horizontal"
                              position="middle"
                              name="engagement-length-warning"
                            >
                              <span>
                                <Flashy color="controlAlert">
                                  <Icon name="alert-filled" />
                                </Flashy>
                              </span>
                              <Gutter right="8px" />
                              <span>
                                <Flashy color="controlAlert">
                                  <CondensedMedium>
                                    {engagementLengthDays < 1
                                      ? 'Expected end date must be after the start date'
                                      : 'Total engagement length must be at least 20 hours'}
                                  </CondensedMedium>
                                </Flashy>
                              </span>
                            </Layout>
                          </>
                        )}
                      </GridColumn>

                      <Gutter top="24px" />
                      <GridRow>
                        <GridColumn
                          largeScreenWidth={7}
                          tabletWidth={6}
                          mobileWidth={12}
                        >
                          <Dropdown
                            name="licensedLawyer"
                            label="Lawyer who will be the main contact for this Axiom talent"
                            options={Object.keys(licensedLawyerValues).map(
                              (key, index) => ({
                                label:
                                  Object.values(licensedLawyerValues)[index],
                                value: key,
                              })
                            )}
                            displayKey="label"
                            valueKey="value"
                          />
                        </GridColumn>
                        <GridColumn
                          largeScreenWidth={3}
                          tabletWidth={6}
                          mobileWidth={12}
                        >
                          <Gutter top="24px" only="mobile" />
                          <Dropdown
                            name="licensedLawyerState"
                            label="Contact's location"
                            options={Object.keys(states).map(key => ({
                              label: key,
                              value: key,
                            }))}
                            displayKey="label"
                            valueKey="value"
                          />
                        </GridColumn>
                      </GridRow>

                      <LayoutItem fluid>
                        <Gutter top="8px" />
                        <Well>
                          <Grid>
                            <GridColumn
                              smallScreenWidth={12}
                              tabletWidth={6}
                              largeScreenWidth={6}
                            >
                              <Dropdown
                                name="worksite"
                                label="Do you need the work done in your office?"
                                options={Object.keys(worksiteValues).map(
                                  (key, index) => ({
                                    label: Object.values(worksiteValues)[index],
                                    value: key,
                                  })
                                )}
                                displayKey="label"
                                valueKey="value"
                                onChange={value => {
                                  switch (value) {
                                    case PositionsConst.Worksites.OnsiteRemote:
                                    case PositionsConst.Worksites.Onsite: {
                                      if (
                                        values.companyLocation?.locationName ===
                                        ProjectNeedsDefaultRemoteLocation.locationName
                                      ) {
                                        setValues({
                                          ...values,
                                          companyLocation:
                                            companyLocationInputValue,
                                        });
                                      } else {
                                        setCompanyLocationInputValue(
                                          values.companyLocation
                                        );
                                      }
                                      break;
                                    }
                                    default: {
                                      setValues({
                                        ...values,
                                        companyLocation:
                                          ProjectNeedsDefaultRemoteLocation,
                                      });
                                    }
                                  }
                                }}
                              />
                            </GridColumn>

                            <GridColumn
                              smallScreenWidth={12}
                              tabletWidth={6}
                              largeScreenWidth={6}
                            >
                              {values.worksite !==
                                PositionsConst.Worksites.Remote &&
                                !!values.worksite && (
                                  <LayoutItem>
                                    <Gutter top="24px" only="mobile" />
                                    <Location
                                      name="companyLocation"
                                      label="Office street address (US)"
                                      onChange={value => {
                                        setCompanyLocationInputValue(value);
                                      }}
                                    />
                                  </LayoutItem>
                                )}
                            </GridColumn>
                          </Grid>
                        </Well>
                      </LayoutItem>
                    </Grid>
                  </CardSection>
                </Card>
                <Gutter top="24px" />
                <Card>
                  <CardHeader>
                    <SmallHeader>Talent experience</SmallHeader>
                  </CardHeader>
                  <CardSection>
                    <Grid>
                      <GridRow>
                        <GridColumn
                          largeScreenWidth={3}
                          smallScreenWidth={12}
                          tabletWidth={4}
                        >
                          <Dropdown
                            name="occupationType"
                            label="Legal role"
                            options={Object.keys(occupationTypeValues).map(
                              (key, index) => ({
                                label:
                                  Object.values(occupationTypeValues)[index],
                                value: key,
                              })
                            )}
                            displayKey="label"
                            valueKey="value"
                          />
                        </GridColumn>
                        <GridColumn
                          largeScreenWidth={5}
                          smallScreenWidth={12}
                          tabletWidth={6}
                        >
                          <Gutter top="24px" only="mobile" />
                          <Dropdown
                            name="levelOfExperience"
                            label="Minimum years of experience"
                            options={Object.keys(levelOfExperienceValues).map(
                              (key, index) => ({
                                label: Object.values(levelOfExperienceValues)[
                                  index
                                ],
                                value: key,
                              })
                            )}
                            displayKey="label"
                            valueKey="value"
                          />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn width={12}>
                          <Well>
                            <Dropdown
                              name="primaryFocusArea"
                              label="Subject matter expertise"
                              // @ts-expect-error Needs new type
                              onChange={(v: string) => {
                                // Remove the values for the question(s) if focus area changes
                                const legalSkillsAnswers: Array<Array<string>> =
                                  [];

                                setValues({
                                  ...values,
                                  legalSkillsAnswers,
                                  primaryFocusArea: v,
                                  otherFocusAreas: values.otherFocusAreas
                                    ? values.otherFocusAreas.filter(
                                        ofa => ofa !== v
                                      )
                                    : undefined,
                                });
                                setFilteredSkillGroups(
                                  TaxonomyUtil.getFocusAreaSkillGroups(
                                    v,
                                    skillGroups
                                  )
                                );
                              }}
                              options={focusAreaOptions.sort((a, b) =>
                                sortDropDownOptions(a, b)
                              )}
                              displayKey="label"
                              valueKey="value"
                            />
                            {values.primaryFocusArea &&
                              filteredSkillGroups.map(
                                ({ key, clientQuestion }, index) => {
                                  const legalSkillOptions =
                                    TaxonomyUtil.getLegalSkillOptions(
                                      legalSkills,
                                      key
                                    );
                                  return (
                                    <Gutter vertical="8px" key={key}>
                                      <FormGroup
                                        name={`legalSkillsAnswers.${index}`}
                                        label={clientQuestion}
                                      >
                                        <Layout
                                          horizontalGutter="8px"
                                          verticalGutter="8px"
                                          stackableOn="mobile"
                                          wrap
                                        >
                                          {legalSkillOptions.map(
                                            ({ label, value }) => (
                                              <Checkbox
                                                mode={
                                                  isMobile ? 'checkbox' : 'pill'
                                                }
                                                name={`legalSkillsAnswers.${index}`}
                                                option={value}
                                                displayValue={label}
                                                key={value}
                                              />
                                            )
                                          )}
                                        </Layout>
                                      </FormGroup>
                                    </Gutter>
                                  );
                                }
                              )}
                          </Well>
                        </GridColumn>
                      </GridRow>
                      <GridColumn
                        largeScreenWidth={7}
                        smallScreenWidth={12}
                        tabletWidth={8}
                      >
                        <Dropdown
                          name="industry"
                          label="Industry experience if required for this work (optional)"
                          options={industryOptions}
                          displayKey="label"
                          valueKey="value"
                        />
                      </GridColumn>
                    </Grid>
                  </CardSection>
                </Card>
                <Gutter top="24px" />
                <Accordions shadow="default">
                  <AccordionsItem
                    name="Additional_info_accordion"
                    expanded={isOptionalDataPrefilled}
                  >
                    <AccordionsItemHeader>
                      <Grid verticalAlign="middle" stackable>
                        <GridColumn largeScreenWidth={8}>
                          <SmallHeader>
                            Other talent qualifications (optional)
                          </SmallHeader>
                        </GridColumn>
                        {!isValid &&
                          submitCount > 0 &&
                          values.additionalDetailsForTalent?.length >
                            selfServiceAdditionalDetailsForTalentMaxLength && (
                            <GridColumn largeScreenWidth={4}>
                              <Gutter top="8px" />
                              <Layout
                                direction="horizontal"
                                position={isMobile ? 'middle' : 'right middle'}
                                name="other_talent_warning"
                              >
                                <span>
                                  <Flashy color="controlAlert">
                                    <Icon name="alert-filled" />
                                  </Flashy>
                                </span>
                                <Gutter right="8px" />
                                <CondensedMedium>
                                  <Flashy color="controlAlert">
                                    Please resolve any errors
                                  </Flashy>
                                </CondensedMedium>

                                <Gutter right="8px" />
                              </Layout>
                            </GridColumn>
                          )}
                      </Grid>
                    </AccordionsItemHeader>
                    <AccordionsItemSection>
                      <Grid>
                        <GridRow gutterTop="24px">
                          <GridColumn>
                            <Dropdown
                              name="otherFocusAreas"
                              label="Additional subject matter expertise"
                              options={focusAreaOptions
                                .filter(
                                  fa => fa.value !== values.primaryFocusArea
                                )
                                .sort((a, b) => sortDropDownOptions(a, b))}
                              displayKey="label"
                              valueKey="value"
                            />
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <GridColumn
                            largeScreenWidth={6}
                            smallScreenWidth={12}
                            tabletWidth={6}
                          >
                            <Dropdown
                              name="legalTechSkills"
                              label="Legal tech skills"
                              options={TaxonomyUtil.getLegalTechSkillOptions(
                                legalTechSkills
                              ).sort((a, b) => sortDropDownOptions(a, b))}
                              displayKey="label"
                              valueKey="value"
                            />
                          </GridColumn>
                          <GridColumn
                            largeScreenWidth={6}
                            smallScreenWidth={12}
                            tabletWidth={6}
                          >
                            <Gutter top="24px" only="mobile" />
                            <Dropdown
                              name="generalSkills"
                              label="Other relevant experience"
                              options={TaxonomyUtil.getGeneralSkillOptions(
                                generalSkills
                              ).sort((a, b) => sortDropDownOptions(a, b))}
                              displayKey="label"
                              valueKey="value"
                            />
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <GridColumn
                            largeScreenWidth={6}
                            smallScreenWidth={12}
                            tabletWidth={6}
                          >
                            <Dropdown
                              name="languageId"
                              label="Language"
                              options={languages.map(language => ({
                                label: language.name,
                                value: language.id,
                              }))}
                              displayKey="label"
                              valueKey="value"
                            />
                          </GridColumn>
                        </GridRow>
                        <GridRow>
                          <GridColumn>
                            <Textarea
                              name="additionalDetailsForTalent"
                              label="Anything we can share with the talent about what this work involves or why it's needed?"
                              onChange={() => {
                                if (!additionalDetailsForTalentUpdated) {
                                  setAdditionalDetailsForTalentUpdated(true);
                                }
                              }}
                            />
                          </GridColumn>
                        </GridRow>
                      </Grid>
                    </AccordionsItemSection>
                  </AccordionsItem>
                </Accordions>
                <Gutter top="24px" />
                <Card>
                  <CardHeader>
                    <SmallHeader>Pricing preferences</SmallHeader>
                  </CardHeader>
                  <CardSection>
                    <Grid>
                      <GridRow>
                        <Well background="blue">
                          <Paragraph>
                            Your budget and pricing preferences help us match
                            you with the right talent. You can estimate now and
                            update it later. You won't be billed until the
                            engagement begins.
                          </Paragraph>
                        </Well>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          largeScreenWidth={3}
                          smallScreenWidth={12}
                          tabletWidth={3}
                        >
                          <Input
                            name="minBudgetPerHour"
                            label="Min hourly rate ($)"
                          />
                        </GridColumn>
                        <GridColumn
                          largeScreenWidth={3}
                          smallScreenWidth={12}
                          tabletWidth={3}
                        >
                          <Gutter top="24px" only="mobile" />
                          <Input
                            name="maxBudgetPerHour"
                            label="Max hourly rate ($)"
                          />
                        </GridColumn>
                      </GridRow>
                    </Grid>
                    <FormGroup label="Choose your preferred engagement structure (you can change your mind before contracting)">
                      <Stretched>
                        <Grid>
                          <GridColumn
                            largeScreenWidth={6}
                            tabletWidth={6}
                            mobileWidth={12}
                            stretched
                          >
                            <Radio
                              mode="tile"
                              name="reservedType"
                              displayValue={
                                <>
                                  <Layout>
                                    <ParagraphHeader>
                                      Pay as you go
                                    </ParagraphHeader>
                                  </Layout>
                                  <Gutter top="16px" />

                                  <CondensedMedium>
                                    You're billed for the exact hours the talent
                                    works.
                                  </CondensedMedium>
                                </>
                              }
                              option={
                                positionReservedTypeValues['Not Reserved']
                              }
                            />
                            <Gutter bottom="16px" only="mobile" />
                          </GridColumn>
                          <GridColumn
                            largeScreenWidth={6}
                            tabletWidth={6}
                            mobileWidth={12}
                            stretched
                          >
                            <Radio
                              mode="tile"
                              name="reservedType"
                              displayValue={
                                <>
                                  <Layout>
                                    <ParagraphHeader>Reserved</ParagraphHeader>
                                  </Layout>
                                  <Gutter vertical="8px">
                                    <Badge background="orange">
                                      Up to 40% discount
                                    </Badge>
                                  </Gutter>
                                  <CondensedMedium>
                                    Pay for fixed hours each week plus any
                                    overage billed.{' '}
                                    <Flashy bold>
                                      All hours are discounted
                                    </Flashy>
                                  </CondensedMedium>
                                </>
                              }
                              option={positionReservedTypeValues.Reserved}
                            />
                          </GridColumn>
                        </Grid>
                      </Stretched>
                    </FormGroup>
                  </CardSection>
                </Card>
                <Gutter top="24px" />
                <Layout
                  position="center"
                  reversibleOn="mobile"
                  stackableOn="mobile"
                >
                  <FluidButtonLayout stackableOn="mobile">
                    {isEditForm && (
                      <Button
                        onClick={() => {
                          navigate(getMatchesUrl(accountId, submissionId));
                        }}
                        name="CANCEL"
                        variation="outline"
                      >
                        CANCEL
                      </Button>
                    )}
                    <Button
                      name="SUBMIT"
                      onClick={() => {
                        const { errors } = FormSchemaUtil.validate(
                          ProjectNeedsWithLegalSkillsSchema,
                          values
                        );
                        if (errors) {
                          ToastUtil.add({
                            name: 'invalid-engagement-form-toast',
                            type: 'error',
                            dismissible: 3,
                            children: (
                              <CondensedHeader>
                                Please provide all details and resolve any
                                errors before proceeding
                              </CondensedHeader>
                            ),
                          });
                        }
                        fireSubmit();
                      }}
                    >
                      {isEditForm ? 'SHOW NEW MATCHES' : 'SHOW MY MATCHES'}
                    </Button>
                  </FluidButtonLayout>
                </Layout>
              </>
            );
          }}
        </Form>
      </GridColumn>
    </Grid>
  );
};
