import { generateCandidateSlug } from '@axiom/utils';
import qs from 'qs';

import { EnvUtil } from './env-util';

export const getShareUrl = (candidate, practiceAreas) =>
  `/access-legal-talent/${generateCandidateSlug(candidate, practiceAreas)}`;

export const getSharePath = (candidate, practiceAreas) => {
  const {
    location: { pathname, search },
  } = window;
  const queryParams = {
    page: pathname ? pathname.split('/').at(-1) : undefined,
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };

  return `/access-legal-talent/${generateCandidateSlug(
    candidate,
    Object.values(practiceAreas)
  )}${qs.stringify(queryParams, { addQueryPrefix: true })}`;
};

export const generateClientProjectsUrl = accountId =>
  `/my-axiom/account/${accountId}`;

export const generateAHQOpportunityURL = opportunityId =>
  `${EnvUtil.envoyUrl}/opportunity-detail/${opportunityId}`;

export const LinkUtil = {
  getSharePath,
};
