import { useEffect, useRef } from 'react';
import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';
import { z } from 'zod';
import { SchemaLocation } from '@axiom/types';

declare global {
  interface Window {
    google: {
      maps: typeof google.maps;
    };
  }
}

type SchemaLocation = z.infer<typeof SchemaLocation>;

const DefaultLocation = {
  locationLatitude: 40.7127753,
  locationLongitude: -74.0059728,
};

export const useGoogleMaps = ({
  googleApiUrl,
  mapRef,
}: {
  googleApiUrl?: string | null;
  mapRef: React.MutableRefObject<unknown>;
}) => {
  const googleMapApiService = useRef<{
    autocomplete?: google.maps.places.AutocompleteService;
    map?: google.maps.Map;
    marker?: google.maps.Marker;
    places?: google.maps.places.PlacesService;
    requests: {
      clear: () => void;
      getPlaceSuggestions: (
        placeName: string
      ) => Promise<Array<SchemaLocation>>;
      getPlaceDetails: (placeLocationId: string) => Promise<SchemaLocation>;
    };
    sessionToken?: google.maps.places.AutocompleteSessionToken;
  }>({
    autocomplete: undefined,
    map: undefined,
    marker: undefined,
    places: undefined,
    requests: {
      clear: (): void => {
        const { marker } = googleMapApiService.current;
        marker?.setMap(null);
        marker?.setPosition(null);
      },
      getPlaceSuggestions: (
        placeName: string
      ): Promise<Array<SchemaLocation>> => {
        return new Promise<Array<SchemaLocation>>((resolve, reject) => {
          const { autocomplete, sessionToken } = googleMapApiService.current;
          autocomplete?.getPlacePredictions(
            {
              input: placeName,
              sessionToken,
            },
            (
              predictions: google.maps.places.AutocompletePrediction[] | null,
              status: string
            ) => {
              if (status === 'OK') {
                const formattedResults = predictions?.map(prediction => ({
                  locationName: prediction.description,
                  locationPlaceId: prediction.place_id,
                }));
                if (formattedResults !== undefined) {
                  resolve(formattedResults);
                }
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject([]);
              }
            }
          );
        });
      },
      getPlaceDetails: (placeLocationId: string): Promise<SchemaLocation> => {
        const { map, marker, places, sessionToken } =
          googleMapApiService.current;
        return new Promise<SchemaLocation>((resolve, reject) => {
          places?.getDetails(
            { placeId: placeLocationId, sessionToken },
            (
              placeDetails: google.maps.places.PlaceResult | null,
              status: google.maps.places.PlacesServiceStatus
            ) => {
              if (status === 'OK') {
                if (placeDetails?.geometry?.location !== undefined) {
                  map?.setCenter(placeDetails.geometry.location);
                  marker?.setMap(null);
                  marker?.setPosition(placeDetails.geometry.location);
                }

                setTimeout(() => {
                  if (map !== undefined) {
                    marker?.setMap(map);
                  }
                }, 0);

                googleMapApiService.current.sessionToken =
                  new google.maps.places.AutocompleteSessionToken();
                resolve({
                  locationAddressComponents: {
                    addresses: placeDetails?.address_components,
                  },
                  locationName: placeDetails?.name,
                  locationLatitude: placeDetails?.geometry?.location?.lat(),
                  locationLongitude: placeDetails?.geometry?.location?.lng(),
                  locationPlaceId: placeDetails?.place_id,
                });
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(null);
              }
            }
          );
        });
      },
    },
    sessionToken: undefined,
  });

  useEffect(() => {
    const loader = new GoogleApiLoader({
      apiKey: googleApiUrl
        ? new URL(googleApiUrl).searchParams.get('key') || ''
        : '',
      libraries: ['places', 'marker'],
      region: 'US',
      language: 'en',
    });
    loader.load().then(() => {
      const { google } = window;

      const mapInstance = new google.maps.Map(mapRef.current as HTMLElement, {
        center: {
          lat: DefaultLocation.locationLatitude,
          lng: DefaultLocation.locationLongitude,
        },
        controlSize: 22,
        draggable: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        mapTypeId: 'roadmap',
        zoom: 13,
        disableDoubleClickZoom: true,
        zoomControl: false,
      });
      googleMapApiService.current.autocomplete =
        new google.maps.places.AutocompleteService();
      googleMapApiService.current.map = mapInstance;
      googleMapApiService.current.marker = new google.maps.Marker({
        map: mapInstance,
      });
      googleMapApiService.current.places = new google.maps.places.PlacesService(
        mapInstance
      );
      googleMapApiService.current.sessionToken =
        new google.maps.places.AutocompleteSessionToken();
    });
  }, []);

  return googleMapApiService.current;
};
