import React from 'react';
import { SubmissionPdf, useApi } from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { AccountConst } from '@axiom/const';

import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { EnvUtil } from '../../utils/env-util';
import { useEngagementTalentData } from '../../hooks/useEngagementTalentData';
import { CandidateLegalTechSkillsApi } from '../../api/candidate-legal-tech-skills-api';

export const SubmissionPdfData = () => {
  const { submissionId } = useParams();

  const { accountId, submission, allCandidateOpps } = useEngagementTalentData();
  const isDirect = submission.accountType === AccountConst.Types.Direct;

  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const allGroupedExperiences = useApi(
    ...allCandidateOpps.map(({ candidate: { id } }) =>
      CandidateApi.readCandidateGroupedExperiences(id, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const allCandidateIds = allCandidateOpps.map(({ candidate: { id } }) => id);

  const allLegalTechnicalSkills = useApi(
    ...allCandidateOpps.map(({ candidate: { id } }) =>
      CandidateLegalTechSkillsApi.readLegalTechSkills(id)
    )
  ).map(response => response.data);

  return (
    <SubmissionPdf
      accountId={accountId}
      submission={submission}
      candidateOpps={allCandidateOpps}
      allGroupedExperiences={allGroupedExperiences}
      practiceAreas={practiceAreas}
      newEngagementUrl={
        isDirect
          ? `/my-axiom/account/${accountId}/intake`
          : EnvUtil.hubspotTalentFinder
      }
      newEngagementInNewTab={isDirect}
      clientUrl={EnvUtil.clientUrl}
      loginUrl={EnvUtil.loginUrl}
      taxonomy={taxonomy}
      allCandidateIds={allCandidateIds}
      allLegalTechnicalSkills={allLegalTechnicalSkills}
    />
  );
};
