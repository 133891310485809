import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  LargeHeader,
  SmallHeader,
  Layout,
  Card,
  CardSection,
  Gutter,
  Badge,
  Paragraph,
  CondensedHeader,
  Button,
  useApi,
  Grid,
  GridRow,
  GridColumn,
  Icon,
  Flashy,
  Loader,
  Visible,
  ServiceChoiceSuccessModal,
} from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { postCreateMqlPassing } from '../../api/hubspot-api';
import { AccountApi } from '../../api/account-api';
import { UserApi } from '../../api/user-api';
import { AccountLegacyApi } from '../../api/account-legacy-api';

export const ServiceChoice = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [
    { data: user },
    { data: accountNewEngagementAssignment },
    { data: account },
  ] = useApi(
    UserApi.readUser(),
    AccountApi.getAccountNewEngagementAssignment(accountId),
    AccountLegacyApi.getAccount(accountId, {
      expand: [AccountConst.Expansions.accountTeamUserInfo],
    })
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hubspotApiInitiated, setHubspotApiInitiated] = useState(false);

  const fullServiceDescriptions = [
    'Schedule time with Axiom to discuss your legal needs.',
    'Describe the work you need done on a live call. (30 minutes)',
    'Review curated talent matches within 2 business days.',
    'Schedule interviews and engage talent through your Axiom rep.',
    'Axiom is with you every step of the way.',
  ];

  const selfServiceDescriptions = [
    'Create an Axiom account.',
    'Provide a few details about your legal needs. (5-10 minutes)',
    'Instantly review top Axiom talent matches.',
    'Schedule interviews and engage your favorite talent digitally.',
    'You’re in control but Axiom is available if you need help.',
  ];

  return (
    <>
      <Grid
        centered
        name="SERVICE_CHOICE_SCREEN"
        stackable
        reversed="largeScreen tablet"
      >
        <GridRow gutterBottom="48px" gutterTop="16px" textAlign="center">
          <LargeHeader>How would you like to engage legal talent?</LargeHeader>
        </GridRow>
        <GridRow stretched>
          <GridColumn largeScreenWidth={4} tabletWidth={6} mobileWidth={12}>
            <Card stretched>
              <CardSection>
                <Grid>
                  <GridRow>
                    <GridColumn textAlign="center">
                      <Layout
                        direction="vertical"
                        position="middle"
                        verticalGutter="8px"
                      >
                        <SmallHeader>Self-Service</SmallHeader>
                        <Badge background="light orange">Go Digital</Badge>
                        <Paragraph>
                          I prefer to find and engage Axiom legal talent online.
                        </Paragraph>
                      </Layout>
                    </GridColumn>
                  </GridRow>
                </Grid>
                <Gutter bottom="24px" />
                <CondensedHeader>How it works</CondensedHeader>
                <Gutter bottom="16px" />
                {selfServiceDescriptions.map((description, index) => (
                  <Gutter
                    bottom="16px"
                    key={`self-service-layout-${index + 1}`}
                  >
                    <Layout
                      name="self-service-layout-description"
                      direction="horizontal"
                      position="left top"
                      horizontalGutter="14px"
                    >
                      <Gutter top="3px">
                        <Flashy color="dataVizPeach">
                          <Icon name="circle-check-filled" />
                        </Flashy>
                      </Gutter>
                      <Paragraph>{description}</Paragraph>
                    </Layout>
                  </Gutter>
                ))}
                <Gutter bottom="43px" />
                <Button
                  name="SELF_SERVICE_SUBMIT"
                  fluid
                  pattern="brand"
                  to={`/my-axiom/account/${accountId}/intake`}
                >
                  Get Started Now
                </Button>
              </CardSection>
            </Card>
            <Visible only="mobile">
              <Gutter bottom="24px" />
            </Visible>
          </GridColumn>
          <Gutter bottom="24px" gutterType="margin" />
          <GridColumn largeScreenWidth={4} tabletWidth={6} mobileWidth={12}>
            <Card stretched>
              <CardSection>
                <Grid>
                  <GridRow>
                    <GridColumn textAlign="center">
                      <Layout
                        direction="vertical"
                        position="middle"
                        verticalGutter="8px"
                      >
                        <SmallHeader>Full-Service</SmallHeader>
                        <Badge background="light blue">Talk With Axiom</Badge>
                        <Paragraph>
                          I want an Axiom rep to find the right legal talent for
                          me.
                        </Paragraph>
                      </Layout>
                    </GridColumn>
                  </GridRow>
                </Grid>
                <Gutter bottom="24px" />
                <CondensedHeader>How it works</CondensedHeader>
                <Gutter bottom="16px" />
                <Layout direction="vertical" position="space-between">
                  {fullServiceDescriptions.map((description, index) => (
                    <Gutter
                      bottom="16px"
                      key={`full-service-layout-${index + 1}`}
                    >
                      <Layout
                        direction="horizontal"
                        position="top"
                        horizontalGutter="14px"
                        name="full-service-layout-description"
                      >
                        <Gutter top="3px">
                          <Flashy color="controlInverseActive">
                            <Icon name="circle-check-filled" />
                          </Flashy>
                        </Gutter>
                        <Paragraph>{description}</Paragraph>
                      </Layout>
                    </Gutter>
                  ))}
                  <Gutter bottom="24px" />
                  <Button
                    name="FULL_SERVICE_SUBMIT"
                    fluid
                    onClick={async () => {
                      try {
                        setHubspotApiInitiated(true);
                        const data = {
                          fields: [
                            {
                              name: 'email',
                              value: user.email,
                            },
                            {
                              name: 'salesforce_account_owner',
                              value: accountNewEngagementAssignment.assignTo,
                            },
                            {
                              name: 'leadsource',
                              value: 'AFC - Self-Service',
                            },
                          ],
                        };
                        await postCreateMqlPassing(data);
                        setHubspotApiInitiated(false);
                        setShowSuccessModal(true);
                      } catch {
                        navigate('/400');
                      }
                    }}
                  >
                    Talk with {account.ownerUser?.firstName ?? 'Axiom'}
                  </Button>
                  {hubspotApiInitiated && (
                    <Loader expand="fullscreen" name="hubspot-loader" />
                  )}
                </Layout>
              </CardSection>
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>
      {showSuccessModal && (
        <ServiceChoiceSuccessModal
          accountId={accountId}
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </>
  );
};
