import React from 'react';
import { CondensedLarge, Flashy } from '@axiom/ui';

export const SinglePageViewDisclaimer = () => (
  <Flashy color="textSubtle" name="DISCLAIMER">
    <CondensedLarge>
      Axiom is not a law firm and does not provide legal advice. Our clients'
      legal teams supervise the legal work of the Axiom lawyer.
    </CondensedLarge>
  </Flashy>
);
