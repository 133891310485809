import React, { useState } from 'react';

import DefaultCompanyLogo from '../../../public/static/images/default-company-logo.png';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type CompanyLogoProps = {
  alt?: string;
  className?: string;
  fallback?: string | null;
  inverse?: boolean;
  monochromatic?: boolean;
  name?: string;
  src?: string | null;
  width?: string;
};

export const CompanyLogo = ({
  alt = 'Company Logo',
  className,
  fallback = DefaultCompanyLogo,
  inverse,
  monochromatic,
  name,
  src,
  width = '75px',
}: CompanyLogoProps) => {
  const [imageLoadFailed, setImageLoadFailed] = useState<boolean | null>(null);

  return (
    <>
      {!!src && imageLoadFailed !== true && (
        <img
          alt={alt}
          src={src}
          onError={() => setImageLoadFailed(true)}
          data-test={name}
          className={AttrsHelper.formatClassname(
            'company-logo',
            monochromatic && 'image-monochromatic',
            inverse && 'image-inverse',
            className
          )}
          style={{
            aspectRatio: '1 1',
            width,
          }}
        />
      )}
      {!!fallback && (!src || imageLoadFailed === true) && (
        <img
          src={fallback}
          alt={alt}
          data-test={name}
          className={AttrsHelper.formatClassname(
            'company-logo',
            monochromatic && 'image-monochromatic',
            inverse && 'image-inverse',
            className
          )}
          style={{
            aspectRatio: '1 1',
            width,
          }}
        />
      )}
    </>
  );
};
