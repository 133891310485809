import React from 'react';
import {
  Candidate,
  CandidateLegalTechSkill,
  GroupedExperiences,
  PracticeArea,
  Taxonomy,
  User,
} from '@axiom/validation';

import { BioPdf } from '../BioPdf/BioPdf';
import { BioView } from '../BioView/BioView';

export type TalentBiosType = {
  candidate: Candidate;
  clientUrl: string;
  groupedExperiences: GroupedExperiences[];
  legalTechSkills: CandidateLegalTechSkill[] | null;
  practiceAreas: PracticeArea[];
  taxonomy: Taxonomy;
  user?: User;
  onNavigate?: (hideHighlights?: boolean, hideLocation?: boolean) => void;
  download?: boolean;
  hideAllHighlights?: boolean;
  hideLocation?: boolean;
  onSave?: () => void;
  isAnonymous?: boolean;
};

export const TalentBios = ({
  candidate,
  clientUrl,
  groupedExperiences,
  legalTechSkills,
  practiceAreas,
  taxonomy,
  user,
  onNavigate,
  download = false,
  hideAllHighlights = false,
  hideLocation = false,
  onSave,
  isAnonymous = false,
}: TalentBiosType): JSX.Element | null => {
  return download ? (
    <BioPdf
      onSave={onSave}
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      isAnonymous={isAnonymous}
      clientUrl={clientUrl}
      hideAllHighlights={hideAllHighlights}
      hideHighlightsDetails
      hideLocation={hideLocation}
      taxonomy={taxonomy}
      legalTechSkills={legalTechSkills || []}
    />
  ) : (
    <BioView
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      legalTechSkills={legalTechSkills || []}
      practiceAreas={practiceAreas}
      taxonomy={taxonomy}
      user={user}
      onNavigate={onNavigate}
      isAnonymous={isAnonymous}
    />
  );
};
