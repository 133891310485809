import React from 'react';
import { Carousel, LargeHeader, Gutter } from '@axiom/ui';

import { useCandidateProfilesData } from '../../hooks/useCandidateProfilesData';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { DiscoverTalentEndCard } from '../DiscoverTalentEndCard/DiscoverTalentEndCard';

import { DiscoverTalentCarouselCard } from './DiscoverTalentCarouselCard';

export const DiscoverTalentCarousel = () => {
  const { account, user } = useUserAccountData();
  const { allCandidates } = useCandidateProfilesData(account);
  const buildChildren = () => {
    const kids = allCandidates.map(candidate => {
      return (
        <DiscoverTalentCarouselCard
          industryValue={account.industryValue}
          candidate={candidate}
          key={`${account.id}-${candidate.id}`}
          user={user}
        />
      );
    });
    kids.push(
      <DiscoverTalentEndCard
        forIndustryCarousel
        key="discover-endCard"
        account={account}
      />
    );

    return kids;
  };

  return (
    <>
      <LargeHeader name="DISCOVER_TALENT_HEADER">
        Discover top talent{account.industryValue ? ' in your industry' : ''}
      </LargeHeader>
      <Gutter bottom="24px" />
      <Carousel name="DISCOVER_TALENT_CAROUSEL">{buildChildren()}</Carousel>
    </>
  );
};
