import { useApi } from '@axiom/ui';
import { CandidateOpportunity, Account } from '@axiom/validation';

import { CandidateLegacyApi } from '../api/candidate-legacy-api';
import { CandidateApi } from '../api/candidate-api';
import { SearchFilters } from '../models/search-filters';

export const useCandidateProfilesData = (account: Account) => {
  const [{ data: savedCandidateOpps } = { data: [] }] = useApi(
    CandidateLegacyApi.getFavoriteCandidates()
  );

  const savedCandidates = savedCandidateOpps.map(c => c.candidate);
  const excludedCandidateIds = savedCandidateOpps.map(
    (c: CandidateOpportunity) => c.candidateId
  );

  const filters: SearchFilters = {
    excludedCandidateIds,
  };

  if (account?.industryValue) {
    filters.industries = [account.industryValue];
  }

  const [{ data: allCandidates } = { data: [] }] = useApi(
    CandidateApi.readCandidateSearchResultsForDashboard({
      page: 1,
      pageSize: 11,
      filters,
    })
  );

  return {
    savedCandidates,
    allCandidates,
  };
};
