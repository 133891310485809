import React from 'react';
import { AfcSubmissionCandidate } from '@axiom/validation';

import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { Gutter } from '../../layout/Gutter/Gutter';

export type TalentEducationTypes = {
  candidate: AfcSubmissionCandidate;
};

export const TalentEducation = ({ candidate }: TalentEducationTypes) => (
  <>
    {candidate?.degrees?.length === 0 && (
      <Paragraph name="NO_EDUCATION">--</Paragraph>
    )}
    {Array.isArray(candidate?.degrees) &&
      candidate.degrees.map(
        ({ institution, degree, yearAwarded, areaOfStudy }, index) => (
          <div
            data-test="EDUCATION_DEGREE"
            key={`${yearAwarded}_${institution}_${degree}_${areaOfStudy?.name}`}
          >
            <ParagraphHeader name="DEGREE_INSTITUTION">
              {institution}
            </ParagraphHeader>

            <Paragraph name="EDUCATION">
              {[degree, areaOfStudy?.name, yearAwarded]
                .filter(Boolean)
                .join(', ')}
            </Paragraph>
            {candidate.degrees && index < candidate.degrees.length - 1 && (
              <Gutter bottom="16px" />
            )}
          </div>
        )
      )}
  </>
);
