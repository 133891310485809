import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button } from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { searchResultsShape } from '../../models/search';
import { FavoriteCandidateStore } from '../../stores/favorite-candidate-store';
import { isLoggedInUser } from '../../utils/roles-util';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { FavoriteCandidateUtil } from '../../utils/favorite-candidate-util';

const FavoriteButtonWrapper = styled.div`
  position: relative;
`;

const FavoriteButtonComponent = ({
  favoriteCandidateState,
  user,
  candidate,
}) => {
  const handleFavClick = (event, isFavorited) => {
    event.preventDefault();
    event.stopPropagation();

    const payload = {
      talentId: candidate.id,
    };

    if (!isFavorited) payload.authed = isLoggedInUser(user);

    FavoriteCandidateStore.toggleFavorite(candidate.id, user, isFavorited);
  };
  return (
    <FavoriteButtonWrapper data-test="favorite-button">
      <StoreStateTreatment
        storeState={favoriteCandidateState}
        refetchLoader={<LoadingCurtain />}
        name="FAVORITE_CANDIDATES"
        renderLoadedView={({ data }) => {
          const isFavorited = FavoriteCandidateUtil.isCandidateFavorited(
            candidate,
            data
          );
          return (
            <Button
              variation="outline"
              fluid
              icon={isFavorited ? 'star-filled' : 'star'}
              onClick={e => {
                handleFavClick(e, isFavorited);
              }}
              disabled={candidate.isViewOnly}
            >
              {isFavorited ? 'Saved' : 'Save'}
            </Button>
          );
        }}
      />
    </FavoriteButtonWrapper>
  );
};

FavoriteButtonComponent.propTypes = {
  candidate: searchResultsShape.isRequired,
  favoriteCandidateState: FavoriteCandidateStore.getStateShape().isRequired,
  user: PreloadedUserStore.getDataShape(),
};

FavoriteButtonComponent.defaultProps = {
  user: null,
};

export const FavoriteButton = connect(state => ({
  favoriteCandidateState: FavoriteCandidateStore.select(state),
}))(FavoriteButtonComponent);
