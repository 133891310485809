import React from 'react';
import {
  AccountNewEngagementAssignment,
  Account,
  User,
} from '@axiom/validation';

import { Paragraph } from '../../content/Paragraph/Paragraph';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { Card } from '../../layout/Card/Card';
import { CardFooter } from '../../layout/Card/CardFooter';
import { CardHeader } from '../../layout/Card/CardHeader';
import { CardSection } from '../../layout/Card/CardSection';
import {
  StartEngagementButton,
  MqlData,
} from '../StartEngagementButton/StartEngagementButton';

export const ClientEngagementStartNewProject = ({
  account,
  accountNewEngagementAssignment,
  user,
  onSelfServiceIneligibleClick,
}: {
  account: Account;
  accountNewEngagementAssignment: AccountNewEngagementAssignment;
  user: User;
  onSelfServiceIneligibleClick: (data: MqlData) => Promise<void>;
}) => {
  return (
    <Card name="NEW_ENGAGEMENT_CARD" stretched className="with-borders">
      <CardHeader>
        <SmallHeader name="NEW_ENGAGEMENT_HEADER">
          Need more support
        </SmallHeader>
      </CardHeader>
      <CardSection divider>
        <Paragraph>
          Axiom has world-class legal talent with experience spanning all types
          of legal work, practice areas, and industries. If you have more legal
          work coming up, start a new engagement now and let Axiom help you get
          ahead of it.
        </Paragraph>
      </CardSection>
      <CardFooter>
        <StartEngagementButton
          onSelfServiceIneligibleClick={onSelfServiceIneligibleClick}
          name="NEW_ENGAGEMENT_NAVIGATE_BUTTON"
          account={account}
          accountNewEngagementAssignment={accountNewEngagementAssignment}
          user={user}
          pattern="primary"
          variation="outline"
        >
          Start an Engagement
        </StartEngagementButton>
      </CardFooter>
    </Card>
  );
};
