import { Api } from '@axiom/ui';
import { Account, AccountNewEngagementAssignment } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AccountApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  getAccountNewEngagementAssignment(accountId: Account['id']) {
    return super.read<{ data: AccountNewEngagementAssignment }>({
      endpoint: `/accounts/${accountId}/newEngagementAssignment`,
      method: 'GET',
    });
  }

  patchAccount(accountId: Account['id'], body: Account) {
    return super.write<{ data: Account }>({
      endpoint: `/accounts/${accountId}`,
      body,
      method: 'PATCH',
    });
  }

  refreshAccount(accountId: Account['id']) {
    return super.invalidate(`/accounts/${accountId}`);
  }
}

export const AccountApi = new AccountApiClass();
