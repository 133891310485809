import { z } from 'zod';
import { SchemaEmail } from '@axiom/types';

export const AdHocContactSchema = z.object({
  firstName: z.string().trim().min(1, {
    message: 'Required',
  }),
  lastName: z.string().trim().min(1, {
    message: 'Required',
  }),
  email: SchemaEmail,
  isUnitedStates: z.enum(['No', 'Yes']).refine(
    val => {
      if (val === 'No') return false;
      return val === 'Yes';
    },
    {
      message: 'Please remove the selected recipient.',
    }
  ),
});

export type AdHocContact = z.infer<typeof AdHocContactSchema>;

export const TalentInterviewConfirmModalSchema = z
  .object({
    contacts: z.array(z.string().uuid()),
    isZoomNotPreferred: z.boolean().nullable(),
    adHocContacts: AdHocContactSchema.array(),
    errorSelectAtLeastOne: z.string().optional(),
    errorMissMatchEmailDomain: z.string().optional(),
    availabilityNote: z.string().optional(),
  })
  .refine(
    ({ contacts, adHocContacts }) => {
      // checking for no client selected or added
      const hasSelected: boolean = contacts.length > 0;
      const hasAdHoc: boolean = adHocContacts.length > 0;

      return hasSelected || hasAdHoc;
    },
    {
      message: 'At least one contact must be selected or added',
      path: ['errorSelectAtLeastOne'],
    }
  );

export type TalentInterviewConfirmModal = z.infer<
  typeof TalentInterviewConfirmModalSchema
>;
