import React, { useMemo } from 'react';
import {
  TilesItem,
  Flashy,
  CondensedHeader,
  Gutter,
  Layout,
  ImageCircle,
  CandidateProfileUtil,
  CondensedLabel,
  Well,
  Paragraph,
} from '@axiom/ui';
import { useNavigate } from 'react-router-dom';
import {
  Account,
  CandidateOpportunity,
  AfcSubmissionsItem,
  AfcSubmission,
  InstantMatch,
} from '@axiom/validation';
import {
  OpportunitiesConst,
  PossibleImageSizes,
  CandidateOpportunitiesConst,
  AccountConst,
} from '@axiom/const';

import { DateUtil } from '../../utils/date-util';

import { EngagementCarouselTileBadge } from './EngagementCarouselTileBadge';

export interface TileButtonProps {
  submission: AfcSubmissionsItem;
  account: Account;
  submissionDetails: AfcSubmission;
  instantMatchCandidates: InstantMatch['candidate'][];
}

export const EngagementCarouselTileButton = ({
  submission,
  account,
  submissionDetails,
  instantMatchCandidates,
}: TileButtonProps): JSX.Element => {
  const isProjectSubmitted =
    submission.projectStateDetail.state ===
    OpportunitiesConst.ProjectStates.Submitted;
  const isAccountDirect = account.accountType === AccountConst.Types.Direct;
  const navigate = useNavigate();
  const hasTalentWithStatus = (
    status: CandidateOpportunity['candidateStatus']
  ) => {
    return submissionDetails?.positions.some(position =>
      position.candidateOpportunities.some(
        cOpp => cOpp.candidateStatus === status
      )
    );
  };
  const calculateEngagementStatus = () => {
    if (
      hasTalentWithStatus(
        CandidateOpportunitiesConst.CandidateStatuses.Selected
      )
    ) {
      return CandidateOpportunitiesConst.CandidateStatuses.Selected;
    } else if (
      hasTalentWithStatus(
        CandidateOpportunitiesConst.CandidateStatuses.Interviewing
      )
    ) {
      return CandidateOpportunitiesConst.CandidateStatuses.Interviewing;
    }
    return null;
  };

  const startAtDisplay = submission.projectStateDetail.startDate
    ? DateUtil.displayDate(submission.projectStateDetail.startDate)
    : '--';
  const endAtDisplay = submission.projectStateDetail.endDate
    ? DateUtil.displayDate(submission.projectStateDetail.endDate)
    : '--';
  const maxProfilePhotosAllowed = 4;

  const computedCandidates = useMemo(() => {
    const properCandidateStatus = calculateEngagementStatus();
    const filteredCandidates =
      submissionDetails?.positions.reduce(
        (candidates, position) => [
          ...candidates,
          ...position.candidateOpportunities
            .filter(candidateOpp =>
              properCandidateStatus
                ? candidateOpp.candidateStatus === properCandidateStatus
                : !!candidateOpp
            )
            .map(cOpp => cOpp.candidate),
        ],
        []
      ) || [];

    return properCandidateStatus
      ? filteredCandidates
      : [...filteredCandidates, ...instantMatchCandidates];
  }, [submissionDetails, isAccountDirect, instantMatchCandidates]);

  const calculateContactLabel = () => {
    if (!isProjectSubmitted) {
      return 'Primary Contact:';
    }
    return 'Axiom Representative:';
  };

  const calculateContactValue = () => {
    if (!isProjectSubmitted) {
      return submission.clientName;
    } else if (!isAccountDirect && submissionDetails?.salesLead) {
      return `${submissionDetails.salesLead.firstName} ${submissionDetails.salesLead.lastName}`;
    }
    return 'Self-Service';
  };

  return (
    <TilesItem
      name="ENGAGEMENT_TILE"
      onClick={() =>
        navigate(
          `./submission/${submission.submissionId}/${
            isProjectSubmitted ? 'matches' : 'engagement-details'
          }`
        )
      }
    >
      <Flashy color="textDefault">
        <EngagementCarouselTileBadge
          instantMatchCandidates={instantMatchCandidates}
          submission={submission}
          submissionDetails={submissionDetails}
        />
        <Gutter bottom="8px" />
        <CondensedHeader name="ENGAGEMENT_TILE_HEADER">
          {submission.jobName}
        </CondensedHeader>
        {computedCandidates.length > 0 && (
          <Gutter vertical="16px">
            <Layout horizontalGutter="8px" position="middle">
              {computedCandidates
                .slice(0, maxProfilePhotosAllowed)
                .map(candidate => (
                  <div data-test="ENGAGEMENT_TALENT_IMAGE" key={candidate.id}>
                    <ImageCircle
                      name={`TALENT_IMAGE_${candidate.id}`}
                      size="small"
                      background="light blue"
                      imageName={candidate?.calculatedDisplayName}
                      src={CandidateProfileUtil.getProfileImageUri(
                        candidate,
                        PossibleImageSizes.W_56
                      )}
                    />
                  </div>
                ))}
              {computedCandidates.length > maxProfilePhotosAllowed && (
                <CondensedLabel name="MORE_CANDIDATES_LABEL">
                  + {computedCandidates.slice(maxProfilePhotosAllowed).length}{' '}
                  More
                </CondensedLabel>
              )}
            </Layout>
          </Gutter>
        )}
        <Well>
          <Paragraph name="ENGAGMENT_TILE_CONTACT">
            <Flashy bold>{calculateContactLabel()}</Flashy>{' '}
            {calculateContactValue()}
          </Paragraph>
          <Gutter bottom="16px" />
          <Paragraph name="ENGAGEMENT_TILE_DATE">
            <Flashy bold>
              {isProjectSubmitted
                ? 'Target Start Date:'
                : 'Engagement End Date:'}
            </Flashy>{' '}
            {isProjectSubmitted ? startAtDisplay : endAtDisplay}
          </Paragraph>
        </Well>
        <Gutter bottom="16px" />
      </Flashy>
    </TilesItem>
  );
};
