export const assignmentType = {
  owner: 'owner',
  collaborator: 'collaborator',
  team: 'team',
} as const;

export const sortType = {
  salesCloseDate: 'salesCloseDate',
  ownerUser: 'ownerUser',
  positions: 'positions',
  jobName: 'jobName',
  createdAt: 'createdAt',
  stageCode: 'stageCode',
  salesLeadId: 'salesLeadId',
} as const;

// Paginated candidate and opportunities results are GET requests.
// Once the number of filters exceeds a certain number, the referer header in
// those requests exceeeds the max header size we currently allow. A more robust
// fix would be to change these searches to POST requests, but in practice no
// one would realistically search on this many criteria.
// see: https://axiomlegal.atlassian.net/browse/TS-388
export const MAX_SEARCH_FILTER_COUNT = 20;

export const maxFiltersErrorMessage =
  'It looks like too many filters have been selected. Try removing one or more filters before applying a new one.';

export const VALID_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'District Of Columbia',
] as const;
