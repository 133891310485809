import React, { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader, useApi } from '@axiom/ui';
import { Account, AfcSubmission } from '@axiom/validation';

import { AccountLegacyApi } from '../api/account-legacy-api';
import { AccountSubmissionsApi } from '../api/account-submissions-api';

const shouldRedirect = (
  account: Account,
  submission: AfcSubmission | null,
  submissionId?: AfcSubmission['id']
) => {
  return !!(
    submissionId &&
    (!submission || !submission.selfService || !submission.editable)
  );
};

export const SelfServiceRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { accountId, submissionId } = useParams();
  const [{ data: account }] = useApi(AccountLegacyApi.getAccount(accountId));
  const [submissionResponse] = useApi(
    submissionId
      ? AccountSubmissionsApi.readAccountSubmissionDetails(
          accountId,
          submissionId
        )
      : null
  );
  const submission = submissionResponse?.data;

  const redirect = shouldRedirect(account, submission, submissionId);

  useEffect(() => {
    if (redirect) {
      navigate('../engagements', { replace: true });
    }
  }, []);

  return redirect ? <Loader name="self-service-loader" /> : children;
};
