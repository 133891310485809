import React, { ReactNode } from 'react';

import { Icon } from '../../element/Icon/Icon';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { IconName } from '../../element/Icon/icon-types';

export type ProgressStepsProps = {
  children: React.ReactNode;
  className?: string;
  completedStep?: string;
  direction?: 'horizontal' | 'vertical';
  name?: string;
  icon?: IconName;
};

export const ProgressSteps = ({
  children,
  className,
  completedStep,
  direction = 'horizontal',
  name,
  icon,
}: ProgressStepsProps) => {
  const stepChildren = React.Children.toArray(children);
  const steps = stepChildren.map(child => {
    // @ts-expect-error Needs proper type
    return child?.props.stepName;
  });

  const getNestedIndicator = () =>
    // @ts-expect-error Needs proper type
    stepChildren.map((ch: React.ReactElement, index) => (
      <div
        key={ch.props.stepName}
        data-test={`progress-step-${index}`}
        className={AttrsHelper.formatClassname(
          'progress-step',
          steps.indexOf(completedStep) >= index && 'step-complete',
          steps.indexOf(completedStep) > index && 'step-extend',
          steps.indexOf(completedStep) === index && 'step-highlight'
        )}
      >
        <div className="indicator-nested">
          {index > 0 && <div className="indicator-bar-before" />}
          {index < stepChildren.length - 1 && (
            <div className="indicator-bar-after" />
          )}
          <div className="indicator-circle">
            {!!icon && steps.indexOf(completedStep) >= index && (
              <Icon name={icon} />
            )}
          </div>
        </div>
        <div className="progress-step-label-container">{ch}</div>
      </div>
    ));

  const nestedIndicator = getNestedIndicator() as ReactNode;
  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'progress-steps',
        `direction-${direction}`,
        className
      )}
    >
      <div className="indicator-overarch">
        <div className="indicator-bar" />
        <div
          className="indicator-bar-complete"
          data-test="horizontal-bar-completed"
          style={{
            [direction === 'horizontal' ? 'width' : 'height']:
              completedStep &&
              `${
                (steps.indexOf(completedStep) / (stepChildren.length - 1)) * 100
              }%`,
          }}
        />
        <div data-test="indicator-circles" className="indicator-circles">
          {nestedIndicator}
        </div>
      </div>
      <div className="indicator-labels">{nestedIndicator}</div>
    </div>
  );
};
