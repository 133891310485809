export class TextareaReplacementUtil {
  text: string;

  constructor(text = '') {
    this.text = text;
  }

  replacesOneNewLineWithTwo(v: string = this.text) {
    this.text = v ? v.replaceAll(/(?<!-)\n(?!-)/gi, '\n\n') : v;
    return this;
  }

  replacesTwoNewLinesWithOne(v: string = this.text) {
    this.text = v ? v.replaceAll(/(?<!-)(\n){2}/g, '\n') : v;
    return this;
  }

  // Fix for bug: FR-3889
  // See https://regexr.com/80cqr to see documentation on what this RegEx does
  replaceEmptyBulletListItem(v: string = this.text) {
    this.text = v ? v.replaceAll(/^ *((-+ *)+\n?$)+/gm, '') : v;
    return this;
  }

  // Fix for bug: FR-4511
  // See https://regexr.com/82d32 to see documentation on what this RegEx does
  replaceFourOrMoreStartingSpaces(v: string = this.text) {
    this.text = v ? v.replaceAll(/^(- )? {4,}/gm, '$1') : v;
    return this;
  }

  getText() {
    return this.text;
  }
}
