import React from 'react';
import { useParams } from 'react-router-dom';
import {
  HeaderBarLinkSection,
  HeaderBarUserSection,
  useApi,
  CookieUtil,
  StartEngagementButton,
} from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { SharedHeader } from '../../components/SharedHeader/SharedHeader';
import { QueryParamUtil } from '../../utils/query-param-util';
import { isClientUser } from '../../utils/roles-util';
import { AccountLegacyApi } from '../../api/account-legacy-api';
import { AccountApi } from '../../api/account-api';
import { postCreateMqlPassing } from '../../api/hubspot-api';
import { UserApi } from '../../api/user-api';

import { AuthenticatedHeaderMenuItem } from './AuthenticatedHeaderMenuItem';

export const AuthenticatedHeaderMenu = () => {
  const { accountId: pathParamAccountId } = useParams();
  const { accountId: queryParamAccountId } = QueryParamUtil.getQueryParams();
  const hasUserCookie = !!CookieUtil.get('user') || null;
  const [{ data: user } = { data: null }] = useApi(
    hasUserCookie && UserApi.readUser()
  );
  const contact = user?.contact;

  const accountId = pathParamAccountId ?? queryParamAccountId;
  const account = (() => {
    let acc;

    if (!contact?.accounts?.length) {
      return null;
    }

    if (accountId) {
      acc = contact.accounts.find(a => a.id === accountId) || null;
    } else {
      [acc] = contact.accounts;
    }

    return acc;
  })();
  const calculatedAccountId = accountId ?? account?.id;
  // will fix in APCORE-2573
  const [{ data: acc } = { data: null }] = useApi(
    calculatedAccountId
      ? AccountLegacyApi.getAccount(calculatedAccountId, {
          expand: [AccountConst.Expansions.accountTeamUserInfo],
        })
      : undefined
  );

  const [{ data: accountNewEngagementAssignment } = { data: null }] = useApi(
    calculatedAccountId && acc.accountType !== AccountConst.Types.Direct
      ? AccountApi.getAccountNewEngagementAssignment(calculatedAccountId)
      : undefined
  );

  return (
    <>
      <HeaderBarLinkSection name="AUTHENTICATED_HEADER_LINK_SECTION">
        {isClientUser(user) && (
          <AuthenticatedHeaderMenuItem
            name="NAVBAR_MY_AXIOM"
            url={`/my-axiom/account/${calculatedAccountId}`}
          >
            My Axiom
          </AuthenticatedHeaderMenuItem>
        )}
        {isClientUser(user) && (
          <AuthenticatedHeaderMenuItem
            name="NAVBAR_MY_AXIOM_ENGAGEMENTS"
            url={`/my-axiom/account/${calculatedAccountId}/engagements`}
            secondaryMatchSlug="engagements"
          >
            My Engagements
          </AuthenticatedHeaderMenuItem>
        )}
        {isClientUser(user) && !!account && (
          <AuthenticatedHeaderMenuItem
            name="NAVBARBENCH"
            url={`/bench?account=${calculatedAccountId}`}
          >
            My Bench
          </AuthenticatedHeaderMenuItem>
        )}
        <AuthenticatedHeaderMenuItem
          name="NAVBAR_BROWSE_TALENT"
          url="/access-legal-talent"
        >
          Browse Talent
        </AuthenticatedHeaderMenuItem>
        <StartEngagementButton
          name="NEW_ENGAGEMENT"
          account={acc}
          user={user}
          accountNewEngagementAssignment={accountNewEngagementAssignment}
          pattern="brand"
          variation="filled"
          onSelfServiceIneligibleClick={postCreateMqlPassing}
        >
          New Engagement
        </StartEngagementButton>
      </HeaderBarLinkSection>
      <HeaderBarUserSection name="AUTHENTICATED_HEADER_USER_SECTION">
        <SharedHeader />
      </HeaderBarUserSection>
    </>
  );
};
