import React, { useCallback } from 'react';
import {
  Account,
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
  Candidate,
  CandidateLegalTechSkill,
  GroupedExperiences,
  PracticeArea,
  Taxonomy,
} from '@axiom/validation';

import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Gutter } from '../../layout/Gutter/Gutter';
import { BioPdfCandidate } from '../BioPdf/BioPdfCandidate';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { SubmissionPdfDescriptionMultiTalent } from '../SubmissionPdfDescriptionMultiTalent/SubmissionPdfDescriptionMultiTalent';
import { PdfWantToTakeAction } from '../PdfWantToTakeAction/PdfWantToTakeAction';
import { LightTheme } from '../../theme/LightTheme/LightTheme';

import { SubmissionPdfEngagementDetails } from './SubmissionPdfEngagementDetails';
import { SubmissionPdfHeader } from './SubmissionPdfHeader';

import {
  Card,
  CardSection,
  EmptyLayout,
  Paragraph,
  SmallHeader,
  Visible,
} from '@axiom/ui';
import EmptyProjectGraphic from '@axiom/ui/src/public/static/svg/empty-project.svg';

export type SubmissionPdfTypes = {
  accountId: Account['id'];
  submission: AfcSubmission;
  candidateOpps: AfcSubmissionCandidateOpportunity[];
  allGroupedExperiences: GroupedExperiences[][];
  practiceAreas: PracticeArea[];
  newEngagementUrl: string;
  clientUrl: string;
  loginUrl: string;
  taxonomy: Taxonomy;
  newEngagementInNewTab?: boolean;
  allCandidateIds: string[];
  allLegalTechnicalSkills: CandidateLegalTechSkill[][];
};

export const SubmissionPdf = ({
  accountId,
  submission,
  candidateOpps,
  allGroupedExperiences,
  practiceAreas,
  newEngagementUrl,
  clientUrl,
  loginUrl,
  taxonomy,
  newEngagementInNewTab,
  allCandidateIds,
  allLegalTechnicalSkills,
}: SubmissionPdfTypes) => {
  const getExperiences = useCallback(
    (id: Candidate['id']): GroupedExperiences[] => {
      return (
        allGroupedExperiences.find(ge => ge?.[0]?.candidateId === id) || []
      );
    },
    [allGroupedExperiences, candidateOpps]
  );
  const getLegalTechnicalSkills = useCallback(
    (id: Candidate['id']): CandidateLegalTechSkill[] => {
      const index = allCandidateIds.indexOf(id);
      return allLegalTechnicalSkills[index] || [];
    },
    [allLegalTechnicalSkills, candidateOpps]
  );

  return (
    <div className="k-pdf-export">
      <LightTheme>
        <Layout background="panelLight" name="SUBMISSION_PDF">
          <LayoutItem>
            <Grid>
              <GridRow gutterBottom="24px">
                <GridColumn>
                  <SubmissionPdfHeader
                    submission={submission}
                    clientUrl={clientUrl}
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="32px">
                <GridColumn>
                  <SubmissionPdfDescriptionMultiTalent loginUrl={loginUrl} />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="32px">
                <GridColumn>
                  <SubmissionPdfEngagementDetails
                    submission={submission}
                    taxonomy={taxonomy}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  {candidateOpps.length > 0 ? (
                    candidateOpps.map(candidateOpp => (
                      <Gutter bottom="32px" key={candidateOpp.candidate.id}>
                        <BioPdfCandidate
                          candidate={candidateOpp.candidate}
                          submissionDescription={
                            candidateOpp.submissionDescription
                          }
                          groupedExperiences={getExperiences(
                            candidateOpp.candidate.id
                          )}
                          submission={submission}
                          practiceAreas={practiceAreas}
                          taxonomy={taxonomy}
                          removeLogo
                          legalTechSkills={getLegalTechnicalSkills(
                            candidateOpp.candidate.id
                          )}
                        />
                        {!!accountId && !!submission?.submissionId && (
                          <Gutter horizontal="32px">
                            <PdfWantToTakeAction
                              candidate={candidateOpp.candidate}
                              accountId={accountId}
                              submissionId={submission.submissionId}
                              clientUrl={clientUrl}
                            />
                          </Gutter>
                        )}
                      </Gutter>
                    ))
                  ) : (
                    <Gutter horizontal="24px" bottom="32px">
                      <Card name="no-profiles">
                        <CardSection divider>
                          <EmptyLayout
                            graphic={
                              <Visible only="largeScreen">
                                <EmptyProjectGraphic
                                  width="200px"
                                  height="200px"
                                  data-test="empty-graphic"
                                />
                              </Visible>
                            }
                            graphicPosition="right"
                          >
                            <SmallHeader>No profiles? No problem.</SmallHeader>
                            <Gutter bottom="16px" />
                            <Paragraph>
                              Axiom’s Talent Specialists are busy hand-selecting
                              the best matches for your engagement. We’ll send
                              you an email when your matches are ready, or you
                              can check back here in 1-2 business days to see
                              your matches!
                            </Paragraph>
                            <br />
                            <br />
                            <Paragraph>
                              Can we help with something else? Axiom has
                              world-class legal talent with experience spanning
                              all types of legal work, practice areas, and
                              industries. If you have more legal work coming up,
                              start a new engagement now and let Axiom help you
                              get ahead of it.
                            </Paragraph>
                          </EmptyLayout>
                        </CardSection>
                      </Card>
                    </Gutter>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>
          </LayoutItem>
        </Layout>
      </LightTheme>
    </div>
  );
};
