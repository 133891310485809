import React, { SyntheticEvent } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export interface RawCheckboxPillProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: (e: SyntheticEvent) => void;
  selected?: boolean;
  name: string;
  invalid?: boolean;
}

export const RawCheckboxPill = ({
  children,
  disabled = false,
  onClick,
  selected = false,
  name,
  invalid = false,
}: RawCheckboxPillProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname(
        'checkbox-pill',
        disabled && 'disabled',
        selected ? 'selected' : 'unselected',
        invalid && 'invalid'
      )}
      data-test={name}
      onClick={disabled ? undefined : onClick}
    >
      <div className="checkbox-pill-content">{children}</div>
    </div>
  );
};
