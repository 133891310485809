import React, { useState } from 'react';
import {
  Button,
  CondensedLarge,
  ContextMenu,
  ContextMenuItem,
  Suspense,
  ToastUtil,
} from '@axiom/ui';
import { Account, User } from '@axiom/validation';

import { isEnvoyUser } from '../../utils/roles-util';
import { SubmissionPdfDownload } from '../SubmissionPdfDownload/SubmissionPdfDownload';

type PropTypes = {
  user?: User;
  account?: Account;
  onClickInviteToAccount: () => void;
  onClickOpenInAhq?: () => void;
};

const userDefault = {};
const onClickOpenInAhqDefault = () => {};
export const EngagementsShareContextMenu = ({
  user = userDefault,
  onClickInviteToAccount,
  onClickOpenInAhq = onClickOpenInAhqDefault,
}: PropTypes) => {
  const [downloadPdf, setDownloadPdf] = useState(false);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);

    ToastUtil.add({
      name: 'COPIED_URL',
      type: 'info',
      children: <CondensedLarge>Link copied!</CondensedLarge>,
    });
  };

  const handleDownloadMatches = () => {
    setDownloadPdf(!downloadPdf);

    ToastUtil.add({
      name: 'DOWNLOAD_PDF',
      type: 'info',
      children: (
        <CondensedLarge>
          Your PDF is being created - it will download automatically when ready.
        </CondensedLarge>
      ),
    });
  };

  return (
    <>
      <ContextMenu
        name="ACTIONS_MENU"
        direction="left"
        fluid
        anchor={
          <Button
            fluid
            pattern="primary"
            variation="minimal"
            name="ACTION_BUTTON"
            icon="open"
          >
            Share
          </Button>
        }
      >
        <ContextMenuItem name="copy-link-option" onClick={handleCopyLink}>
          Copy link
        </ContextMenuItem>
        <ContextMenuItem
          name="share-option"
          onClick={() => onClickInviteToAccount()}
        >
          Invite Others to Account
        </ContextMenuItem>
        <ContextMenuItem name="download-pdf" onClick={handleDownloadMatches}>
          Download All Matches
        </ContextMenuItem>
        {isEnvoyUser(user) && (
          <ContextMenuItem name="AHQ_LINK" onClick={() => onClickOpenInAhq()}>
            Open in AHQ
          </ContextMenuItem>
        )}
      </ContextMenu>
      {!!downloadPdf && (
        <Suspense fallback={null}>
          <div className="k-pdf-export">
            <SubmissionPdfDownload
              onSave={() => setDownloadPdf(!downloadPdf)}
            />
          </div>
        </Suspense>
      )}
    </>
  );
};
