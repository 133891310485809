import { Api } from '@axiom/ui';
import {
  Account,
  AfcSubmissionsItem,
  AfcSubmissionCandidate,
  Candidate,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AccountSubmissionsLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readCandidateAccountSubmissionDetails(
    accountId: Account['id'],
    submissionId: AfcSubmissionsItem['id'],
    candidateId: Candidate['id'],
    isInstantMatch?: boolean
  ) {
    return super.read<{ data: AfcSubmissionCandidate }>({
      query: { isInstantMatch: !!isInstantMatch },
      endpoint: `/accounts/${accountId}/submissions/${submissionId}/candidates/${candidateId}`,
      method: 'GET',
    });
  }

  refreshCandidateAccountSubmissionDetails(
    accountId: Account['id'],
    submissionId: AfcSubmissionsItem['id'],
    candidateId: Candidate['id']
  ) {
    return super.invalidate(
      `/accounts/${accountId}/submissions/${submissionId}/candidates/${candidateId}`
    );
  }
}

export const AccountSubmissionsLegacyApi =
  new AccountSubmissionsLegacyApiClass();
