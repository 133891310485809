import { PracticeArea } from '@axiom/validation';
import { PracticeAreaConst } from '@axiom/const';

type PracticeAreaAndSpecialty = {
  practiceArea?: PracticeArea | null;
  specialty?: PracticeArea | null;
};

const getPracticeAreaAndSpecialty = (
  practiceAreas: PracticeArea[],
  practiceAreaId?: PracticeArea['id'] | null
): PracticeAreaAndSpecialty => {
  const getPracticeArea = (paid = practiceAreaId): PracticeArea | null => {
    return practiceAreas?.find(pa => pa.id === paid) ?? null;
  };
  const practiceArea = getPracticeArea();
  const response: PracticeAreaAndSpecialty = {
    practiceArea: {},
    specialty: {},
  };

  if (practiceArea) {
    if (
      practiceArea.type === PracticeAreaConst.PRACTICE_AREA_TYPES.practiceArea
    ) {
      response.practiceArea = practiceArea;
    } else if (
      practiceArea.type === PracticeAreaConst.PRACTICE_AREA_TYPES.specialty
    ) {
      response.practiceArea = getPracticeArea(practiceArea.parentId);
      response.specialty = practiceArea;
    }
  }

  return response;
};

const formatPracticeAreaSpecialty = (
  practiceAreas: PracticeArea[],
  practiceAreaId?: PracticeArea['id'] | null
) => {
  if (!practiceAreaId) {
    return null;
  }

  const { practiceArea, specialty } = getPracticeAreaAndSpecialty(
    practiceAreas,
    practiceAreaId
  );

  return specialty?.name && practiceArea?.name !== specialty.name
    ? `${practiceArea?.name} - ${
        specialty.name.toLowerCase() === 'm&a, divestitures'
          ? 'Divestitures'
          : specialty.name
      }`
    : practiceArea?.name;
};

const displayPracticeAreaAndSpecialty = ({
  practiceArea,
  specialty,
}: PracticeAreaAndSpecialty): string | null => {
  if (practiceArea?.name && specialty?.name) {
    return `${practiceArea?.name} - ${specialty?.name}`;
  } else if (practiceArea?.name && !specialty?.name) {
    return practiceArea?.name;
  }

  return null;
};

export const PracticeAreaUtil = {
  formatPracticeAreaSpecialty,
  getPracticeAreaAndSpecialty,
  displayPracticeAreaAndSpecialty,
};
