import React from 'react';

import MultiTasking from '../../../public/static/svg/multi-tasking.svg';
import { Modal } from '../../element/Modal/Modal';
import { ModalHeader } from '../../element/Modal/ModalHeader';
import { ModalSection } from '../../element/Modal/ModalSection';
import { ModalFooter } from '../../element/Modal/ModalFooter';
import { SectionHeader } from '../../content/SectionHeader/SectionHeader';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { Button } from '../../element/Button/Button';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Visible } from '../../layout/Visible/Visible';

export const ServiceChoiceSuccessModal = ({
  onClose,
  accountId,
}: {
  onClose: () => void;
  accountId: string;
}) => {
  return (
    <Modal name="SERVICE_CHOICE_SUCCESS_MODAL">
      <ModalHeader
        onClose={() => onClose()}
        name="SERVICE_CHOICE_SUCCESS_MODAL_HEADER"
      >
        We got you. Here’s what happens next.
      </ModalHeader>
      <ModalSection>
        <Grid>
          <GridRow>
            <Visible only="tablet largeScreen">
              <GridColumn width={4}>
                <MultiTasking width="197px" height="174px" />
              </GridColumn>
            </Visible>
            <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
              <Gutter bottom="16px">
                <SectionHeader>
                  Take a breath - this is what we do best
                </SectionHeader>
              </Gutter>
              <Paragraph>
                Your Axiom rep will reach out to discuss your legal needs in
                more detail. Axiom will then curate matches, set up interviews,
                and help you select the right Axiom legal pro for your business.
              </Paragraph>
            </GridColumn>
          </GridRow>
        </Grid>
      </ModalSection>
      <ModalFooter>
        <Button
          name="ACKNOWLEDGE_SUCCESS_BUTTON"
          to={`/my-axiom/account/${accountId}`}
          onClick={() => onClose()}
        >
          Got It!
        </Button>
      </ModalFooter>
    </Modal>
  );
};
