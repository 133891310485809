import { z } from 'zod';
import { SchemaEmail } from '@axiom/types';

/**
 * Here we check to make sure that the given name string is valid.
 * This given name should contain more than just spaces and the result is trimmed
 */
const SchemaName = z.string().trim().min(1, { message: 'Required' });

export const EngagementsShareModalSchema = z.object({
  firstName: SchemaName,
  lastName: SchemaName,
  email: SchemaEmail.nullable(),
  notes: z.string().trim().max(1000).nullable(),
  isUnitedStates: z.enum(['No', 'Yes']).refine(
    val => {
      if (val === 'No') return false;
      return val === 'Yes';
    },
    {
      message: 'Please remove the selected recipient.',
    }
  ),
});

export const ConfidentialFormSchema = z.object({
  confidentialOption: z.boolean().nullable(),
});

export type EngagementsShareModalProps = z.infer<
  typeof EngagementsShareModalSchema
>;
export type ConfidentialFormProps = z.infer<typeof ConfidentialFormSchema>;
