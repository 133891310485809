import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';
import { Account, AfcSubmissionsItem } from '@axiom/validation';
import { OpportunitiesConst } from '@axiom/const';

import { AccountSubmissionsApi } from '../api/account-submissions-api';

interface Args {
  account?: Account;
}

type SubmissionReducerType = {
  allActiveSubmissions: AfcSubmissionsItem[];
  allUpcomingSubmissions: AfcSubmissionsItem[];
};

export const useSubmissionsData = (props: Args = {}) => {
  const params = useParams();
  const urlAccountId = params?.accountId;
  const accountId = props?.account?.id ?? urlAccountId;
  const [{ data: allSubmissions } = { data: [] }] = useApi(
    accountId
      ? AccountSubmissionsApi.readAccountSubmissions(accountId)
      : undefined
  );

  const splitSubmissions = allSubmissions.reduce(
    (crnt: SubmissionReducerType, submission: AfcSubmissionsItem) => {
      switch (submission.projectStateDetail.state) {
        case OpportunitiesConst.ProjectStates.Active: {
          crnt.allActiveSubmissions.push(submission);
          break;
        }
        case OpportunitiesConst.ProjectStates.Submitted: {
          crnt.allUpcomingSubmissions.push(submission);
          break;
        }
        default:
      }

      return crnt;
    },
    {
      allActiveSubmissions: [],
      allUpcomingSubmissions: [],
    } as SubmissionReducerType
  );

  return {
    allSubmissions,
    ...splitSubmissions,
  };
};
