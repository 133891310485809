import React, { useContext } from 'react';
import { FormFieldProps as SemanticFieldProps } from 'semantic-ui-react';

import {
  FormGroupContext,
  FormGroupContextType,
} from '../FormGroup/FormGroupContext';
import { FormField, FormFieldProps } from '../FormField/FormField';

import {
  RawCheckbox,
  RawCheckboxProps,
  RawCheckboxValueType,
} from './RawCheckbox';

const noop: RawCheckboxProps['onChange'] = value => value;

export type CheckboxProps<FormValue> = {
  description?: FormFieldProps<FormValue>['description'];
  disabled?: RawCheckboxProps['disabled'];
  displayValue?: RawCheckboxProps['displayValue'];
  id?: RawCheckboxProps['id'];
  label?: FormFieldProps<FormValue>['label'];
  mode?: RawCheckboxProps['mode'];
  name: RawCheckboxProps['name'];
  onChange?: RawCheckboxProps['onChange'];
  /**
   * Provide a value that represents checkbox as checked if you need something
   * other than a boolean.
   *
   * For instance: {favoriteFood: steak}
   * `<Checkbox
   *  name="favoriteFood"
   *  option="steak"
   *  />`
   *
   */
  option?: RawCheckboxProps['option'];
  Tooltip?: FormFieldProps<FormValue>['Tooltip'];
};

export const Checkbox = <FormValue,>({
  description,
  disabled = false,
  displayValue,
  id,
  label,
  mode,
  name,
  onChange = noop,
  option = true,
  Tooltip,
}: CheckboxProps<FormValue>) => {
  /**
   * In case id isn't passed in, and if this component shares the same data `name` with
   * another component, we can be assured the id is at least unique for
   * label=>form element connections within the DOM.
   *
   * Example:
   * Component1 option=Donut: foodChoice
   * Component2 option=Steak: foodChoice
   *
   * formattedId = id=foodChoiceDonut
   */
  const formattedId = id || name + option;
  const formGroup = useContext(FormGroupContext) as FormGroupContextType;
  return (
    <div className="checkbox-root">
      <FormField
        {...{
          dataTest: formGroup ? `${name}-${option}` : `${name}`,
          description,
          disabled,
          id: formattedId,
          label,
          name,
          Tooltip,
        }}
        renderField={({
          value,
          schemaProperty,
          setFieldValue,
          setFieldTouched,
          invalid,
        }: {
          value: RawCheckboxValueType;
        } & SemanticFieldProps) => (
          <RawCheckbox
            dataType={schemaProperty.type}
            disabled={disabled}
            displayValue={displayValue}
            id={formattedId}
            invalid={invalid}
            mode={mode}
            name={name}
            onChange={(v, e) => {
              // Formik won't recognize the checkbox as touched if you uncheck it
              // Adding onBlur has a weird delay so, for now, this manual step
              setFieldTouched(name);
              onChange(v, e);
              setFieldValue(name, v);
            }}
            option={option}
            value={value}
          />
        )}
      />
    </div>
  );
};
