import { AfcSubmissionCandidate, Candidate } from '@axiom/validation';

type PropsType = {
  candidate?: Partial<Candidate | AfcSubmissionCandidate>;
  submissionDescription?: string | null;
};

export class SubmissionCandidateModel {
  props: PropsType = { candidate: {} };

  constructor(props: PropsType = { candidate: {} }) {
    this.props = props;
  }

  calculatedDescription() {
    return (
      this.props?.submissionDescription ||
      this.props.candidate?.publicCandidateSummary ||
      ''
    ).trim();
  }

  calculatedAddress() {
    return [
      this.props.candidate?.addressCity,
      this.props.candidate?.addressState,
      this.props.candidate?.addressCountry,
    ]
      .filter(Boolean)
      .join(', ');
  }
}
