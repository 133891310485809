import { arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getAccountSubmissionsForStore } from '../api/account-submissions-api';
import { AccountSubmissionShape } from '../models/submissions';

class AccountSubmissionsStoreClass extends GenericStore {
  async load(accountId) {
    const submissionsPromise = getAccountSubmissionsForStore(accountId);

    await this.setState(submissionsPromise);

    const submissions = await submissionsPromise;

    return submissions;
  }

  getDataShape() {
    return arrayOf(AccountSubmissionShape);
  }

  getSingleDataShape() {
    return AccountSubmissionShape;
  }
}

export const AccountSubmissionsStore = new AccountSubmissionsStoreClass();
