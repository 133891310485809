import React, { useEffect, useRef, useState } from 'react';
import {
  useApi,
  PDFExport,
  SplitSubmissionPdf,
  StringUtil,
  PdfPageTemplateFooter,
  CookieUtil,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { EnvUtil } from '../../utils/env-util';
import { useEngagementTalentData } from '../../hooks/useEngagementTalentData';
import { CandidateLegalTechSkillsApi } from '../../api/candidate-legal-tech-skills-api';

import { PDFWrapper } from './SplitSubmissionPdfDownloadStyles';

export const SplitSubmissionPdfDownload = () => {
  const isUserLoggedIn = !!CookieUtil.get('user') || null;
  const { submissionId } = useParams();
  const pdfRoot = useRef(null);
  const [candidateIndex, setCandidateIndex] = useState(0);
  const { allCandidateOpps, submission } = useEngagementTalentData();
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const allGroupedExperiences = useApi(
    ...allCandidateOpps.map(({ candidate: { id } }) =>
      CandidateApi.readCandidateGroupedExperiences(id, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const allCandidateIds = allCandidateOpps.map(({ candidate: { id } }) => id);

  const allLegalTechnicalSkills = useApi(
    ...allCandidateOpps.map(
      ({ candidate: { id } }) =>
        isUserLoggedIn && CandidateLegalTechSkillsApi.readLegalTechSkills(id)
    )
  ).map(response => response?.data ?? []);

  const cleanCompanyName = StringUtil.cleanStringForFileName(
    submission.jobName
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (pdfRoot?.current?.save) {
      timeout = setTimeout(() => {
        pdfRoot.current.save(() => {
          if (candidateIndex < allCandidateOpps.length - 1) {
            setCandidateIndex(candidateIndex + 1);
          }
        });
      }, 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [pdfRoot, candidateIndex]);

  const candidateOpp = allCandidateOpps[candidateIndex];

  const renderTemplateFooter = (props: {
    pageNum: number;
    totalPages: number;
  }) => {
    return (
      <PdfPageTemplateFooter
        pdfProps={props}
        candidate={candidateOpp.candidate}
      />
    );
  };

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        fileName={`${candidateOpp.candidate.calculatedDisplayName} ${cleanCompanyName} Axiom Matches.pdf`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
        pageTemplate={props => renderTemplateFooter(props)}
      >
        <SplitSubmissionPdf
          submission={submission}
          candidateOpp={candidateOpp}
          allGroupedExperiences={allGroupedExperiences}
          practiceAreas={practiceAreas}
          clientUrl={EnvUtil.clientEngagementsUrl}
          loginUrl={EnvUtil.loginUrl}
          taxonomy={taxonomy}
          allCandidateIds={allCandidateIds}
          allLegalTechnicalSkills={allLegalTechnicalSkills}
        />
      </PDFExport>
    </PDFWrapper>
  );
};
