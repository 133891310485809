import React from 'react';
import {
  Account,
  User,
  AccountNewEngagementAssignment,
} from '@axiom/validation';

import EmptyProjectGraphic from '../../../public/static/svg/empty-project.svg';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { Card } from '../../layout/Card/Card';
import { CardFooter } from '../../layout/Card/CardFooter';
import { CardSection } from '../../layout/Card/CardSection';
import { EmptyLayout } from '../../layout/EmptyLayout/EmptyLayout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Visible } from '../../layout/Visible/Visible';
import {
  StartEngagementButton,
  MqlData,
} from '../StartEngagementButton/StartEngagementButton';

export const ClientEngagementNoTalentMessage = ({
  account,
  user,
  accountNewEngagementAssignment,
  onSelfServiceIneligibleClick,
  onLoggedOutSubmissionClick,
}: {
  account: Account;
  user: User;
  accountNewEngagementAssignment: AccountNewEngagementAssignment;
  onSelfServiceIneligibleClick: (data: MqlData) => Promise<void>;
  onLoggedOutSubmissionClick?: () => void;
}) => {
  return (
    <Card name="no-profiles">
      <CardSection divider>
        <EmptyLayout
          graphic={
            <Visible only="largeScreen">
              <EmptyProjectGraphic
                width="200px"
                height="200px"
                data-test="empty-graphic"
              />
            </Visible>
          }
          graphicPosition="right"
        >
          <SmallHeader>No profiles? No problem.</SmallHeader>
          <Gutter bottom="16px" />
          <Paragraph>
            Axiom’s Talent Specialists are busy hand-selecting the best matches
            for your engagement. We’ll send you an email when your matches are
            ready, or you can check back here in 1-2 business days to see your
            matches!
          </Paragraph>
          <br />
          <br />
          <Paragraph>
            Can we help with something else? Axiom has world-class legal talent
            with experience spanning all types of legal work, practice areas,
            and industries. If you have more legal work coming up, start a new
            engagement now and let Axiom help you get ahead of it.
          </Paragraph>
        </EmptyLayout>
      </CardSection>
      <CardFooter>
        <StartEngagementButton
          name="NEW_ENGAGEMENTS_BUTTON"
          account={account}
          user={user}
          accountNewEngagementAssignment={accountNewEngagementAssignment}
          pattern="primary"
          variation="filled"
          onSelfServiceIneligibleClick={onSelfServiceIneligibleClick}
          onLoggedOutSubmissionClick={onLoggedOutSubmissionClick}
        >
          Start a new engagement
        </StartEngagementButton>
      </CardFooter>
    </Card>
  );
};
